import { shouldShowBanner } from '../Banner/utils';

export const shouldShowOfferModal = (offerDetails) => {
  const lastShown = sessionStorage.getItem('upgradeModalLastShown');
  const now = new Date();

  if (!lastShown || new Date(lastShown).getDate() !== now.getDate()) {
    const canShowBanner = shouldShowBanner(offerDetails);
    if (canShowBanner) {
      sessionStorage.setItem('upgradeModalLastShown', now);
      return true;
    }
  }
  return false;
};
