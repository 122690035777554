import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useRef,
} from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';

import APIContext from '../../../contexts/APIContext';
import CacheContext from '../../../contexts/CacheContext';

import {
  trackEventModalViewed,
  trackEvent,
} from '../../Utils/SegmentAnalytics';

function AccountPasswordModal(props) {
  const { APIchangePassword } = useContext(APIContext);
  const { getUserInformation } = useContext(CacheContext);

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (props.isVisible) {
      trackEventModalViewed('change password');
    }
  }, [props.isVisible]);

  async function changePassword() {
    setSuccessMessage('');
    setErrorMessage('');

    setIsLoading(true);

    let email = getUserInformation().email;

    APIchangePassword(email)
      .then((response) => {
        setIsLoading(false);
        setSuccessMessage(
          "We've just sent a password reset email to your email. If you can't find the email, check your spam folder or resend the email below using the button below.",
        );
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(
          "We couldn't process your request. Please contact support.",
        );
      });
  }

  function closeModal() {
    props.closeModal();
  }

  return (
    <>
      <Transition.Root show={props.isVisible} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-50 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={true}
          onClose={() => closeModal()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium"
                    >
                      Change your password
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm">
                        You are going to receive a password reset link to your
                        email{' '}
                        <span className="text-blue-brand-500">
                          {getUserInformation().email}
                        </span>
                      </p>
                      {successMessage !== '' ? (
                        <div className="mt-4 rounded-md bg-green-50 p-4">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <CheckCircleIcon
                                className="h-5 w-5 text-green-400"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-3">
                              <h3 className="text-sm font-medium text-green-800">
                                Email sent
                              </h3>
                              <div className="mt-2 text-sm text-green-700">
                                <p>{successMessage}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {errorMessage !== '' ? (
                        <div className="mt-4 rounded-md bg-red-50 p-4">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <XCircleIcon
                                className="h-5 w-5 text-red-400"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-3">
                              <h3 className="text-sm font-medium text-red-800">
                                Error
                              </h3>
                              <div className="mt-2 text-sm text-red-700">
                                <p>{errorMessage}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => changePassword()}
                    ref={cancelButtonRef}
                  >
                    {isLoading ? (
                      <>
                        <svg
                          className="animate-spin h-5 w-5 mr-3"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            fill="transparent"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Sending email
                      </>
                    ) : successMessage !== '' ? (
                      'Resend email'
                    ) : (
                      'Send email'
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => closeModal()}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default AccountPasswordModal;
