import { useCallback, useEffect, useState } from 'react';
import { handleIpAddressFetching } from '../../Utils/Helpers';

const useGenerateUrl = ({ publicProductData, userEmail, usersProductData }) => {
  const { id: productId, test_url: productTestUrl } = publicProductData || {};
  const { api_key: apiKey } = usersProductData || {};
  const [url, setUrl] = useState('');
  const [queryIpAddress, setQueryIpAddress] = useState('');

  const generateUrl = useCallback(
    function () {
      const baseUrl = `${productTestUrl}/?api_key=${apiKey}`;
      let params = '';

      switch (productId) {
        case 2:
          // ip-geolocation
          params = '';
          handleIpAddressFetching(setQueryIpAddress);
          return baseUrl + queryIpAddress;
        case 3:
          // Holidays
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          params = `&country=US&year=${currentYear}&month=12&day=25`;
          return baseUrl + params;
        case 4:
          // avatars
          params = '&name=Claire Florentz';
          return baseUrl + params;
        case 5:
          // VAT
          params = '&vat_number=SE556656688001';
          return baseUrl + params;
        case 6:
          // email-validation
          let email = userEmail;
          params = '&email=' + email;
          return baseUrl + params;
        case 7:
          // phone-validation
          params = '&phone=14152007986';
          return baseUrl + params;
        case 8:
          // screenshot
          params = '&url=https://news.ycombinator.com';
          return baseUrl + params;
        case 9:
          // scrape
          params = '&url=https://news.ycombinator.com';
          return baseUrl + params;
        case 10:
          // images
          params = '&phone=14152007986';
          return baseUrl + params;
        case 13:
          // timezone
          params = '&location=Oxford, United Kingdom';
          return baseUrl + params;
        case 14:
          // Company Enrichment
          params = '&domain=airbnb.com';
          return baseUrl + params;
        case 15:
          // Exchange rates
          params = '&base=USD&target=EUR';
          return baseUrl + params;
        case 16:
          // IBAN validation
          params = '&iban=BE71096123456769';
          return baseUrl + params;
        default:
          return '';
      }
    },
    [productId, productTestUrl, apiKey, userEmail, queryIpAddress],
  );

  useEffect(() => {
    const newUrl = generateUrl();
    setUrl(newUrl);
  }, [generateUrl, publicProductData]);

  return url;
};

export default useGenerateUrl;
