import { useState, useEffect, useContext } from 'react';
import { shouldShowBanner } from './utils';
import APIContext from '../../contexts/APIContext';

export function useShowBanner(component) {
  const [showBanner, setShowBanner] = useState(false);
  const [promoCode, setPromoCode] = useState(null);
  const { userInfo, APIGetBannerPromoCode } = useContext(APIContext);

  useEffect(() => {
    const checkBanner = async () => {
      const currentPath = window.location.pathname;
      const shouldFetch = currentPath.includes(`/${component.toLowerCase()}`);

      if (!userInfo || !shouldFetch || !shouldShowBanner(userInfo)) return;

      try {
        const code = await APIGetBannerPromoCode();
        setPromoCode(code);
        setShowBanner(!!code);
      } catch (error) {
        console.error('Error fetching promo code:', error);
        setShowBanner(false);
      }
    };

    checkBanner();
  }, [userInfo, component]);

  return { showBanner, promoCode };
}
