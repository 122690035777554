import { useContext } from 'react';

import HeaderContext from '../contexts/HeaderContext';

const useHeaderContext = () => {
  const context = useContext(HeaderContext);
  if (context === undefined) {
    throw new Error('useHeaderContext must be used within a HeaderProvider');
  }
  return context;
};

export default useHeaderContext;
