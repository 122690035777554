import React, { useEffect } from 'react';
import { trackEvent } from '../../../Utils/SegmentAnalytics';

import '../../../../assets/styles/changelog.css';

import { BellIcon } from '@heroicons/react/outline';

function TopbarChangelog(props) {
  useEffect(() => {
    renderChangelog();
  }, []);

  function renderChangelog() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.headwayapp.co/widget.js';
    document.head.appendChild(script);
    var config = {
      selector: '#changelog',
      account: 'x9AlEx',
      callbacks: {
        onShowWidget: function () {
          trackEvent('Changelog viewed', {});
        },
      },
    };
    script.onload = function () {
      window.Headway.init(config);
    };
  }

  return (
    <button className="ml-3 p-1 rounded-full text-gray-brand-500 hover:text-white hover:bg-gray-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
      <span className="sr-only">View notifications</span>
      <BellIcon className="h-6 w-6" aria-hidden="true" />
      <div className="absolute w-10 h-16 top-0" id="changelog"></div>
    </button>
  );
}

export default TopbarChangelog;
