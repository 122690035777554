import React from 'react';
import { useHistory } from 'react-router-dom';
import { ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/outline';

import { redirectFromQuickstart } from '../../../../Quickstart/Quickstart.helpers';
import { trackEvent } from '../../../../Utils/SegmentAnalytics';
import Button from '../../../../Layout/Button';
import Popover from '../../../../Layout/Popover/Popover';
import useGetQueryParamValue from '../../../../Utils/useGetQueryParamValue';

const FeedbackPopover = ({
  referenceElement,
  setIsShowingFeedbackModal,
  onClose,
  isOpen,
  productName,
}) => {
  const { location, push } = useHistory();
  const isQuickstart = location.pathname.includes('quickstart');
  const apiOfInterest = useGetQueryParamValue('interest');
  const handlePositiveFeedback = () => {
    trackEvent('Test API call feedback', {
      product_name: productName,
      feedback: 'positive',
      source: isQuickstart ? 'quickstart' : 'dashboard',
    });

    isQuickstart && push(redirectFromQuickstart(apiOfInterest));
    onClose();
  };

  const handleNegativeFeedback = () => {
    setIsShowingFeedbackModal(true);
    onClose();
  };

  return (
    <Popover
      open={isOpen}
      referenceElement={referenceElement}
      title="Give us feedback"
      description="What do you think of the response?"
      fallbackPlacements={['left']}
      onClose={onClose}
      closeOnClickOutside={false}
    >
      <div className="flex flex-initial flex-col gap-3">
        <Button
          isWide={true}
          action="secondary"
          onClick={handlePositiveFeedback}
        >
          <p className="flex items-center justify-center text-base w-full h-full font-semibold text-success leading-8">
            <ThumbUpIcon className="mr-3 w-5 h-5" />
            It looks good!
          </p>
        </Button>
        <Button
          isWide={true}
          action="secondary"
          onClick={handleNegativeFeedback}
        >
          <p className="flex items-center justify-center text-base w-full h-full font-semibold text-error leading-8">
            <ThumbDownIcon className="mr-3 w-5 h-5" />
            There's an issue
          </p>
        </Button>
      </div>
    </Popover>
  );
};

export default FeedbackPopover;
