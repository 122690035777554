import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import UsageChart from './UsageChart';

import { trackEventPageLoad } from '../../Utils/SegmentAnalytics';

function Usage(props) {
  const [usageData, setUsagedata] = useState({
    chartData: {
      labels: [],
      data: [],
    },
  });

  useEffect(() => {
    getUsageData();
    trackEventPageLoad('product usage');
  }, []);

  function getUsageData() {
    // Build array of last 30 days
    var pastDaysToLookup = 29;
    var dateArray = [];
    var dateArrayVerbose = [];

    for (var i = 0; i <= pastDaysToLookup; i++) {
      var d = new Date();

      // Get date in reverse order from today
      d.setDate(d.getDate() - (pastDaysToLookup - i));

      // Formatting date
      var dd = d.getDate();
      var mm = d.getMonth() + 1;
      var yyyy = d.getFullYear();

      // Formatting verbose
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      var mmVerbose = monthNames[mm - 1].substring(0, 3);
      let formattedDateVerbose = mmVerbose + ' ' + dd;
      dateArrayVerbose.push(formattedDateVerbose);

      // Creating date array to do matching with the API when retrieving usage values per date
      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }
      let formattedDate = yyyy + '-' + mm + '-' + dd;
      dateArray.push(formattedDate);
    }

    // Build array of last 30 days usage
    var usageArray = [];
    var historicalUsage = props.historicalUsage;
    for (var j = 0; j <= pastDaysToLookup; j++) {
      // Checking if we have usage for day
      if (
        historicalUsage &&
        historicalUsage !== undefined &&
        dateArray[j] in historicalUsage
      ) {
        // Actual usage
        let usage = historicalUsage[dateArray[j]];
        usageArray.push(usage);
      } else {
        // No usage
        usageArray.push(0);
      }
    }

    const data = {
      chartData: {
        labels: dateArrayVerbose,
        data: usageArray,
      },
    };

    setUsagedata(data);
  }
  return (
    <>
      <h2 className="text-xl font-medium">Usage</h2>

      <div className="mt-4 text-sm">
        Below is your API usage for the last 30 days
        <br />
      </div>

      <div className="mt-8 text-sm">
        <div className="kt-portlet__content__padded">
          <UsageChart info={usageData} />
        </div>
      </div>
    </>
  );
}

export default withRouter(Usage);
