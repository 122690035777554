import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import APIContext from '../../contexts/APIContext';
import { trackEventPageLoad, trackEvent } from '../Utils/SegmentAnalytics';

import { XCircleIcon } from '@heroicons/react/solid';
import { useCreateGoogleCallback, GoogleButton } from './GoogleButton';
import { getSignupSourceData } from '../Utils/Helpers';

function SignupInvite(props) {
  const apiContext = useContext(APIContext);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [errorMessage, setErrorMessage] = useState('Please try again');
  const [displayErrorMessage, setDisplayErrorMessage] = useState('none');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let email = window.location.search.match(/email=([^&]*)/)
      ? window.location.search.match(/email=([^&]*)/)[1]
      : '';
    let token = window.location.search.match(/token=([^&]*)/)
      ? window.location.search.match(/token=([^&]*)/)[1]
      : '';

    if (!email || !token) {
      history.push('/users/signup');
    } else {
      setEmail(email);
      setToken(token);
      trackEventPageLoad('signup invite');
    }
  }, [props.location.search]);

  const googleSignUpCallback = useCreateGoogleCallback(
    'signup',
    setErrorMessage,
    setDisplayErrorMessage,
  );

  useEffect(() => {
    let credentials = new URLSearchParams(window.location.search).get(
      'credentials',
    );

    if (credentials) {
      googleSignUpCallback(credentials, 'google_one_tap_from_workflow');
    }
  }, [window.google]);

  async function signup(event) {
    event.preventDefault();

    trackEvent('Signup form submitted', {
      email: email,
    });

    setIsLoading(true);

    if (!email) {
      let message = 'Please enter your email';
      setErrorMessage(message);
      setDisplayErrorMessage('block');
      trackEvent('Signup form rejected', {
        email: email,
        message: message,
      });
    } else if (!password) {
      let message = 'Please enter your password';
      setErrorMessage(message);
      setDisplayErrorMessage('block');
      trackEvent('Signup form rejected', {
        email: email,
        message: message,
      });
    } else {
      await apiContext
        .APIsignup(email, password, captcha, getSignupSourceData(), token)
        .then((response) => {
          if (response.status === 201) {
            // Proceed with login / auth
            login(email, password);
          } else {
            var message =
              "We couldn't create your account. Please contact support.";
            setDisplayErrorMessage('block');
            setErrorMessage(message);
            trackEvent('Signup form rejected', {
              email: email,
              status: response.status,
              message: message,
            });
          }
        })
        .catch((error) => {
          var message =
            "We couldn't complete your signup. Please contact support.";

          if (
            error.response &&
            error.response.data &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.message
          ) {
            message = error.response.data.error.message;
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.email
          ) {
            message = `Error: ${error.response.data.email[0]}`;
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.password
          ) {
            message = `Error: ${error.response.data.password[0]}`;
          }

          setDisplayErrorMessage('block');
          setErrorMessage(message);
          trackEvent('Signup form rejected', {
            email: email,
            status: error.response ? error.response.status : '',
            message: message,
          });
        });
    }
    setIsLoading(false);
  }

  async function login(email, password) {
    setIsLoading(true);
    await apiContext
      .APIlogin(email, password)
      .then((response) => {
        if (response.status === 200) {
          setDisplayErrorMessage('none');
          setErrorMessage('');

          var redirectPath = '/';
          // Check if redirectPath exists on current URL
          if (window.location.search.match(/target=([^&]*)/)) {
            redirectPath = window.location.search.match(/target=([^&]*)/)[1];
            // Add param to hide free plan
            redirectPath = redirectPath + '?source=signup';
          }

          history.push(redirectPath);
        } else {
          setDisplayErrorMessage('block');
          setErrorMessage('Please verify your email and password.');
        }
      })
      .catch((error) => {
        let errorMessage = 'Incorrect email or password. Please try again.';

        if (!error.response) {
          errorMessage = 'Something went wrong. Please try again.';
        } else if (error.response.status === 429) {
          errorMessage =
            'Too many failed login attempts. Please contact support.';
        }
        setDisplayErrorMessage('block');
        setErrorMessage(errorMessage);
      });
    setIsLoading(false);
  }

  return (
    <div>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Welcome!
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            You have been invited to join a team on Abstract.
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <GoogleButton
              callback={googleSignUpCallback}
              context="signup"
              width="365px"
            />

            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border bg-gray-100 border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-brand-500 focus:border-blue-brand-500 sm:text-sm"
                    placeholder="jane@doe.com"
                    value={email}
                    disabled={true}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-brand-500 focus:border-blue-brand-500 sm:text-sm"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div>
                <ReCAPTCHA
                  style={{ width: '100%' }}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={(captchaToken) => {
                    setCaptcha(captchaToken);
                  }}
                />
              </div>

              <div>
                <button
                  disabled={
                    token && email && password && !isLoading ? false : true
                  }
                  type="submit"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-sm font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 disabled:opacity-50"
                  onClick={signup}
                >
                  {isLoading ? (
                    <>
                      <svg
                        className="animate-spin h-5 w-5 mr-3"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          fill="transparent"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Creating your account
                    </>
                  ) : (
                    <>Continue</>
                  )}
                </button>
                <div className="text-center text-xs text-gray-600">
                  <div className="text-left mt-2">
                    By signing up, you agree to our{' '}
                    <a
                      href="https://www.abstractapi.com/legal/legal"
                      className="text-blue-brand-500 cursor-pointer"
                      target="_blank"
                      rel="noreferrer"
                      title="Terms of Service"
                    >
                      Terms of Service
                    </a>
                    .
                  </div>
                </div>
              </div>
              <div
                className="rounded-md bg-red-50 p-4"
                style={{ display: displayErrorMessage }}
              >
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      {errorMessage}
                    </h3>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupInvite;
