import { useState, forwardRef } from 'react';
import { RadioGroup } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

import { SelectableCard } from './SelectableCard/SelectableCard';
import { Button } from '../index';

const SelectableCards = forwardRef(
  ({ itemsList, handleSelectedItem, allItems, selectedItem }, ref) => {
    const itemToShow = 5; // This is the number of items to show initially
    const [showingItems, setShowingItems] = useState(
      itemsList.slice(0, itemToShow),
    );
    return (
      <div
        className="overflow-y-auto text-xs md:text-sm"
        style={{
          height: `${showingItems.length > 5 ? '60rem' : `auto`}`,
        }}
      >
        <RadioGroup
          ref={ref}
          value={selectedItem}
          onChange={handleSelectedItem}
        >
          <div className="mx-auto flex flex-col space-y-4 w-80 md:w-458">
            {showingItems.map((item) => (
              <SelectableCard key={`${item.title}-option`} item={item} />
            ))}
          </div>
        </RadioGroup>
        {allItems.length > 5 && (
          <span
            className={`${
              showingItems === allItems ? 'hidden' : ''
            } flex justify-center`}
          >
            <Button
              action="secondary"
              icon={<ChevronDownIcon />}
              onClick={() => setShowingItems(allItems)}
              className="mt-10"
            >
              See more
            </Button>
          </span>
        )}
      </div>
    );
  },
);

export { SelectableCards };
