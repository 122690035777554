import { useEffect } from 'react';
import SubHeader from '../Layout/SubHeader/SubHeader';

import { trackEventPageLoad } from '../Utils/SegmentAnalytics';

function Beta(props) {
  useEffect(() => {
    trackEventPageLoad('beta');
  }, []);
  return (
    <div>
      <SubHeader title="API in beta" />
      <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="row" id="view-dashboard">
          <div className="col-lg-12 col-xl-12">
            <div className="kt-portlet kt-portlet--fit kt-portlet--height-fluid">
              <div className="kt-portlet__body kt-portlet__body--fluid">
                <div className="kt-widget-24 kt-widget-24--white">
                  <div className="kt-widget-24__content">
                    <div className="kt-widget-24__content-info">
                      <div className="kt-widget-24__content-section">
                        <div className="kt-widget-24__content-title">
                          This API is currently in private beta.
                        </div>
                        <br />
                        <span>
                          Please contact support to get access to this API{' '}
                        </span>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Beta;
