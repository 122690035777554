import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import APIContext from '../../contexts/APIContext';

import { trackEventPageLoad, trackEvent } from '../Utils/SegmentAnalytics';

import { CheckCircleIcon } from '@heroicons/react/solid';

function PasswordResetConfirm() {
  const history = useHistory();

  const { APIconfirmPasswordChange } = useContext(APIContext);

  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  useEffect(() => {
    trackEventPageLoad('password reset confirmation');
  }, []);

  async function confirmPasswordReset(event) {
    event.preventDefault();

    trackEvent('Password reset confirmation form submitted ', {});

    if (password === '' || passwordConfirmation === '') {
      let message = 'Please fill out all fields';
      setAlertMessage(message);
      trackEvent('Password reset confirmation form rejected', {
        message: message,
      });
    } else if (password !== passwordConfirmation) {
      let message = 'Passwords do not match.';
      setAlertMessage(message);
      trackEvent('Password reset confirmation form rejected', {
        message: message,
      });
    } else {
      setIsLoading(true);

      var email = window.location.search.match(/email=([^&]*)/)
        ? window.location.search.match(/email=([^&]*)/)[1]
        : '';
      var token = window.location.search.match(/token=([^&]*)/)
        ? window.location.search.match(/token=([^&]*)/)[1]
        : '';

      APIconfirmPasswordChange(email, password, token)
        .then((response) => {
          setIsLoading(false);

          if (response.status === 204) {
            history.push('/users/login');
          } else {
            setAlertMessage(
              'Something went wrong. Please try again or contact support.',
            );
            trackEvent('Password reset confirmation form rejected', {
              status: response.status,
            });
          }
        })
        .catch((error) => {
          var message =
            'Something went wrong. Please try again or contact support.';
          if (error.response && error.response.data.password) {
            message = error.response.data.password[0];
          }
          setIsLoading(false);
          setAlertMessage(message);
          trackEvent('Password reset confirmation form rejected', {
            status: error.response ? error.response.status : '',
            message: message,
          });
        });
    }
  }

  return (
    <div>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="text-center text-3xl font-extrabold text-gray-900">
            Reset your password
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Confirm your new password
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-brand-500 focus:border-blue-brand-500 sm:text-sm"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm password
                </label>
                <div className="mt-1">
                  <input
                    id="password-2"
                    name="password-2"
                    type="password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-brand-500 focus:border-blue-brand-500 sm:text-sm"
                    value={passwordConfirmation}
                    onChange={(e) => {
                      setPasswordConfirmation(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div>
                <button
                  disabled={
                    password === '' || passwordConfirmation === ''
                      ? true
                      : false
                  }
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-sm font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 disabled:opacity-50"
                  onClick={confirmPasswordReset}
                >
                  {isLoading ? (
                    <>
                      <svg
                        className="animate-spin h-5 w-5 mr-3"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          fill="transparent"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Changing password
                    </>
                  ) : (
                    <>Change password</>
                  )}
                </button>
                {alertMessage != '' ? (
                  <div className="mt-4 rounded-md bg-red-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon
                          className="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">
                          {alertMessage}
                        </h3>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetConfirm;
