import { RadioGroup } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { InformationCircleIcon } from '@heroicons/react/solid';

import { classNames } from '../../../Utils/Helpers';
import { Tag } from '../../../Layout/Tag/Tag';
import { Tooltip } from '../../../Layout/Tooltip/Tooltip';

const SelectableCard = ({ item, ...props }) => (
  <RadioGroup.Option
    {...props}
    value={item}
    className={({ checked, active }) =>
      classNames(
        checked ? 'bg-blue-brand-500 border-transparent' : 'border-gray-300',
        'relative block cursor-pointer rounded-md border bg-white px-3 py-3 md:px-6 md:py-4 shadow-sm focus:outline-none sm:flex sm:justify-between',
      )
    }
  >
    {({ active, checked }) => (
      <div className="flex justify-between w-full items-center">
        <div className="flex flex-row gap-2">
          <span className={`my-auto ml-0 mr-1 ${checked ? 'text-white' : ''}`}>
            {item.icon}
          </span>

          <div>
            <RadioGroup.Label
              as="span"
              className={`w-full flex justify-between items-center font-medium ${
                checked ? 'text-white' : ''
              } `}
            >
              <span
                className={`flex gap-3 ${item.description ? 'font-bold' : ''}`}
              >
                {item.title}
              </span>
            </RadioGroup.Label>
            {item.description && (
              <RadioGroup.Description
                className={`${checked ? 'text-white' : ''} `}
              >
                {item.description}
              </RadioGroup.Description>
            )}
          </div>
        </div>
        <span className="flex gap-5 items-center">
          {item.tag && <Tag>{item.tag}</Tag>}
          {item.tooltip && (
            <Tooltip content={item.tooltip}>
              <InformationCircleIcon
                className={`${
                  checked ? 'text-white opacity-100' : ''
                } h-5 w-5 opacity-30`}
              />
            </Tooltip>
          )}
          <ChevronRightIcon
            className={`align-self-auto w-4 h-full ${
              checked ? 'text-white' : ''
            }`}
          />
        </span>
      </div>
    )}
  </RadioGroup.Option>
);

export { SelectableCard };
