import React, { useState, useContext, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import APIContext from '../../contexts/APIContext';
import SessionContext from '../../contexts/SessionContext';

function EmailConfirm(props) {
  const history = useHistory();

  const { APIValidateEmail, APIrefreshUserData } = useContext(APIContext);
  const { loginUser } = useContext(SessionContext);

  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    confirmEmailChange();
  }, [props.location.search]);

  async function confirmEmailChange() {
    let target = new URLSearchParams(props.location.search).get('target');
    let token = new URLSearchParams(props.location.search).get('token');
    let uid = new URLSearchParams(props.location.search).get('uid');
    let selectedPlan = new URLSearchParams(props.location.search).get(
      'selected_plan',
    );

    if (!token || !uid) {
      history.push('/users/login');
    } else {
      await APIValidateEmail(uid, token).then((response) => {
        if (response.data.activation) {
          loginUser(response.data.tokens);
          let redirectPath = '/onboarding';

          APIrefreshUserData();

          if (target) {
            redirectPath += `?target=/api/${target}/pricing/select&pricing_cta=onboarding`;
          }
          if (selectedPlan) {
            redirectPath += `&selected_plan=${selectedPlan}`;
          }
          history.push(redirectPath);
        } else {
          setAlertMessage(response.data.error);
        }
      });
    }
  }

  return (
    <div style={{ 'text-align': 'center' }}>
      <div style={{ width: 300 + 'px', margin: '100px auto 40px auto' }}>
        <img
          className="mx-auto h-12 w-auto"
          src="/assets/media/logos/abstract-dark-light.svg"
          alt=""
        ></img>
      </div>
      <div
        className="kt-portlet"
        style={{ width: 300 + 'px', margin: '20px auto 20px auto' }}
      >
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            {!alertMessage ? (
              <h3 className="font-extrabold text-gray-900">
                Confirming your account...
              </h3>
            ) : (
              <h3 className="font-extrabold text-gray-900">
                Email confirmation
              </h3>
            )}
          </div>
          <br />
        </div>
        <div className="kt-portlet__body">
          <div className="login-form">
            {alertMessage ? (
              <div
                className="alert alert-solid-danger alert-bold"
                role="alert"
                style={{ marginBottom: '10px' }}
              >
                <div className="text-red-600">{alertMessage}</div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailConfirm;
