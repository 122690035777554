import { useMemo } from 'react';

const useGetQueryParamValue = (key) => {
  return useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(key) ?? null;
  }, [key]);
};

export default useGetQueryParamValue;
