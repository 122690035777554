import React from 'react';

const Heading = ({
  children,
  level = '1',
  color = '',
  padding = 'pt-10 pb-8',
}) => {
  const className = {
    1: `font-bold text-2xl ${padding} ${color}`,
    3: `font-bold text-lg ${padding} ${color}`,
    4: `font-bold text-base ${padding} ${color}`,
  }[level];

  return <h1 className={className}>{children}</h1>;
};

export { Heading };
