import { useState, useEffect, useContext } from 'react';
import APIContext from '../../../contexts/APIContext';
import BulkUploadModal from '../../Modals/BulkUploadModal';
import { generateBulkUploadCSVURL } from '../../Utils/Helpers';
import { calculateAvailabilityDaysFromFile } from '../../Utils/Helpers';

const BulkUpload = (props) => {
  const { APIgetFilesUploaded } = useContext(APIContext);
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const productName = props.productPath.split('-')[0];

  useEffect(() => {
    APIgetFilesUploaded(props.productPath, currentPage).then((response) => {
      setFilesUploaded(response.data.results);
      setCount(response.data.count);
      setTotalPages(Math.ceil(response.data.count / response.data.page_size));
    });
  }, [props.productPath, currentPage]);
  const bulllUploadCSVURLHelp = generateBulkUploadCSVURL(props.productPath);
  const closeModal = () => {
    setIsInviteModalVisible(false);
  };

  const allowedToUploadFile = () => {
    if (props.apiPlanPrice > 0) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <BulkUploadModal
        isVisible={isInviteModalVisible}
        closeModal={closeModal}
        apiKey={props.apiKey}
        productPath={props.productPath}
        filesUploaded={filesUploaded}
        setFilesUploaded={setFilesUploaded}
      />
      <h2 className="text-xl font-medium">Bulk upload (CSV)</h2>
      <div className="mt-4 text-sm">
        Don't know how to or don't want to make API calls? Use the bulk CSV
        uploader to easily use the API. The results will be sent to your email
        when ready.
        <br />
        <br />
        <a
          href={bulllUploadCSVURLHelp}
          className="transition text-blue-brand-500 ease hover:text-blue-brand-500 mr-3"
          target="_blank"
        >
          Learn more about bulk uploading
        </a>
        <div className="mt-4">
          <div className="flex justify-end">
            <button
              className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() =>
                !allowedToUploadFile()
                  ? props.showSubscriptionOverlay(
                      'Upgrade your plan to analyze CSV files',
                      'csv_upload',
                    )
                  : setIsInviteModalVisible(true)
              }
            >
              Upload CSV
            </button>
          </div>
          {filesUploaded.length > 0 && (
            <div className="border mt-4 border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      NAME
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      STATUS
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filesUploaded.map((file) => (
                    <tr key={file.id || filesUploaded.length}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {file.file_name} -{' '}
                        {file.total_row === 0 ? 'N/A' : file.total_row}{' '}
                        {productName}s
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {file.status === 'Ended' ? (
                          <a
                            href={file.url}
                            className="text-blue-brand-500 cursor-pointer ease hover:text-blue-brand-500 mr-3 "
                            title={`This file will be available until ${calculateAvailabilityDaysFromFile(
                              file,
                            )}`}
                          >
                            Download
                          </a>
                        ) : (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                            {file.status}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                <div className="flex flex-1 justify-between sm:hidden">
                  <a
                    href="#"
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Previous
                  </a>
                  <a
                    href="#"
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Next
                  </a>
                </div>
                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing{' '}
                      <span className="font-medium">
                        {currentPage === 1 ? 1 : 10 * (currentPage - 1)}
                      </span>{' '}
                      to{' '}
                      <span className="font-medium">
                        {filesUploaded.length + 10 * (currentPage - 1)}
                      </span>{' '}
                      of <span className="font-medium">{count}</span> results
                    </p>
                  </div>
                  <div>
                    <nav
                      className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                      aria-label="Pagination"
                    >
                      <a
                        href="#"
                        onClick={() =>
                          currentPage > 1 && setCurrentPage(currentPage - 1)
                        }
                        disabled={currentPage <= 1}
                        className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                          currentPage === 1
                            ? 'disabled opacity-50 cursor-not-allowed'
                            : ''
                        }`}
                      >
                        <span className="sr-only">Previous</span>
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                      {[...Array(totalPages)].map((_, index) => (
                        <a
                          href="#"
                          key={index + 1}
                          onClick={() => setCurrentPage(index + 1)}
                          aria-current={
                            index + 1 === currentPage ? 'page' : null
                          }
                          className={`relative ${
                            index + 1 === currentPage
                              ? 'z-10 bg-black-abstract-branding-500 text-white'
                              : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                          } inline-flex items-center px-4 py-2 text-sm font-semibold focus:z-20 focus:outline-offset-0`}
                        >
                          {index + 1}
                        </a>
                      ))}
                      <a
                        href="#"
                        onClick={() =>
                          currentPage < totalPages &&
                          setCurrentPage(currentPage + 1)
                        }
                        disabled={currentPage === totalPages}
                        className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                          currentPage === totalPages
                            ? 'disabled opacity-50 cursor-not-allowed'
                            : ''
                        }`}
                      >
                        <span className="sr-only">Next</span>
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BulkUpload;
