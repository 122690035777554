import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import APIContext from '../../contexts/APIContext';

import { trackEventPageLoad, trackEvent } from '../Utils/SegmentAnalytics';

import { CheckCircleIcon } from '@heroicons/react/solid';

function PasswordReset() {
  const { APIchangePassword } = useContext(APIContext);

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  useEffect(() => {
    trackEventPageLoad('password reset');
  }, []);

  async function sendPasswordResetEmail(event) {
    event.preventDefault();

    trackEvent('Password reset form submitted', {
      email: email,
    });

    setIsLoading(true);
    setIsAlertVisible(false);

    APIchangePassword(email)
      .then((response) => {
        setIsLoading(false);
        setIsAlertVisible(true);

        trackEvent('Password reset form accepted', {
          email: email,
          status: response.status,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        setIsAlertVisible(true);

        trackEvent('Password reset form rejected', {
          email: email,
          status: error.response ? error.response.status : '',
        });
      });
  }

  return (
    <div>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="text-center text-3xl font-extrabold text-gray-900">
            Reset your password
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Enter your email to receive a link to reset your password
          </p>
        </div>

        <div
          className="mt-8 sm:mx-auto"
          style={{ width: '500px', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-brand-500 focus:border-blue-brand-500 sm:text-sm"
                    placeholder="jane@doe.com"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div>
                {isAlertVisible ? (
                  <div
                    className="rounded-md bg-green-50 p-4"
                    style={{ display: isAlertVisible }}
                  >
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon
                          className="h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-green-800">
                          If an account exists with this email, it will recieve
                          a password reset email.
                        </h3>
                      </div>
                    </div>
                  </div>
                ) : (
                  <button
                    disabled={email === '' || isLoading ? true : false}
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-sm font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 disabled:opacity-50"
                    onClick={sendPasswordResetEmail}
                  >
                    {isLoading ? (
                      <>
                        <svg
                          className="animate-spin h-5 w-5 mr-3"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            fill="transparent"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Sending email
                      </>
                    ) : (
                      <>Send password reset email</>
                    )}
                  </button>
                )}
              </div>
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">Or</span>
                  </div>
                </div>

                <div className="mt-6">
                  <div>
                    <Link
                      to="/users/login"
                      className="w-full flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-brand-500"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;
