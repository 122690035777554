export const NAMES = {
  KEBAB_CASE: {
    emailValidation: 'email-validation',
    ibanValidation: 'iban-validation',
    phoneValidation: 'phone-validation',
    vatValidation: 'vat',
    ipGeolocation: 'ip-geolocation',
    holidays: 'holidays',
    exchangeRates: 'exchange-rates',
    companyEnrichment: 'company-enrichment',
    timezone: 'timezone',
    avatars: 'avatars',
    screenshot: 'screenshot',
    scrape: 'scrape',
    images: 'images',
  },

  DISPLAY_CASE: {
    emailValidation: 'Email Validation',
    ibanValidation: 'IBAN Validation',
    phoneValidation: 'Phone Validation',
    vatValidation: 'VAT Validation & Rates',
    ipGeolocation: 'IP Geolocation',
    holidays: 'Holidays Data',
    companyEnrichment: 'Company Enrichment',
    avatars: 'User Avatar Creation',
    screenshot: 'Website Screenshot',
    exchangeRates: 'Exchange Rates Data',
    timezone: 'Time Zone Data',
    scrape: 'Website Scraping',
    images: 'Image Processing',
  },
};

export const getKebabCase = (displayCaseValue) => {
  const displayToKebabMap = Object.keys(NAMES.DISPLAY_CASE).reduce(
    (acc, key) => {
      acc[NAMES.DISPLAY_CASE[key]] = NAMES.KEBAB_CASE[key];
      return acc;
    },
    {},
  );

  return displayToKebabMap[displayCaseValue] || null;
};
