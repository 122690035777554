import React, { useContext, useState, useEffect } from 'react';
import {
  withRouter,
  Route,
  Switch,
  useHistory,
  Redirect,
} from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import APIContext from './../../contexts/APIContext';
import CacheContext from './../../contexts/CacheContext';
import Layout from './Layout';
import Tester from './Modules/Tester';
import Pricing from './Modules/Pricing';
import BulkUpload from './Modules/BulkUpload';
import Integrations from './Modules/Integrations';
import Settings from './Modules/Settings';
import Usage from './Modules/Usage';
import Documentation from './Modules/Documentation';
import Support from './Modules/Support';
import SubscriptionOverlay from './Modules/SubscriptionOverlay';
import productsJson from '../../assets/json/products.json';
import OfferModal from '../OfferModal/OfferModal';
import Banner from '../Banner/Banner';
import { useShowBanner } from '../Banner/useShowBanner';
import useShowOfferModal from '../OfferModal/useShowOfferModal';

const API = (props) => {
  let history = useHistory();
  const apiContext = useContext(APIContext);
  const cacheContext = useContext(CacheContext);
  const [publicProductData, setPublicProductData] = useState({}); // Information about the API like name, id, documentation, etc
  const [usersProductData, setUsersProductData] = useState({}); // Information about the users use of the API like the api_key, the current plan, usage, etc
  const [isLoading, setIsLoading] = useState(true);
  const { showBanner, promoCode } = useShowBanner('api');
  const { showOfferModal, promoCodeModal } = useShowOfferModal('api');
  const [subscriptionOverlayTitle, setSubscriptionOverlayTitle] = useState(
    'Simple, transparent, and cost-effective pricing',
  );

  useEffect(() => {
    const refreshApiData = async () => {
      setIsLoading(true);
      const { api } = props.match.params;
      let product = productsJson.products[api];
      // Getting users data related to the API being displayed (api_key, credits remaining, etc)
      let usersApis = cacheContext.getUserApisData();
      let data = usersApis?.filter((api) => api.api_id === product.id);
      setPublicProductData(product);
      if (Object.keys(data || {}).length > 0) {
        setUsersProductData(
          usersApis.filter((api) => api.api_id === product.id)[0],
        );
        setIsLoading(false);
      } else {
        history.push(`/beta`);
      }
    };

    refreshApiData();
  }, [
    props.match.params.api,
    apiContext.userInfo,
    showOfferModal,
    promoCodeModal,
  ]);

  const showSubscriptionOverlay = (message, pricingCTA) => {
    if (message && message !== '') {
      setSubscriptionOverlayTitle(message);
    } else {
      setSubscriptionOverlayTitle('Select your plan');
    }
    const { api } = props.match.params;
    history.push(`/api/${api}/pricing/select?pricing_cta=${pricingCTA}`);
  };

  const hideSubscriptionOverlay = () => {
    history.goBack();
  };

  return (
    <>
      {showOfferModal && (
        <OfferModal promoCode={promoCodeModal} currentComponent="api" />
      )}
      {showBanner && !showOfferModal && (
        <Banner promoCode={promoCode} currentComponent="api" />
      )}
      <Layout title={publicProductData.name + ' API'}>
        {usersProductData.api_plan_name === 'Free' ? (
          <div className="pb-2 sm:pb-5">
            <div className="rounded-md bg-blue-brand-500 shadow-sm p-12 pb-6 pt-6">
              <div className="flex items-center justify-between flex-wrap">
                <div className="">
                  <div className="font-medium text-white">
                    <div style={{ fontSize: '1rem' }}>
                      Unlock more calls and features
                    </div>
                    <div className="text-sm opacity-70">
                      You are currently on a free plan with limited features and
                      usage
                    </div>
                  </div>
                </div>
                <div className="order-3 mt-2 flex-shrink-0 w-full order-2 mt-0 sm:w-auto">
                  <button
                    onClick={() =>
                      showSubscriptionOverlay('', 'upgrade_banner')
                    }
                    className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-black-abstract-branding-700 bg-white hover:bg-indigo-50"
                  >
                    Upgrade
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="bg-white overflow-hidden shadow-small rounded-md">
          <div className="p-12">
            {isLoading ? (
              <>
                <ContentLoader
                  speed={1.5}
                  width={'40%'}
                  height={'40px'}
                  viewBox="0 0 100% 100%"
                  backgroundColor="#646c9a"
                  backgroundOpacity={0.05}
                  foregroundColor="#646c9a"
                  foregroundOpacity={0.1}
                  {...props}
                >
                  <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
                </ContentLoader>
                <ContentLoader
                  speed={1.5}
                  width={340}
                  height={84}
                  viewBox="0 0 340 84"
                  backgroundColor="#646c9a"
                  backgroundOpacity={0.05}
                  foregroundColor="#646c9a"
                  foregroundOpacity={0.1}
                  {...props}
                >
                  <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
                  <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
                  <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
                  <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
                  <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
                  <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
                  <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
                  <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
                </ContentLoader>
              </>
            ) : (
              <Switch>
                <Route
                  exact
                  path={`${props.match.path}/pricing`}
                  render={(props) => (
                    <Pricing
                      {...props}
                      productId={publicProductData.id}
                      usersProductData={usersProductData}
                      showSubscriptionOverlay={showSubscriptionOverlay}
                    />
                  )}
                />
                <Route
                  exact
                  path={`${props.match.path}/bulk-upload`}
                  render={(props) => (
                    <BulkUpload
                      {...props}
                      apiKey={usersProductData.api_key}
                      productPath={publicProductData.path}
                      showSubscriptionOverlay={showSubscriptionOverlay}
                      apiPlanPrice={usersProductData.api_plan_price}
                    />
                  )}
                />
                <Route
                  exact
                  path={`${props.match.path}/pricing/select`}
                  render={(props) => (
                    <>
                      <SubscriptionOverlay
                        {...props}
                        title={subscriptionOverlayTitle}
                        productId={publicProductData.id}
                        usersProductData={usersProductData}
                        currentPlanId={usersProductData.api_plan_id}
                        hide={hideSubscriptionOverlay}
                        productName={publicProductData.name}
                      />
                    </>
                  )}
                />
                <Route
                  exact
                  path={`${props.match.path}/tester`}
                  render={(props) => (
                    <Tester
                      {...props}
                      productName={publicProductData.name}
                      productId={publicProductData.id}
                      productTestUrl={publicProductData.test_url}
                      apiKey={usersProductData.api_key}
                      api={props.match.params.api}
                    />
                  )}
                />
                <Route
                  exact
                  path={`${props.match.path}/integrations`}
                  render={(props) => (
                    <Integrations
                      {...props}
                      apiKey={usersProductData.api_key}
                      api={props.match.params.api}
                      apiPlanPrice={usersProductData.api_plan_price}
                      apiPlanName={usersProductData.api_plan_name}
                    />
                  )}
                />
                <Route
                  exact
                  path={`${props.match.path}/documentation`}
                  render={(props) => (
                    <Documentation
                      {...props}
                      fileName={publicProductData.documentation}
                      apiKey={usersProductData.api_key}
                    />
                  )}
                />
                <Route
                  exact
                  path={`${props.match.path}/usage`}
                  render={(props) => (
                    <Usage
                      {...props}
                      historicalUsage={usersProductData.historical_usage}
                    />
                  )}
                />
                <Route
                  exact
                  path={`${props.match.path}/settings`}
                  render={(props) => (
                    <Settings
                      {...props}
                      apiKey={usersProductData.api_key}
                      apiKeyName={usersProductData.name}
                      apiPlanPrice={usersProductData.api_plan_price}
                      apiKeyID={usersProductData.id}
                      apiPlanName={usersProductData.api_plan_name}
                      showSubscriptionOverlay={showSubscriptionOverlay}
                    />
                  )}
                />
                <Route
                  exact
                  path={`${props.match.path}/support`}
                  component={Support}
                />
                <Redirect to={`${props.match.path}/tester`} />
              </Switch>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};
export default withRouter(API);
