import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import SessionContext from '../contexts/SessionContext';

function PublicRoute({ component: Component, ...rest }) {
  return (
    <SessionContext.Consumer>
      {(context) => {
        const { isUserAuthenticated } = context;
        return (
          <Route
            {...rest}
            render={(props) =>
              isUserAuthenticated() &&
              window.location.pathname !== '/users/password/reset/confirm' ? (
                <Redirect to="/dashboard" />
              ) : (
                <Component {...props} />
              )
            }
          />
        );
      }}
    </SessionContext.Consumer>
  );
}

export default PublicRoute;
