import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Heading,
  LoadingSpinner,
  Tester,
  TextArea,
} from '../Layout/index';
import { useHistory } from 'react-router-dom';
import { ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/outline';
import useRefreshApiData from './hooks/useRefreshApiData';
import useGenerateUrl from './hooks/useGenerateUrl';
import usePerformTestRequest from './hooks/usePerformTestRequest';
import APIContext from '../../contexts/APIContext';
import CacheContext from '../../contexts/CacheContext';
import { trackEvent } from '../Utils/SegmentAnalytics';
import { truncateUrl, redirectFromQuickstart } from './Quickstart.helpers';
import FeedbackModal from '../API/Modules/Tester/FeedbackModal';
import { useStripe } from '@stripe/react-stripe-js';

const Quickstart = () => {
  const { push } = useHistory();
  const stripe = useStripe();
  const { APIrefreshUserData, APIGetCheckoutSession, APIgetSelectedPlan } =
    useContext(APIContext);
  const { getUserApisData, getUserInformation, getUserInterest } =
    useContext(CacheContext);
  const userEmail = getUserInformation()?.email;
  const api = getUserInterest();
  const { isLoading, publicProductData, usersProductData, refreshApiData } =
    useRefreshApiData({ api, APIrefreshUserData, getUserApisData });
  const generatedUrl = useGenerateUrl({
    publicProductData,
    userEmail,
    usersProductData,
  });
  const [isShowingFeedbackModal, setIsShowingFeedbackModal] = useState(false);
  const [showTester, setShowTester] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [testInformation, setTestInformation] = useState({
    statusColor: 'indigo',
    statusText: '',
    result: '',
  });
  const { loading, performTestRequest } = usePerformTestRequest({
    productId: publicProductData?.id,
    testUrlEditable: generatedUrl,
    setTestInformation,
    testInformation,
    setShowPopover,
    showPopover,
    setShowTester,
    productName: publicProductData?.name,
  });

  useEffect(() => {
    refreshApiData();
    trackEvent('App page loaded - quickstart', {
      product_name: api,
    });
  }, []);

  const getSelectedPlan = async () => {
    const { plan } = await APIgetSelectedPlan();
    return plan;
  };

  const goToCheckoutSession = async (plan, api) => {
    let redirectUrl = window.location.origin;
    if (api) {
      redirectUrl += `/api/${api}/pricing/select?pricing_cta=landing_page`;
    } else {
      redirectUrl += '/dashboard?pricing_cta=landing_page';
    }

    APIGetCheckoutSession(plan, redirectUrl).then((response) => {
      return stripe.redirectToCheckout({
        sessionId: response.data['session_id'],
      });
    });
  };

  const handlePositiveFeedback = async () => {
    trackEvent('Test API call feedback', {
      product_name: publicProductData.name,
      feedback: 'positive',
      source: 'quickstart',
    });
    const planId = await getSelectedPlan();
    if (!planId) {
      push(redirectFromQuickstart(api));
    } else {
      goToCheckoutSession(planId, api);
    }
  };

  const handleNegativeFeedback = () => {
    setIsShowingFeedbackModal(true);
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Heading level="4" color="text-gray-brand-500" padding="pt-10 pb-3">
        🚀 Quickstart
      </Heading>
      <Heading level="3" padding="pt-0">
        Welcome to {publicProductData?.name} API!
      </Heading>
      {!showTester && (
        <>
          <p className="pt-2 pb-8">
            Click the button below to make your first free test request.
          </p>
          <TextArea
            value={truncateUrl(generatedUrl)}
            width="w-777"
            rows={1}
            padding="py-11 px-8"
            restricted={true}
            border="border-indigo-500"
          />
          <div className="flex justify-end gap-4 mt-7 md:mb-0 mb-16">
            <Button onClick={performTestRequest} loading={loading}>
              Make test request
            </Button>
          </div>
        </>
      )}
      {showTester && (
        <>
          <div className="bg-white shadow sm:rounded-lg mt-4 border border-gray-brand-500">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Please enter your feedback to continue
              </h3>
              <div
                className="flex flex-initial flex-col gap-3 mt-2 mb-2"
                style={{ flexDirection: 'row', justifyContent: 'end' }}
              >
                <Button
                  isWide={true}
                  action="secondary"
                  onClick={handlePositiveFeedback}
                >
                  <p className="flex items-center justify-center text-base w-full h-full font-semibold text-success leading-8">
                    <ThumbUpIcon className="mr-3 w-5 h-5" />
                    It looks good!
                  </p>
                </Button>
                <Button
                  isWide={true}
                  action="secondary"
                  onClick={handleNegativeFeedback}
                >
                  <p className="flex items-center justify-center text-base w-full h-full font-semibold text-error leading-8">
                    <ThumbDownIcon className="mr-3 w-5 h-5" />
                    There's an issue
                  </p>
                </Button>
              </div>
            </div>
          </div>
          <FeedbackModal
            isShowingFeedbackModal={isShowingFeedbackModal}
            productName={publicProductData.name}
            onClose={() => setIsShowingFeedbackModal(false)}
            testUrl={truncateUrl(generatedUrl)}
          />
          <Tester
            testInformation={testInformation}
            showPopover={false}
            setShowPopover={setShowPopover}
            productName={publicProductData.name}
            api={undefined}
          />
        </>
      )}
    </>
  );
};

export { Quickstart };
