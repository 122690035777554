import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import InviteModal from '../../../Modals/InviteModal';

import { UserGroupIcon } from '@heroicons/react/outline';

const TopbarInviteUser = () => {
  const location = useLocation();

  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);

  useEffect(() => {
    if (location.search === '?invite-users') {
      setIsInviteModalVisible(true);
    }
  }, [location]);

  const closeModal = () => {
    setIsInviteModalVisible(false);
  };

  return (
    <>
      <button
        onClick={() => setIsInviteModalVisible(true)}
        className="p-1 rounded-full text-gray-brand-500 hover:text-white hover:bg-gray-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center justify-center"
      >
        <UserGroupIcon className="h-6 w-6 mr-1" aria-hidden="true" />
        <span style={{ fontSize: '15px' }}>Invite users</span>
      </button>
      <InviteModal
        isVisible={isInviteModalVisible}
        closeModal={closeModal}
        source="navbar"
      />
    </>
  );
};

export default TopbarInviteUser;
