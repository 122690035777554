import { useContext, useState } from 'react';

import SubHeader from '../Layout/SubHeader/SubHeader';
import APIContext from '../../contexts/APIContext';

function Admin(props) {
  const { APIstartImpersonation } = useContext(APIContext);

  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  async function impersonate() {
    APIstartImpersonation(email)
      .then((_) => {})
      .catch((error) => {
        var message = "Impersonation couldn't be processed. Please try again";
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message = error.response.data.message;
        }
        setErrorMessage(message);
      });
  }

  return (
    <div>
      <SubHeader title="Admin" />
      <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="row" id="view-dashboard">
          <div className="col-lg-12 col-xl-12">
            <div className="kt-portlet kt-portlet--fit kt-portlet--height-fluid">
              <div className="kt-portlet__body kt-portlet__body--fluid">
                <div className="kt-widget-24 kt-widget-24--white">
                  <div className="kt-widget-24__content">
                    <div className="kt-widget-24__content-info">
                      <div className="kt-widget-24__content-section">
                        <div className="kt-widget-24__content-title">
                          Impersonate
                        </div>
                      </div>
                    </div>
                    <div className="kt-portlet__content__padded">
                      {errorMessage !== '' ? (
                        <div
                          className="alert alert-solid-danger alert-bold"
                          role="alert"
                        >
                          <div className="alert-text">{errorMessage}</div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="form-group row">
                        <label className="col-form-label">Email address</label>
                        <div className="input-group">
                          <input
                            type="text"
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            placeholder=""
                            value={email}
                          />
                          <button
                            onClick={() => impersonate()}
                            className="btn btn-brand"
                          >
                            Change
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
