import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const Tooltip = ({
  children,
  content,
  arrow = true,
  placement = 'top',
  backgroundColor = 'var(--gray-brand-900)',
  delay = [150, 300],
}) => (
  <Tippy
    arrow={arrow}
    content={content}
    placement={placement}
    interactive
    maxWidth={300}
    offset={[0, 10]}
    backgroundColor={backgroundColor}
    animation={'shift-away-subtle'}
    delay={delay}
    appendTo={() => document.body}
  >
    {children}
  </Tippy>
);

export { Tooltip };
