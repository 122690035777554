import { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';

import axios from 'axios';

import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import gfm from 'remark-gfm';

import '../../../assets/styles/markdown.css';

import { LockClosedIcon } from '@heroicons/react/outline';

import { trackEventPageLoad } from '../../Utils/SegmentAnalytics';

function Documentation(props) {
  const [documentationMarkdown, setDocumentationMarkdown] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDocumentation(props.fileName);
    trackEventPageLoad('product documentation');
  }, [props.fileName]);

  const getDocumentation = async (fileName) => {
    setIsLoading(true);

    let url = 'https://abstractapi-documentation.netlify.app/' + fileName;

    axios
      .get(url)
      .then(function (response) {
        setIsLoading(false);
        setDocumentationMarkdown(response.data);
        return response;
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const renderers = {
    code: ({ language, value }) => {
      return (
        <SyntaxHighlighter style={docco} language={language} children={value} />
      );
    },
  };

  return (
    <>
      <h2 className="text-xl font-medium">Documentation</h2>

      <div className="mt-4 text-sm">
        This is your private API key, specific to this API.
        <br />
      </div>

      <div className="mt-2 text-sm">
        <span className="flex-row py-2 px-2 w-auto rounded-md font-bold bg-gray-brand-100 text-gray-brand-500 text-xs">
          <LockClosedIcon className="h-4 w-4 mr-1 inline" aria-hidden="true" />
          {props.apiKey}
        </span>
      </div>

      <div className="mt-8 text-sm">
        <article className="markdown-body" style={{ marginTop: '30px' }}>
          {isLoading ? (
            <ContentLoader
              speed={1.5}
              width={340}
              height={84}
              viewBox="0 0 340 84"
              backgroundColor="#646c9a"
              backgroundOpacity={0.05}
              foregroundColor="#646c9a"
              foregroundOpacity={0.1}
              {...props}
            >
              <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
              <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
              <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
              <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
              <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
              <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
              <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
              <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
            </ContentLoader>
          ) : (
            <ReactMarkdown
              renderers={renderers}
              plugins={[gfm]}
              children={documentationMarkdown}
            />
          )}
        </article>
      </div>
    </>
  );
}

export default Documentation;
