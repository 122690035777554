import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';

import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';

import APIContext from '../../contexts/APIContext';
import { trackEvent, trackEventModalViewed } from '../Utils/SegmentAnalytics';

const InviteModal = ({
  isVisible,
  closeModal,
  inviteUserModalCustomText,
  enableSkip,
  source,
}) => {
  const { APIinviteOrganizationMember } = useContext(APIContext);

  const history = useHistory();

  const [emails, setEmails] = useState(['']);
  const [errorMessage, setErrorMessage] = useState('');
  const [inviteState, setInviteState] = useState('default'); // default > processing > success
  const [isLoading, setIsLoading] = useState(false);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setEmails(['']);
    setErrorMessage('');
    setInviteState('default');

    if (isVisible) {
      trackEventModalViewed('invite user');
    }
  }, [isVisible]);

  const addUser = async () => {
    setIsLoading(true);

    setErrorMessage('');
    setInviteState('processing');

    if (!emails.length) {
      setInviteState('default');
      setErrorMessage('Please enter a valid email address.');
    } else {
      await APIinviteOrganizationMember(emails)
        .then((response) => {
          setInviteState('success');
          trackEvent('User invite request', {
            source: source,
            email: emails,
          });
        })
        .catch((error) => {
          setInviteState('default');
          var message = '';
          try {
            message = error.response.data.error.message;
          } catch (error) {
            message = 'There was an error. Please contact support.';
          }
          setErrorMessage(message);
        });
    }
    setIsLoading(false);
  };

  const onClose = () => {
    // Redirect to organization view if successful invite
    if (inviteState === 'success') {
      // Check if redirectPath exists on current URL
      if (window.location.search.match(/target=([^&]*)/)) {
        let redirectPath = window.location.search.match(/target=([^&]*)/)[1];
        // Add param to hide free plan
        redirectPath = redirectPath + '?source=signup';
        history.push(redirectPath);
      } else if (window.location.pathname === '/onboarding') {
        history.push('/');
      } else {
        closeModal();
        history.push('/settings/organization');
      }
    } else {
      closeModal();
    }
  };

  const handleChange = (e, idx) => {
    const { value } = e.target;
    const rows = [...emails];
    rows[idx] = value;
    setEmails(rows);
  };

  const handleRemoveSpecificRow = (idx) => {
    const rows = [...emails];
    rows.splice(idx, 1);
    setEmails(rows);
  };

  const handleAddRow = () => {
    setEmails(emails.concat(['']));
  };

  return (
    <>
      <Transition.Root show={isVisible} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-50 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={true}
          onClose={() => onClose()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium"
                    >
                      Invite users
                    </Dialog.Title>
                    <div className="mt-2">
                      {inviteState === 'default' ||
                      inviteState === 'processing' ? (
                        <>
                          <p className="text-sm text-gray-500">
                            {inviteUserModalCustomText
                              ? inviteUserModalCustomText
                              : "Invite users to your Abstract account for free. These users can access API keys, documentation, and billing details. You can invite as many users as you'd like and remove them at any time."}
                          </p>

                          {emails.map((item, idx) => (
                            <div className="mt-3 flex" key={idx}>
                              <input
                                type="email"
                                name="email"
                                autoComplete="email"
                                value={emails[idx]}
                                onChange={(event) => handleChange(event, idx)}
                                className="appearance-none block w-80 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-brand-500 focus:border-blue-brand-500 sm:text-sm"
                              />
                              {idx >= 1 ? (
                                <button
                                  type="button"
                                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-1 sm:w-auto sm:text-sm"
                                  onClick={() => handleRemoveSpecificRow(idx)}
                                >
                                  Remove row
                                </button>
                              ) : undefined}
                            </div>
                          ))}

                          <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:mt-3 sm:w-auto sm:text-sm"
                            onClick={() => handleAddRow()}
                          >
                            Add another user
                          </button>

                          {errorMessage !== '' ? (
                            <div className="mt-3 rounded-md bg-red-50 p-4">
                              <div className="flex">
                                <div className="flex-shrink-0">
                                  <XCircleIcon
                                    className="h-5 w-5 text-red-400"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-3">
                                  <h3 className="text-sm font-medium text-red-800">
                                    {errorMessage}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {inviteState === 'success' ? (
                        <div className="rounded-md bg-green-50 p-4">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <CheckCircleIcon
                                className="h-5 w-5 text-green-400"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-3">
                              <p className="text-sm font-medium text-green-800">
                                Your invites were successfully sent
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  {inviteState === 'default' || inviteState === 'processing' ? (
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => addUser()}
                      ref={cancelButtonRef}
                    >
                      {isLoading ? (
                        <>
                          <svg
                            className="animate-spin h-5 w-5 mr-3"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              fill="transparent"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Inviting
                        </>
                      ) : (
                        'Invite'
                      )}
                    </button>
                  ) : (
                    <></>
                  )}
                  {inviteState === 'success' || inviteState === 'default' ? (
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-brand-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => onClose()}
                    >
                      {enableSkip && inviteState !== 'success'
                        ? 'Skip'
                        : 'Close'}
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default InviteModal;
