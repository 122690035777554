import { withRouter } from 'react-router-dom';
import { Line } from 'react-chartjs-2';

const UsageChart = ({ info }) => {
  const buildData = ({ chartData }) => ({
    labels: chartData.labels,
    datasets: [
      {
        label: '',
        backgroundColor: '#5d78ff14',
        borderColor: '#5d78ff',
        pointBackgroundColor: '#5d78ff',
        data: chartData.data,
      },
    ],
  });

  const data = buildData(info);

  const options = {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: '#646c9a',
            userCallback: function (value, index, values) {
              return value.toLocaleString();
            },
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontColor: '#646c9a',
          },
          gridLines: {
            color: '#e1e1ef',
            borderDash: [5, 5],
            zeroLineColor: '#e1e1ef',
            zeroLineBorderDash: [5, 5],
          },
        },
      ],
    },
    layout: {
      padding: {
        right: 10,
      },
    },
  };

  return (
    <>
      <Line data={data} options={options} />
    </>
  );
};

export default withRouter(UsageChart);
