import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import APIContext from '../../contexts/APIContext';
import { useStripe } from '@stripe/react-stripe-js';
import {
  get50Off,
  trackEventUpsell,
  generateAcceptOfferCallbackURL,
  generateRejectOfferURL,
} from './helper';
import { CheckIcon } from '@heroicons/react/solid';
const Offer = () => {
  const { APIgetSuggestedPlan } = useContext(APIContext);
  const [offerDetails, setOfferDetails] = useState({});
  const planId = new URLSearchParams(window.location.search).get('plan_id');
  const { APIGetCheckoutSession } = useContext(APIContext);
  const [eventDetails, setEventDetails] = useState({});
  const stripe = useStripe();
  const [srcImage, setSrcImage] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [offerRejectedURL, setOfferRejectedURL] = useState('');
  const [timeLeft, setTimeLeft] = useState(10 * 60);
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOfferDetails = async () => {
      try {
        const offeredAPIName = new URLSearchParams(window.location.search).get(
          'offered_api_name',
        );
        const data = await APIgetSuggestedPlan(planId, offeredAPIName);
        if (data && data.plan_details && data.offer_details) {
          setRedirectUrl(
            generateAcceptOfferCallbackURL(data.offer_details.product_name),
          );
          setOfferRejectedURL(
            generateRejectOfferURL(data.plan_details.product_name),
          );
          setOfferDetails(data.offer_details);
          setEventDetails({
            productName: data.plan_details.product_name,
            productOfferedName: data.offer_details.product_name,
            planOfferedName: data.offer_details.name,
            planPrice: data.plan_details.price
              ? data.plan_details.price.toLocaleString('en-US')
              : 'N/A',
            planOfferedPrice: data.offer_details.price
              ? data.offer_details.price.toLocaleString('en-US')
              : 'N/A',
            billingCadence: data.plan_details.billing_cadence,
            planOfferedBillingCadence: data.offer_details.billing_cadence,
            apiCalls: data.plan_details.api_calls,
            planOfferedApiCalls: data.offer_details.api_calls,
            rps: data.plan_details.rps,
            planOfferedRps: data.offer_details.rps,
            planId: data.plan_details.id,
            planOfferedId: data.offer_details.id,
            isDefaultPlan: data.plan_details.is_default_plan,
            isDefaultPlanOffered: data.offer_details.is_default_plan,
          });
          if (data.offer_details.product_name === 'Email Validation') {
            setSrcImage('email_upsell.png');
          } else {
            setSrcImage('ip_geo_upsell.png');
          }
        } else {
          console.error('Unexpected API response structure:', data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchOfferDetails();
  }, [planId]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      } else {
        setTimeLeft(timeLeft - 1);
        handleCountDown();
        clearInterval(timer);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            fill="transparent"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <span className="text-gray-500 text-lg">Loading, please wait...</span>
      </div>
    );
  }

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const handleDoNotUpgrade = () => {
    trackEventUpsell('Offer rejected', eventDetails);
    history.push(offerRejectedURL);
  };

  const handleCountDown = () => {
    trackEventUpsell('Countdown ended', eventDetails);
    history.push(offerRejectedURL);
  };

  const handleUpgradePlan = () => {
    trackEventUpsell('Offer clicked', eventDetails);
    const isSuggestion = true;
    APIGetCheckoutSession(offerDetails.id, redirectUrl, isSuggestion)
      .then((response) => {
        trackEventUpsell('New paid subscription successfull', eventDetails);
        return stripe.redirectToCheckout({
          sessionId: response.data['session_id'],
        });
      })
      .catch((error) => {
        trackEventUpsell('Error when trying to create session', {
          ...offerDetails,
          ...{ error: error },
        });
      });
  };

  return (
    <div className="h-full grid grid-cols-12 gap-1 bg-gray-brand-100">
      <div className=" col-span-8 bg-white">
        <div className="offer-image  pl-12 pr-12 ml-12 mr-12 mt-12">
          {srcImage ? (
            <img src={srcImage} alt="Offer" className="img-fluid" />
          ) : (
            <div className="aspect-ratio  bg-gray-200 rounded-lg animate-pulse" />
          )}
        </div>
      </div>
      <div className="grid grid-cols-subgrid col-span-4 h-full bg-gray-brand-100 pt-12 ml-12 mr-12 pl-12 pr-12">
        <div className="col-start-2 mt-10">
          <div className="relative border-2 border-gray-400 rounded-lg place-content-center shadow-sm divide-y divide-gray-200 flex-1">
            <div className="p-6 relative">
              <div className="flex">
                {offerDetails.name ? (
                  <div className="w-full mr-2">
                    <span className="text-2xl font-extrabold text-gray-700 line-through mr-2">
                      ${offerDetails.price.toLocaleString('en-US')}
                    </span>
                    <span className="text-3xl font-extrabold text-gray-900">
                      ${get50Off(offerDetails.price)}
                    </span>
                    <span> / {offerDetails.billing_cadence}</span>
                  </div>
                ) : (
                  <div className="w-full mr-2">
                    <div className="aspect-ratio text-3xl font-extrabold w-full h-full bg-gray-200 rounded-lg animate-pulse" />
                    <div className="aspect-ratio text-3xl font-extrabold w-full h-full bg-gray-200 rounded-lg animate-pulse mt-2" />
                  </div>
                )}
              </div>
              <div>
                <button
                  type="button"
                  onClick={handleUpgradePlan}
                  className="mt-7 block w-full bg-black-abstract-branding-500 border rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-black-abstract-branding-700"
                >
                  Upgrade
                </button>
              </div>
            </div>
            <div className="pt-6 pb-8 px-6">
              <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                What's included
              </h3>
              <ul className="mt-6 space-y-4">
                <li className="flex space-x-3">
                  <CheckIcon
                    className="flex-shrink-0 h-5 w-5 text-brand-blue-500"
                    aria-hidden="true"
                  />
                  <span className="text-xs text-gray-500">
                    {offerDetails.api_calls?.toLocaleString()} calls /{' '}
                    {offerDetails.billing_cadence}
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CheckIcon
                    className="flex-shrink-0 h-5 w-5 text-brand-blue-500"
                    aria-hidden="true"
                  />
                  <span className="text-xs text-gray-500">
                    {offerDetails.rps} requests per second
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CheckIcon
                    className="flex-shrink-0 h-5 w-5 text-brand-blue-500"
                    aria-hidden="true"
                  />
                  <span className="text-xs text-gray-500">
                    Domains whitelisting
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CheckIcon
                    className="flex-shrink-0 h-5 w-5 text-brand-blue-500"
                    aria-hidden="true"
                  />
                  <span className="text-xs text-gray-500">
                    Multiple API keys
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="text py-7 border-y text-xs text-gray-500 text-center relative shadow-sm divide-y divide-gray-200 flex-1">
            <strong>
              Discount valid for the next {minutes} minutes and {seconds}{' '}
              seconds.
            </strong>
          </div>
          <button
            type="button"
            onClick={handleDoNotUpgrade}
            className="px-1 py-1 w-full inline-flex justify-center  border-2 border-gray-400 rounded-lg rounded-md py-2 text-sm font-semibold text-gray-700 text-center hover:bg-gray-50 border-gray-300 text-center hover:bg-gray-200 hover:text-white-500 hover:border-gray-brand-400"
          >
            Don't upgrade
          </button>
        </div>
      </div>
    </div>
  );
};

export default Offer;
