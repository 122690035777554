import React, { useState, useRef } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import FeedbackModal from '../../API/Modules/Tester/FeedbackModal';
import FeedbackPopover from '../../API/Modules/Tester/FeedbackPopover';
import { formatIndent } from './Tester.helpers';

const Tester = ({
  testInformation,
  showPopover,
  setShowPopover,
  productName,
  language = 'javascript',
}) => {
  const [isShowingFeedbackModal, setIsShowingFeedbackModal] = useState(false);
  const popoverRefElement = useRef(null);

  return (
    <>
      <div
        id="sample-result"
        className="w-full mt-6"
        style={{ position: 'relative' }}
        ref={popoverRefElement}
      >
        <div>
          <span
            className={`absolute top-5 right-5 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-${testInformation.statusColor}-100 text-${testInformation.statusColor}-800`}
          >
            <svg
              className={`-ml-0.5 mr-1.5 h-2 w-2 text-${testInformation.statusColor}-400`}
              fill="currentColor"
              viewBox="0 0 8 8"
            >
              <circle cx={4} cy={4} r={3} />
            </svg>
            {testInformation.statusText}
          </span>
        </div>
        <SyntaxHighlighter
          language={language}
          style={docco}
          wrapLongLines={false}
          customStyle={{
            padding: 30,
            border: '1px solid #e2e5ec',
            borderRadius: 6,
            width: 780,
          }}
        >
          {formatIndent(testInformation.result)}
        </SyntaxHighlighter>
      </div>
      <FeedbackPopover
        referenceElement={popoverRefElement}
        productName={productName}
        setIsShowingFeedbackModal={setIsShowingFeedbackModal}
        onClose={() => setShowPopover(false)}
        isOpen={showPopover}
      />
      <FeedbackModal
        isShowingFeedbackModal={isShowingFeedbackModal}
        productName={productName}
        onClose={() => setIsShowingFeedbackModal(false)}
        testUrl="/test"
      />
    </>
  );
};

export default Tester;
