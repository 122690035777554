import React, { useState, useEffect, useContext, useRef } from 'react';
import { trackEvent } from '../Utils/SegmentAnalytics';
import APIContext from '../../contexts/APIContext';
import { useStripe } from '@stripe/react-stripe-js';
import { getNextMonthName, builtURLRedirectBanner } from '../Banner/utils';
import SubscriptionPaymentModal from '../Modals/SubscriptionPaymentModal';
import { ModalWithButton } from '../Layout/Modal/ModalWithButton';

const OfferModal = ({ promoCode, currentComponent }) => {
  const { APIGetCheckoutSession, APIrefreshUserData } = useContext(APIContext);
  const apiCallMade = useRef(false);
  const isMounted = useRef(true);
  const [productName, setProductName] = useState('');
  const [planId, setPlanId] = useState(null);
  const [nextMonth, setNextMonth] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [productId, setProductId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [bannerCouponId, setBannerCouponId] = useState(null);
  const [discountPercentage, setDiscountPercentage] = useState(30);
  const [offerDiscount, setOfferDiscount] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const stripe = useStripe();

  const isSuggestion = true;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const checkUserInfo = async () => {
      if (!apiCallMade.current && promoCode) {
        if (typeof promoCode !== 'object' || promoCode === null) {
          return;
        }

        const { plan, promotion_code } = promoCode;
        if (!plan || !promotion_code) {
          return;
        }

        const path = window.location.pathname;
        const isCorrectPath =
          (currentComponent === 'dashboard' && path.includes('/dashboard')) ||
          (currentComponent === 'api' && path.includes('/api/'));

        if (!isCorrectPath) return;

        try {
          apiCallMade.current = true;
          if (isMounted.current) {
            setNextMonth(getNextMonthName());
            setRedirectUrl(
              builtURLRedirectBanner(promoCode, 'upgrade_modal_discount'),
            );
            setProductId(plan.api.id);
            setBannerCouponId(promotion_code.stripe_id);
            setProductName(plan.api.name);
            setPlanId(plan.id);
            setOfferDiscount(promotion_code.code);
            setDiscountPercentage(
              parseInt(promotion_code.coupon.percentage_discount),
            );
            setIsVisible(true);
          }
        } catch (error) {
          console.error('Error', error);
        }
      }
    };

    checkUserInfo();
  }, [currentComponent, promoCode]);

  const onClose = () => {
    setIsVisible(false);
    sessionStorage.setItem('upgradeModalLastShown', new Date());
    trackEvent('Offer Modal - Close clicked', {
      product_name: productName,
    });
  };

  const handleSubscribe = () => {
    trackEvent('Offer Modal - Subscribe clicked', {
      product_name: productName,
      offer_discount: offerDiscount,
    });
    setShowModal(false);
    APIGetCheckoutSession(
      planId,
      redirectUrl,
      isSuggestion,
      bannerCouponId,
      'offer_modal_discount',
    )
      .then((response) => {
        return stripe.redirectToCheckout({
          sessionId: response.data['session_id'],
        });
      })
      .catch((error) => {
        trackEvent('Modal create session failed.', {
          error: error || 'Error when trying to create session',
        });
        setModalMessage(
          'An error occurred while creating the session. Please try again.',
        );
        setShowModal(true);
      });
  };

  if (!isVisible) return null;

  return (
    <>
      <ModalWithButton
        isVisible={isVisible}
        closeModal={onClose}
        title={`Subscribe to the ${productName} API and save ${discountPercentage}%`}
        confirmLabel="Subscribe today"
        cancelLabel="Close"
        onConfirm={handleSubscribe}
        children
      >
        <p className="text-sm text-gray-500">
          This offer will expire on {nextMonth} 1st. Subscribe to enjoy this
          discount 🎁
        </p>
      </ModalWithButton>

      <SubscriptionPaymentModal
        isVisible={showModal}
        refreshApiData={() => APIrefreshUserData()}
        message={modalMessage}
        closeModal={() => {
          setShowModal(false);
        }}
        productId={productId}
        selectedPlan={planId}
      />
    </>
  );
};

export default OfferModal;
