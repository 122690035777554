import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <div className="text-xs text-right">
        <a
          href="https://www.abstractapi.com/"
          rel="noopener noreferrer"
          target="_blank"
          className="transition ease hover:text-gray-brand-500 mr-3"
        >
          Abstract API Inc.
        </a>
        <a
          href="https://www.abstractapi.com/legal/legal#privacy"
          rel="noopener noreferrer"
          target="_blank"
          className="transition ease hover:text-gray-brand-500 mr-3"
        >
          Terms of service
        </a>
        <a
          href="https://www.abstractapi.com/legal/legal#privacy"
          rel="noopener noreferrer"
          target="_blank"
          className="transition ease hover:text-gray-brand-500"
        >
          Privacy policy
        </a>
      </div>
    );
  }
}

export default Footer;
