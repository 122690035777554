const formatIndent = (string) => {
  let lines = string.split('\n');
  let indentation;
  const matches = /^[\s\t]+/.exec(lines[0]);

  if (lines[0] === '') lines.shift();

  if (matches) indentation = matches[0];

  if (indentation) {
    lines = lines.map(function (line) {
      return line.replace(indentation, '').replace(/\t/g, '    ');
    });
  }

  return indentation ? lines.join('\n').trim() : string;
};

const displayTestResponse = ({ response, productId, setTestInformation }) => {
  const statusCode = response.status;

  let data = response.data;
  if (productId === 'scrape') {
    data = data.replace('<', '&lt;');
    data = data.replace('>', '&gt;');
  } else {
    data = JSON.stringify(response.data, undefined, 4);
  }

  setTestInformation((prev) => ({ ...prev, result: data }));

  const statusMap = {
    200: { text: 'Success', color: 'green' },
    400: { text: 'Bad request', color: 'red' },
    401: { text: 'Unauthorized', color: 'red' },
    402: { text: 'Payment required', color: 'yellow' },
    422: { text: 'Quota reached', color: 'yellow' },
    429: { text: 'Too many requests', color: 'yellow' },
  };

  const status = statusMap[statusCode];
  if (status) {
    setTestInformation((prev) => ({
      ...prev,
      statusText: status.text,
      statusColor: status.color,
    }));
  }
};

export { displayTestResponse, formatIndent };
