const trackPayload = (productName, testUrl) => {
  const productNameLower = productName.toLowerCase();

  if (productNameLower.includes('email')) {
    return testUrl.match(/email=([^&]*)/)?.[1] || null;
  } else if (productNameLower.includes('ip')) {
    return testUrl.match(/ip_address=([^&]*)/)?.[1] || null;
  } else if (productNameLower.includes('phone')) {
    return testUrl.match(/phone=([^&]*)/)?.[1] || null;
  } else if (productNameLower.includes('iban')) {
    return testUrl.match(/iban=([^&]*)/)?.[1] || null;
  } else if (productNameLower.includes('exchange')) {
    const base = testUrl.match(/base=([^&]*)/)?.[1] || null;
    const target = testUrl.match(/target=([^&]*)/)?.[1] || null;
    return { base, target };
  } else if (productNameLower.includes('avatar')) {
    return testUrl.match(/name=([^&]*)/)?.[1] || null;
  } else if (productNameLower.includes('screenshot')) {
    return testUrl.match(/url=([^&]*)/)?.[1] || null;
  } else if (productNameLower.includes('scrape')) {
    return testUrl.match(/url=([^&]*)/)?.[1] || null;
  } else if (productNameLower.includes('vat')) {
    return testUrl.match(/vat_number=([^&]*)/)?.[1] || null;
  } else if (productNameLower.includes('timezone')) {
    return testUrl.match(/location=([^&]*)/)?.[1] || null;
  } else if (productNameLower.includes('holidays')) {
    const country = testUrl.match(/country=([^&]*)/)?.[1] || null;
    const year = testUrl.match(/year=([^&]*)/)?.[1] || null;
    const month = testUrl.match(/month=([^&]*)/)?.[1] || null;
    const day = testUrl.match(/day=([^&]*)/)?.[1] || null;
    return { country, year, month, day };
  } else if (productNameLower.includes('companyenrichment')) {
    return testUrl.match(/domain=([^&]*)/)?.[1] || null;
  }

  return null;
};

export { trackPayload };
