export async function identify() {
  try {
    // Only run identify if there is a user_id
    let data = JSON.parse(localStorage.getItem('user_info'));
    if (data && data.id && data.email) {
      let userId = data.id;
      let email = data.email;
      let traits = { email: email };
      window.analytics.identify(userId, traits);
    }
  } catch (err) {
    // Tracking disabled by client
  }
}

export async function identifyWithTraits(traits) {
  try {
    // Only run identify if there is a user_id
    let data = JSON.parse(localStorage.getItem('user_info'));
    if (data && data.id && data.email) {
      let userId = data.id;
      let email = data.email;
      traits['email'] = email;
      window.analytics.identify(userId, traits);
    }
  } catch (err) {
    console.log('Error executing identifyWithTraits', err);
  }
}

export async function trackEventPageLoad(pageName) {
  try {
    identify();
    window.analytics.track(`App page loaded - ${pageName}`, {
      referrer: document.referrer,
      current_url: window.location.href,
      path: window.location.pathname,
    });
  } catch (err) {
    // Tracking disabled by client
  }
}

export async function trackEventModalViewed(modalName, data) {
  try {
    // identify();

    // Make sure it's lower case
    modalName = modalName.toLowerCase();

    let pageData = {
      referrer: document.referrer,
      current_url: window.location.href,
      path: window.location.pathname,
    };
    let properties = Object.assign({}, pageData, data);

    window.analytics.track(`Modal viewed - ${modalName}`, properties);
  } catch (err) {
    // Tracking disabled by client
  }
}

export async function trackEvent(eventName, data) {
  try {
    // identify();

    let pageData = {
      referrer: document.referrer,
      current_url: window.location.href,
      path: window.location.pathname,
    };
    let properties = Object.assign({}, pageData, data);

    window.analytics.track(`${eventName}`, properties);
  } catch (err) {
    // Tracking disabled by client
  }
}

export async function resetAnalyticsSession() {
  try {
    window.analytics.reset();
  } catch (err) {
    // Tracking disabled by client
  }
}
