import { useEffect } from 'react';
import { trackEventPageLoad } from '../../Utils/SegmentAnalytics';

import { ChatIcon, MailIcon, CalendarIcon } from '@heroicons/react/outline';

function Support() {
  useEffect(() => {
    trackEventPageLoad('product support');
  });

  function showLiveChat() {
    try {
      window.Intercom.show();
    } catch (err) {
      // Client has ad blocker
    }
  }

  return (
    <>
      <h2 className="text-xl font-medium">Support</h2>

      <div className="mt-4 text-sm">
        If you have any questions about the API or need help, you can contact us
        below.
      </div>

      <div className="mt-10 text-sm flex items-stretch flex-wrap -mx-3 overflow-hidden">
        <div className="my-3 px-3 w-1/3">
          <button
            onClick={showLiveChat}
            className="w-full h-full flex-row items-center px-6 py-20 border border-gray-200 shadow-sm text-base font-medium rounded-md bg-white transition ease hover:border-black-abstract-branding-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500"
          >
            <ChatIcon className="w-10 m-auto" />
            <div className="font-bold ">Live chat</div>
            <span className="inline-flex items-center px-2.5 py-0.5 text-xs rounded-md font-medium bg-green-100 text-green-800">
              Currently live
            </span>
          </button>
        </div>

        <div className="my-3 px-3 w-1/3">
          <button
            onClick={() =>
              window.open('mailto:hello@abstractapi.com', '_blank')
            }
            className="w-full h-full flex-row items-center px-6 py-20 border border-gray-200 shadow-sm text-base font-medium rounded-md bg-white transition ease hover:border-black-abstract-branding-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500"
          >
            <MailIcon className="w-10 m-auto" />
            <div className="font-bold ">Email</div>
          </button>
        </div>

        {/*
                    <div className="my-3 px-3 w-1/3">
                        <button onClick={() => (window.open("https://calendly.com/abstractapi/15min", "_blank"))} className="w-full h-full flex-row items-center px-6 py-20 border border-gray-200 shadow-sm text-base font-medium rounded-md bg-white transition ease hover:border-black-abstract-branding-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500">
                            <CalendarIcon className="w-10 m-auto"/>
                            <div className="font-bold ">
                                Schedule call
                            </div>
                        </button>
                    </div>
                 */}
      </div>
    </>
  );
}

export default Support;
