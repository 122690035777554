import { useState } from 'react';

import productsJson from '../../../assets/json/products.json';

const useRefreshApiData = ({ api, APIrefreshUserData, getUserApisData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [publicProductData, setPublicProductData] = useState(null);
  const [usersProductData, setUsersProductData] = useState(null);

  const refreshApiData = async () => {
    setIsLoading(true);

    let product = productsJson.products[api];

    setPublicProductData(product);

    try {
      await APIrefreshUserData();
      let usersApis = getUserApisData();
      let data = usersApis.filter((api) => api.api_id === product.id);
      if (Object.keys(data).length > 0) {
        setUsersProductData(
          usersApis.filter((api) => api.api_id === product.id)[0],
        );
      }
    } catch (error) {
      console.error('Error refreshing API data');
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, publicProductData, usersProductData, refreshApiData };
};

export default useRefreshApiData;
