import React, { useContext, useEffect, useState } from 'react';
import { ExclamationIcon } from '@heroicons/react/outline';
import APIContext from '../../../contexts/APIContext';
import { trackEventPageLoad } from '../../Utils/SegmentAnalytics';

const SubHeader = React.memo(function SubHeader(props) {
  const { APImanageBilling } = useContext(APIContext);
  const [showPaymentAlert, setShowPaymentAlert] = useState(false);

  useEffect(() => {
    const message = window.location.search.match(/message=([^&]*)/);
    if (message && message[1] === 'failed-payment') {
      setShowPaymentAlert(true);
    }
  }, []);

  async function goToBilling() {
    await APImanageBilling().then((url) => {
      window.open(url, '_self');
    });

    trackEventPageLoad('Payment failed - Billing pageview');
  }

  return (
    <div>
      {showPaymentAlert ? (
        <div className="bg-yellow-400 rounded-md p-2">
          <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex">
              <div className="flex w-0 flex-1 items-center">
                <span className="flex rounded-lg bg-yellow-100 p-2">
                  <ExclamationIcon
                    className="h-6 w-6 text-yellow-400"
                    aria-hidden="true"
                  />
                </span>
                <p className="ml-3 font-medium text-white">
                  <span className="">
                    To avoid disruptions to your account, please update your
                    payment method.
                  </span>
                </p>
              </div>
              <div className="flex items-center flex-col sm:flex-row">
                <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                  <a
                    onClick={() => goToBilling()}
                    className="sm:ml-2 flex items-center justify-center cursor-pointer rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-yellow-600 shadow-sm hover:bg-yellow-50"
                  >
                    Update payment method
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <h1 className="text-2xl font-semibold mt-6 pb-4">{props.title}</h1>
    </div>
  );
});

export default SubHeader;
