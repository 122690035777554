import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import APIContext from '../../contexts/APIContext';
import { trackEventPageLoad, trackEvent } from '../Utils/SegmentAnalytics';

import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/solid';
import { SocialProof } from './SocialProof';
import { useCreateGoogleCallback, GoogleButton } from './GoogleButton';

function Login() {
  const apiContext = useContext(APIContext);
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('Please try again');
  const [displayErrorMessage, setDisplayErrorMessage] = useState('none');
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState('none');
  const [isLoading, setIsLoading] = useState(false);
  const googleSignInCallback = useCreateGoogleCallback(
    'signin',
    setErrorMessage,
    setDisplayErrorMessage,
  );

  useEffect(() => {
    trackEventPageLoad('login');
  }, []);

  async function login(event) {
    event.preventDefault();
    setDisplayErrorMessage('none');

    trackEvent('Login form submitted', {
      email: email,
    });

    setIsLoading(true);

    if (!email) {
      setIsLoading(false);
      let message = 'Please enter your email';
      setErrorMessage(message);
      setDisplayErrorMessage('block');

      trackEvent('Login form rejected', {
        email: email,
        message: message,
      });
    } else if (!password) {
      setIsLoading(false);
      let message = 'Please enter your password';
      setErrorMessage(message);
      setDisplayErrorMessage('block');

      trackEvent('Login form rejected', {
        email: email,
        message: message,
      });
    } else {
      setDisplaySuccessMessage('none');
      await apiContext
        .APIlogin(email, password)
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            setDisplayErrorMessage('none');
            setErrorMessage('');
            history.push('/');
          } else {
            setDisplayErrorMessage('block');
            let message = 'Please verify your email and password.';
            setErrorMessage(message);
            trackEvent('Login form rejected', {
              email: email,
              status: response.status,
              message: message,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);

          let errorMessage = 'Incorrect email or password. Please try again.';

          if (!error.response) {
            errorMessage = "We couldn't log you in. Please contact support.";
          } else if (
            error.response.data.error?.decline_code === 'email_not_verified'
          ) {
            setDisplaySuccessMessage('block');
            errorMessage = '';
          } else if (error.response.status === 429) {
            errorMessage =
              'Too many failed login attempts. Please contact support.';
          } else if (error.response.status === 403) {
            errorMessage = error.response.data.error.message;
          }

          if (errorMessage) {
            setDisplayErrorMessage('block');
            setErrorMessage(errorMessage);
          }
          trackEvent('Login form rejected', {
            email: email,
            status: error.response ? error.response.status : '',
            message: errorMessage,
          });
        });
    }
  }

  return (
    <div className="flex relative flex-col items-stretch lg:flex-row lg:min-h-full md:min-h-none">
      <img
        className="absolute top-10 left-8 md:left-18"
        src="/assets/media/logos/abstract-dark-light.svg"
        alt="Workflow"
        width="130px"
      />
      <div className="flex-1 justify-center pt-36 pb-20 lg:py-12 px-8 lg:max-w-auth sm:w-full lg:px-4 bg-gray-50 block items-center lg:flex">
        <div className="sm:mx-auto w-full lg:h-100 lg:w-96 sm:max-w-sm">
          <div className="pb-8 px-4 sm:px-10 mt-20">
            <h2 className="text-left text-3xl font-extrabold text-gray-900">
              Login
            </h2>
            <p className="mt-2 mb-2 text-left text-sm text-gray-600 lg:mb-2">
              Welcome back
            </p>
            <GoogleButton
              callback={googleSignInCallback}
              context={'signin'}
              width="300px"
            />
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-brand-500 focus:border-blue-brand-500 sm:text-sm"
                    placeholder="jane@doe.com"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    disabled={displaySuccessMessage == 'block'}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-brand-500 focus:border-blue-brand-500 sm:text-sm"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    disabled={displaySuccessMessage == 'block'}
                  />
                </div>
              </div>
              <button
                type="button"
                disabled={
                  !(
                    email &&
                    password &&
                    !isLoading &&
                    displaySuccessMessage == 'none'
                  )
                }
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-sm font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 disabled:opacity-50"
                onClick={login}
              >
                {isLoading ? (
                  <>
                    <svg
                      className="animate-spin h-5 w-5 mr-3"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        fill="transparent"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Logging in
                  </>
                ) : (
                  <>Login</>
                )}
              </button>
              <div className="flex items-center justify-end">
                <div className="text-sm">
                  <Link
                    to="/users/password/reset"
                    className="font-medium text-blue-brand-500 hover:text-blue-brand-700"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
              <div
                className="rounded-md bg-red-50 p-4"
                style={{ display: displayErrorMessage }}
              >
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      {errorMessage}
                    </h3>
                  </div>
                </div>
              </div>
              <div
                className="mt-4 rounded-md bg-green-50 p-4"
                style={{ display: displaySuccessMessage }}
              >
                <div className="ml-3 text-sm text-green-700">
                  <div className="mt-2 flex">
                    <CheckCircleIcon
                      className="h-5 w-5 text-green-400 mr-2"
                      aria-hidden="true"
                    />
                    <p>
                      <strong>{`We've sent a verification link to ${email}`}</strong>
                      <br />
                      <br />
                      Please click it to activate your account.
                    </p>
                  </div>
                </div>
              </div>
            </form>
            <div
              className="mt-6"
              style={{
                display: displaySuccessMessage == 'block' ? 'none' : 'block',
              }}
            >
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-gray-50 text-gray-500">OR</span>
                </div>
              </div>

              <div className="mt-6">
                <div>
                  <Link
                    to="/users/signup"
                    className="w-full flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-brand-500"
                  >
                    Create an account
                  </Link>
                </div>
              </div>
              <div
                style={{
                  width: '304px',
                  height: '25px',
                  visibility: 'hidden',
                  possition: 'absolute',
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 bg-white flex items-center">
        <SocialProof />
      </div>
    </div>
  );
}

export default Login;
