import { useContext, forwardRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { identifyWithTraits, trackEvent } from '../../Utils/SegmentAnalytics';
import { SelectableCards } from '../../Layout/index';
import { productOptions } from './OnboardingCards.string';
import APIContext from '../../../contexts/APIContext';
import CacheContext from '../../../contexts/CacheContext';
import { getKebabCase } from '../../../namingConventions/products';

import { trackProductInterest } from '../helpers';

const OnboardingCards = forwardRef((props, ref) => {
  const { eventProperties, setEventProperties, mainInterest } = props;
  const { APIpushIdentifyEvent } = useContext(APIContext);
  const { setUserInterest } = useContext(CacheContext);
  const { push } = useHistory();
  const traitName = 'main_interest';

  function selectedItem(item, mainInterest) {
    let interest = mainInterest || getKebabCase(item?.title);
    let selectedManually = trackProductInterest(
      setEventProperties,
      eventProperties,
    );

    const completeEventProperties = {
      ...eventProperties,
      main_interest: interest,
      user_manually_selected_main_interest: selectedManually,
    };

    setUserInterest(interest);
    trackEvent('Onboarding form completed', completeEventProperties);
    identifyWithTraits(completeEventProperties);
    APIpushIdentifyEvent(interest);

    const updatedEventProperties = { ...completeEventProperties };
    delete updatedEventProperties.answer;
    delete updatedEventProperties.main_interest;
    delete updatedEventProperties[traitName];
    setEventProperties(updatedEventProperties);

    push(
      `/api/${interest}/pricing/select?pricing_cta=onboarding&from_onboarding=true`,
    );
  }

  useEffect(() => {
    if (mainInterest) {
      selectedItem(null, mainInterest);
    }
  }, [mainInterest]);

  const handleSelectedItem = (item) => {
    selectedItem(item);
  };

  return (
    <SelectableCards
      ref={ref}
      itemsList={productOptions.items}
      handleSelectedItem={handleSelectedItem}
      allItems={productOptions.items}
    />
  );
});

export default OnboardingCards;
