const formatIndent = (string) => {
  let lines = string.split('\n');
  let indentation;
  const matches = /^[\s\t]+/.exec(lines[0]);

  if (lines[0] === '') lines.shift();

  if (matches) indentation = matches[0];

  if (indentation) {
    lines = lines.map(function (line) {
      return line.replace(indentation, '').replace(/\t/g, '    ');
    });
  }

  return indentation ? lines.join('\n').trim() : string;
};

const generateCodeSnippet = (language, productId, testUrl, apiKey) => {
  let snippet = '';
  switch (language) {
    case 'cURL':
      if (productId === 10) {
        // Images API
        snippet = `
                  curl https://images.abstractapi.com/v1/url/ -X POST -H "Content-Type: application/json" -H "Accept: application/json" -d '{"api_key": "${apiKey}", "lossy": true, "url": "https://s3.amazonaws.com/static.abstractapi.com/test-images/dog.jpg"}`;
      } else {
        // Other APIs
        snippet = `
                  curl '${testUrl}'`;
      }

      return snippet;
    case 'Javascript':
      // Checked and working
      snippet = `
              function httpGetAsync(url, callback) {
                  const xmlHttp = new XMLHttpRequest();
                  xmlHttp.onreadystatechange = function() {
                      if (xmlHttp.readyState === 4 && xmlHttp.status === 200)
                      callback(xmlHttp.responseText);
                  }
                  xmlHttp.open("GET", url, true); // true for asynchronous
                  xmlHttp.send(null);
              }

              const url = "${testUrl}"

              httpGetAsync(url)`;

      return snippet;
    case 'jQuery':
      snippet = `
              $.getJSON("${testUrl}", function(data) {
                  console.log(data);
              })`;

      return snippet;
    case 'NodeJS':
      // Checked and working
      snippet = `
              const axios = require('axios');
              axios.get('${testUrl}')
                  .then(response => {
                      console.log(response.data);
                  })
                  .catch(error => {
                      console.log(error);
                  });`;

      return snippet;
    case 'Python':
      snippet = `
              import requests

              response = requests.get("${testUrl}")
              print(response.status_code)
              print(response.content)`;

      return snippet;
    case 'Ruby':
      snippet = `
              require 'net/http'
              require 'net/https'

              def make_abstract_request
                  uri = URI('${testUrl}')

                  http = Net::HTTP.new(uri.host, uri.port)
                  http.use_ssl = true
                  http.verify_mode = OpenSSL::SSL::VERIFY_PEER

                  request =  Net::HTTP::Get.new(uri)

                  response = http.request(request)
                  puts "Status code: #{ response.code }"
                  puts "Response body: #{ response.body }"
              rescue StandardError => error
                  puts "Error (#{ error.message })"
              end

              make_abstract_request()`;

      return snippet;
    case 'Java':
      snippet = `
              import java.io.IOException;
              import org.apache.http.client.fluent.*;

              public class makeAbstractRequest
              {
              public static void main(String[] args) {
                  makeAbstractRequest();
              }

              private static void makeAbstractRequest() {

                  try {

                      Content content = Request.Get('${testUrl}')

                      .execute().returnContent();

                      System.out.println(content);
                  }
                  catch (IOException error) { System.out.println(error); }
              }
              }`;

      return snippet;
    case 'PHP':
      // Checked and working
      snippet = `
              // Initialize cURL.
              $ch = curl_init();

              // Set the URL that you want to GET by using the CURLOPT_URL option.
              curl_setopt($ch, CURLOPT_URL, '${testUrl}');

              // Set CURLOPT_RETURNTRANSFER so that the content is returned as a variable.
              curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);

              // Set CURLOPT_FOLLOWLOCATION to true to follow redirects.
              curl_setopt($ch, CURLOPT_FOLLOWLOCATION, true);

              // Execute the request.
              $data = curl_exec($ch);

              // Close the cURL handle.
              curl_close($ch);

              // Print the data out onto the page.
              echo $data;
              `;

      return snippet;
    case 'Go':
      snippet = `
              package main

              import (
              "net/http"
              "log"
              "io/ioutil"
              )

              func main() {
              MakeRequest()
              }

              func MakeRequest() {
              resp, err := http.Get("${testUrl}")
              if err != nil {
                  log.Fatalln(err)
              }

              body, err := ioutil.ReadAll(resp.Body)
              if err != nil {
                  log.Fatalln(err)
              }

              log.Println(string(body))
              }`;

      return snippet;
    default:
      return '';
  }
};

const getCodeSnippet = (language, productId, testUrl, apiKey) => {
  // Generating code snippet then formatting indentation and spacing
  return formatIndent(
    generateCodeSnippet(language, productId, testUrl, apiKey),
  );
};

const removeParameterFromUrl = (url, parameter) => {
  return url
    .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
    .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');
};

const displayTestResponse = ({ response, productId, setTestInformation }) => {
  const statusCode = response.status;

  let data = response.data;
  if (productId === 'scrape') {
    data = data.replace('<', '&lt;');
    data = data.replace('>', '&gt;');
  } else {
    data = JSON.stringify(response.data, undefined, 4);
  }

  setTestInformation((prev) => ({ ...prev, result: data }));

  const statusMap = {
    200: { text: 'Success', color: 'green' },
    400: { text: 'Bad request', color: 'red' },
    401: { text: 'Unauthorized', color: 'red' },
    402: { text: 'Payment required', color: 'yellow' },
    422: { text: 'Quota reached', color: 'yellow' },
    429: { text: 'Too many requests', color: 'yellow' },
  };

  const status = statusMap[statusCode];
  if (status) {
    setTestInformation((prev) => ({
      ...prev,
      statusText: status.text,
      statusColor: status.color,
    }));
  }
};

export {
  formatIndent,
  getCodeSnippet,
  removeParameterFromUrl,
  displayTestResponse,
};
