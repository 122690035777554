import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { trackEvent } from '../Utils/SegmentAnalytics';

const TrackerCallScheduled = () => {
  const history = useHistory();
  const [showMessage, setShowMessage] = useState(true);
  const [urlToRedirect, setUrlToRedirect] = useState('dashboard');

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem('user_info'));
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const isUserAuthenticated = data && data.id;

    if ('redirect' in params) {
      setUrlToRedirect(params.redirect || '/dashboard');
    } else {
      if (isUserAuthenticated) {
        setUrlToRedirect('dashboard');
      } else {
        setUrlToRedirect('https://www.abstractapi.com/');
      }
    }

    if (isUserAuthenticated) {
      params.id = data.id;
    }

    trackEvent('Call Scheduled', params);

    const timeout = setTimeout(() => {
      setShowMessage(false);
      window.location.href = urlToRedirect;
    }, 5000);

    return () => clearTimeout(timeout);
  }, [history, urlToRedirect]);

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ width: 300 + 'px', margin: '100px auto 40px auto' }}>
        <img
          className="mx-auto h-12 w-auto"
          src="/assets/media/logos/abstract-dark-light.svg"
          alt=""
        ></img>
      </div>
      <div
        className="kt-portlet"
        style={{ width: 300 + 'px', margin: '20px auto 20px auto' }}
      >
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="font-extrabold text-gray-900">
              {showMessage && (
                <p>
                  Your call has been scheduled. You will be redirected to{' '}
                  {urlToRedirect.startsWith('http')
                    ? urlToRedirect
                    : `the ${urlToRedirect}`}
                  .
                </p>
              )}
            </h3>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default TrackerCallScheduled;
