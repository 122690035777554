import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useRef,
} from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline';

import APIContext from '../../contexts/APIContext';
import CacheContext from '../../contexts/CacheContext';
import { CSVReader } from 'react-papaparse';
import {
  trimTrailingEmptyValues,
  validatorCSV,
  summarizeCSVErrors,
  generateBulkUploadCSVURL,
  generateRowDescription,
  getHowMuchErrorsFound,
} from '../Utils/Helpers';
import { trackEvent } from '../Utils/SegmentAnalytics';

const buttonRef = React.createRef();

const BulkUploadModal = (props) => {
  const { getUserInformation } = useContext(CacheContext);
  const { processCSV } = useContext(APIContext);

  const [rowsData, setRowsData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessagesSummary, setErrorMessagesSummary] = useState([]);
  const [uploadState, setUploadState] = useState('default'); // default > processing > success || failed
  const [isLoading, setIsLoading] = useState(false);
  const [disableUploadButton, setDisableUploadButton] = useState(false);
  const bulklUploadCSVURLHelp = generateBulkUploadCSVURL(props.productPath);
  const cancelButtonRef = useRef(null);
  const [hasHeaders, setHasHeaders] = useState(true);
  const [columnOptions, setColumnOptions] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState('');
  const [rawRows, setRawRows] = useState([]);
  const rowDescription = generateRowDescription(props.productPath);
  const [howMuchErrorsFound, setHowMuchErrorsFound] = useState('');

  const getProductNameDisplay = (slugApiName) => {
    return slugApiName
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handlerError = (validationErrors) => {
    setUploadState('failed');
    setDisableUploadButton(false);

    const { errorCodes, errorMessagesSummary, errors } = summarizeCSVErrors(
      validationErrors,
      props.productPath,
    );
    setHowMuchErrorsFound(getHowMuchErrorsFound(errorMessagesSummary));

    setErrorMessagesSummary(errorMessagesSummary); // errors who will be showed as sugerences.
    setErrorMessage(errors); // errors who will be showed in details.

    trackEvent('CSV format check failed', {
      product_name: getProductNameDisplay(props.productPath),
      detail: errorCodes, // short errors who will be showed in the tracker.
    });

    return null;
  };

  useEffect(() => {
    setErrorMessage('');
    setUploadState('default');
    setDisableUploadButton(false);
    setErrorMessagesSummary([]);
    return () => {
      setErrorMessage('');
      setUploadState('default');
      setDisableUploadButton(false);
      setRowsData([]);
    };
  }, [props.isVisible]);

  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data, fileInfo) => {
    setErrorMessage('');
    setRawRows(data);
    setHasHeaders(true);

    // Store first row for column selection
    if (data.length > 0) {
      setColumnOptions(
        data[0].data.map((col, index) => ({
          label: col,
          value: index,
        })),
      );
    } else {
      setErrorMessage('Error it not data to analyze ');
      setColumnOptions([]);
    }
    setIsLoading(false);
  };

  const handleHeaderCheckboxChange = (e) => {
    setHasHeaders(e.target.checked);
  };

  const handleColumnChange = (e) => {
    const columnIndex = parseInt(e.target.value, 10);

    // Check if columnIndex is not an integer or less than or equal to 0
    if (isNaN(columnIndex) || columnIndex < 0) {
      setSelectedColumn(-1);
      setDisableUploadButton(false);
      setIsLoading(false);
      return;
    }

    setSelectedColumn(columnIndex);

    let rows = [];
    rawRows.forEach(function (e) {
      rows.push(e.data[columnIndex]);
    });

    if (isNaN(columnIndex) || columnIndex < 0) {
      setErrorMessage('Please select a column to analyze');
      setDisableUploadButton(false);
      return;
    }
    // Get rows for analysis
    let rowsToAnalyze = trimTrailingEmptyValues(rows);
    if (hasHeaders) {
      rowsToAnalyze = rows.slice(1);
    }
    setRowsData(rowsToAnalyze);
    setIsLoading(false);
    setRowsData(rowsToAnalyze);
    setIsLoading(false);
    setDisableUploadButton(true);
    setErrorMessage('');
    setErrorMessage('');
  };

  const uploadCSV = async (event) => {
    setIsLoading(true);
    setErrorMessage('');
    setErrorMessagesSummary([]);
    setUploadState('processing');
    setUploadState('default');
    setSelectedColumn(-1);
    const errors = validatorCSV(rowsData, props.productPath);

    // If column validation passes, check the rest of CSV validation
    if (errors.length > 0) {
      handlerError(errors);
      setDisableUploadButton(false);
      setIsLoading(false);
      return;
    }

    await processCSV(props.apiKey, props.productPath, rowsData)
      .then((response) => {
        // Track event for CSV processing queued
        trackEvent('CSV processing queued', {
          rows: rowsData.length,
          has_header: hasHeaders,
        });
        setUploadState('success');
        setRowsData([]);
        props.setFilesUploaded((prevFiles) => [response.data, ...prevFiles]);
        setDisableUploadButton(false);
        setIsLoading(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setUploadState('failed');
        setDisableUploadButton(false);
        setIsLoading(false);

        // Track event for CSV processing failed
        trackEvent('CSV processing failed', {
          rows: rowsData.length,
          has_header: hasHeaders,
        });
        setIsLoading(false);

        // Track event for CSV processing failed
        trackEvent('CSV processing failed', {
          rows: rowsData.length,
          has_header: hasHeaders,
        });
      });
    setIsLoading(false);
  };

  const closeModal = () => {
    props.closeModal();
    setErrorMessagesSummary([]);
    setErrorMessage('');
  };

  const getModalTitle = (uploadState) => {
    if (uploadState === 'default' || uploadState === 'processing') {
      return 'Upload CSV';
    } else if (uploadState === 'success') {
      return 'Your upload was successful and is now processing';
    }
    return 'An error occurred during your upload.';
  };

  return (
    <>
      <Transition.Root show={props.isVisible} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-50 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={true}
          onClose={() => closeModal()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {uploadState === 'success' ? (
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                  ) : undefined}
                  {uploadState === 'failed' ? (
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                  ) : undefined}
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium sm:text-center mt-3"
                    >
                      {getModalTitle(uploadState)}
                    </Dialog.Title>
                    <div className="mt-2">
                      {uploadState === 'default' ||
                      uploadState === 'processing' ? (
                        <>
                          <p className="text-sm text-gray-500">
                            Please select a CSV file
                          </p>
                          <div className="mt-3">
                            <CSVReader
                              ref={buttonRef}
                              onFileLoad={handleOnFileLoad}
                              noClick
                              noDrag
                            >
                              {({ file }) => (
                                <aside
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginBottom: 10,
                                  }}
                                >
                                  <button
                                    type="button"
                                    onClick={handleOpenDialog}
                                    style={{
                                      borderRadius: 0,
                                      marginLeft: 0,
                                      marginRight: 0,
                                      width: '100%',
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                    }}
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                  >
                                    Browse files
                                  </button>
                                  <p className="text-sm text-gray-500 mt-3">
                                    {file && file.name}
                                  </p>
                                  {file && columnOptions.length > 0 && (
                                    <>
                                      <div className="mt-4">
                                        <label className="block text-sm text-gray-700 mb-2">
                                          <input
                                            type="checkbox"
                                            checked={hasHeaders}
                                            onChange={
                                              handleHeaderCheckboxChange
                                            }
                                            className="mr-2"
                                          />
                                          The first row contains headers.
                                        </label>
                                      </div>
                                      <div className="mt-4">
                                        <label className="block text-sm text-gray-700 mb-2">
                                          Select column with {rowDescription}
                                        </label>
                                        <select
                                          value={selectedColumn}
                                          onChange={handleColumnChange}
                                          className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-black-abstract-branding-500 focus:border-black-abstract-branding-500"
                                        >
                                          <option value={-1}>
                                            Select a column{' '}
                                          </option>
                                          {columnOptions.map(
                                            (column, index) => (
                                              <option
                                                key={index}
                                                value={column.value}
                                              >
                                                Column n°{index + 1} (
                                                {column.label || 'empty row '})
                                              </option>
                                            ),
                                          )}
                                        </select>
                                      </div>
                                    </>
                                  )}
                                </aside>
                              )}
                            </CSVReader>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {uploadState === 'success' ? (
                        <p className="text-gray-500 sm:text-center">
                          We'll email the results to{' '}
                          {getUserInformation().email} when they're ready.
                        </p>
                      ) : (
                        <></>
                      )}
                      <div>
                        {uploadState === 'failed' ? (
                          <p className="text-gray-500 sm:text-center">
                            Please ensure the CSV you uploaded follows the
                            guidelines:
                          </p>
                        ) : (
                          <></>
                        )}
                        {errorMessagesSummary &&
                        errorMessagesSummary.length > 0 ? (
                          <>
                            <div
                              className="alert alert-solid-danger alert-bold"
                              role="alert"
                            >
                              <ul className="list-disc mt-4 mb-4 ml-3">
                                {errorMessagesSummary.map((message, index) => (
                                  <li
                                    key={index}
                                    className="text-gray-500  ml-2 sm:text-left"
                                  >
                                    {message}
                                  </li>
                                ))}
                              </ul>
                              <p className="text-gray-500 sm:text-left">
                                You can
                                <a
                                  href={bulklUploadCSVURLHelp}
                                  className="transition text-blue-brand-500 ease hover:text-blue-brand-500 mr-3"
                                  target="_blank"
                                  style={{ marginRight: '0px' }}
                                >
                                  &nbsp;learn more here
                                </a>{' '}
                                and if you’re still having trouble, please
                                contact support.
                              </p>
                              {errorMessage && (
                                <div className="alert-text ml-2 mt-6 text-sm text-gray-500">
                                  <details>
                                    <summary>View details</summary>
                                    <br />
                                    <p className="mb-3 mt-3 text-color-red">
                                      {howMuchErrorsFound}. Below you'll find
                                      further details:
                                    </p>
                                    <div
                                      style={{
                                        maxHeight: '200px',
                                        overflowY: 'auto',
                                      }}
                                    >
                                      {errorMessage
                                        .slice(0, 50)
                                        .map((error, index) => (
                                          <React.Fragment key={index}>
                                            {error}
                                            <br />
                                          </React.Fragment>
                                        ))}
                                    </div>
                                  </details>
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          errorMessage &&
                          uploadState === 'failed' && (
                            <>
                              <p className="text-gray-500 sm:text-left">
                                You can
                                <a
                                  href={bulklUploadCSVURLHelp}
                                  className="transition text-blue-brand-500 ease hover:text-blue-brand-500 mr-3"
                                  target="_blank"
                                  style={{ marginRight: '0px' }}
                                >
                                  &nbsp;learn more here
                                </a>{' '}
                                and if you’re still having trouble, please
                                contact support.
                              </p>
                              <div className="alert-text text-sm text-gray-500">
                                {errorMessage}
                              </div>
                            </>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  {uploadState === 'success' ||
                  uploadState === 'default' ||
                  uploadState === 'failed' ? (
                    <>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => closeModal()}
                      >
                        Close
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                  {uploadState === 'failed' && (
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() =>
                        setUploadState('default') ||
                        setErrorMessage('') ||
                        setErrorMessagesSummary([])
                      }
                    >
                      Upload CSV
                    </button>
                  )}
                  {uploadState === 'default' || uploadState === 'processing' ? (
                    <button
                      type="button"
                      className={`mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm ${
                        errorMessage !== '' || !disableUploadButton
                          ? 'opacity-50 cursor-not-allowed'
                          : ''
                      }`}
                      onClick={() => uploadCSV()}
                      disabled={errorMessage !== '' || !disableUploadButton}
                      ref={cancelButtonRef}
                    >
                      {isLoading ? (
                        <>
                          <svg
                            className="animate-spin h-5 w-5 mr-3"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              fill="transparent"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Uploading
                        </>
                      ) : (
                        'Upload CSV'
                      )}
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default BulkUploadModal;
