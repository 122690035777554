import { trackEvent } from '../Utils/SegmentAnalytics';
import productsJson from '../../assets/json/products.json';

export const get50Off = (price) => {
  return Math.ceil(price * 0.5).toLocaleString('en-US');
};

export const generateRejectOfferURL = (productName) => {
  let product =
    productsJson.products[productName.toLowerCase().replace(/\s+/g, '-')];

  return `/api/${product.path}/tester`;
};

export const generateAcceptOfferCallbackURL = (productName) => {
  let productNameFormatted = productName.replace(/\s+/g, '-').toLowerCase();
  return (
    window.location.origin +
    `/api/${productNameFormatted}/pricing/select?pricing_cta=upsell_section&was_offered=true`
  );
};

export const trackEventUpsell = (eventName, eventDetails) => {
  const eventNameUpsell =
    eventName == 'loadPage'
      ? 'App page loaded - Upsell page'
      : `Upsell page - ${eventName}`;

  trackEvent(eventNameUpsell, {
    product_name: eventDetails.productName,
    billing_cadence: eventDetails.billingCadence,
    plan_price: eventDetails.planPrice,
    plan_api_calls: eventDetails.planApiCalls,
    product_offered_name: eventDetails.productOfferedName,
    plan_offered_price: eventDetails.planOfferedPrice,
    plan_offered_billing_cadence: eventDetails.planOfferedBillingCadence,
    plan_offered_api_calls: eventDetails.planOfferedApiCalls,
    is_default_plan: eventDetails.isDefaultPlan,
    is_default_plan_offered: eventDetails.isDefaultPlanOffered,
    offered_api_name: eventDetails.offeredApiName,
    plan_id: eventDetails.planId,
    plan_offered_id: eventDetails.planOfferedId,
  });
};
