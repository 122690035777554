import React, { useContext, useEffect } from 'react';

import APIContext from '../../contexts/APIContext';

const UpdatePaymentDetails = () => {
  const { APImanageBilling } = useContext(APIContext);

  useEffect(() => {
    goToBilling();
  }, []);

  async function goToBilling() {
    await APImanageBilling().then((url) => {
      window.open(url, '_self');
    });
  }

  return (
    <div style={{ 'text-align': 'center' }}>
      <div style={{ width: 300 + 'px', margin: '100px auto 40px auto' }}>
        <img
          className="mx-auto h-12 w-auto"
          src="/assets/media/logos/abstract-dark-light.svg"
          alt=""
        ></img>
      </div>
      <div
        className="kt-portlet"
        style={{ width: 300 + 'px', margin: '20px auto 20px auto' }}
      >
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="font-extrabold text-gray-900">
              Loading payment details...
            </h3>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default UpdatePaymentDetails;
