import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useRef,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { RadioGroup } from '@headlessui/react';
import APIContext from '../../contexts/APIContext';
import { XCircleIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { trackEvent, trackEventModalViewed } from '../Utils/SegmentAnalytics';

const settings = [
  {
    name: 'Missing functionality',
    description: 'The API is missing functionality',
  },
  { name: 'Data quality', description: 'The API has data quality issues' },
  { name: 'Response time', description: 'The API has response time issues' },
  { name: 'Price', description: 'The API is too expensive' },
  { name: 'No longer needed', description: 'We no longer need the API' },
  { name: 'Other', description: 'Other' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function SubscriptionCancellationModal(props) {
  const [selectedReason, setSelectedReason] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const cancelButtonRef = useRef(null);

  let history = useHistory();

  const { APICancelPlan, APImanageBilling } = useContext(APIContext);

  useEffect(() => {
    if (props.isVisible) {
      trackEventModalViewed('cancel subscription', {
        product_id: props.productId,
      });
    }
  }, [props.isVisible, props.productId]);

  function closeModal() {
    props.closeModal();

    // Redirect to API
    history.goBack();
  }

  async function goToBilling() {
    await APImanageBilling().then((url) => {
      window.open(url, '_self');
    });
  }

  async function cancelPlan() {
    setErrorMessage('');
    setShowSuccessMessage(false);
    setIsLoading(true);

    let trackEventData = {
      product_id: props.productId,
      product_name: props.productName,
      plan_id: props.currentPlan.id,
      plan_name: props.currentPlan.name,
      plan_price: props.currentPlan.base_price,
      plan_included_api_calls: props.currentPlan.included_api_calls,
    };

    trackEvent('Subscription - plan change attempted', trackEventData);

    trackEventData.cancellation_reason = selectedReason['name'];

    APICancelPlan(props.productId, trackEventData)
      .then((response) => {
        setShowSuccessMessage(true);
        setIsLoading(false);
      })
      .catch(() => {
        const message =
          "Your cancellation couldn't be processed. Please try again or contact support.";
        setErrorMessage(message);
        setIsLoading(false);
        trackEvent('Subscription - plan change failed', {
          ...trackEventData,
          error_message: message,
        });
      });
  }

  return (
    <>
      <Transition.Root show={props.isVisible} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={closeModal}
          open={true}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium"
                    >
                      {!showSuccessMessage
                        ? 'Cancel your subscription'
                        : 'Your subscription is now set to be cancelled'}
                    </Dialog.Title>
                    {!showSuccessMessage ? (
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          We are sorry to see you go. What is the main reason
                          for this cancellation?
                        </p>
                        {errorMessage !== '' ? (
                          <div className="mt-3 rounded-md bg-red-50 p-4">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <XCircleIcon
                                  className="h-5 w-5 text-red-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-3">
                                <h3 className="text-sm font-medium text-red-800">
                                  {errorMessage}
                                </h3>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        <RadioGroup
                          value={selectedReason}
                          onChange={setSelectedReason}
                        >
                          <RadioGroup.Label className="sr-only">
                            Privacy setting
                          </RadioGroup.Label>
                          <div className="mt-4 mb-2 bg-white rounded-md -space-y-px">
                            {settings.map((setting, settingIdx) => (
                              <RadioGroup.Option
                                key={setting.name}
                                value={setting}
                                className={({ checked }) =>
                                  classNames(
                                    settingIdx === 0
                                      ? 'rounded-tl-md rounded-tr-md'
                                      : '',
                                    settingIdx === settings.length - 1
                                      ? 'rounded-bl-md rounded-br-md'
                                      : '',
                                    checked
                                      ? 'bg-indigo-50 border-indigo-200 z-10'
                                      : 'border-gray-200',
                                    'relative border p-4 flex cursor-pointer focus:outline-none',
                                  )
                                }
                              >
                                {({ active, checked }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        checked
                                          ? 'bg-gray-brand-500 border-transparent'
                                          : 'bg-white border-gray-300',
                                        active
                                          ? 'ring-2 ring-offset-2 ring-gray-brand-500'
                                          : '',
                                        'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                                      )}
                                      aria-hidden="true"
                                    >
                                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                                    </span>
                                    <div className="ml-3 flex flex-col">
                                      <RadioGroup.Label
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? 'text-indigo-900'
                                            : 'text-gray-900',
                                          'block text-sm font-medium',
                                        )}
                                      >
                                        {setting.name}
                                      </RadioGroup.Label>
                                    </div>
                                  </>
                                )}
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      </div>
                    ) : (
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Your subscription will not renew and will be cancelled
                          automatically at the end of your billing cycle.
                          <br />
                          <br /> You can see that date by heading to your{' '}
                          <span
                            className="font-bold text-gray-brand-500 cursor-pointer"
                            onClick={goToBilling}
                          >
                            billing portal
                          </span>
                          .
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  {!showSuccessMessage ? (
                    <button
                      type="button"
                      disabled={
                        Object.keys(selectedReason).length === 0 ? true : false
                      }
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-brand-500 text-base font-medium text-white hover:bg-gray-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-brand-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50"
                      ref={cancelButtonRef}
                      onClick={() => cancelPlan()}
                    >
                      {isLoading ? (
                        <>
                          <svg
                            class="animate-spin h-5 w-5 mr-3"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              fill="transparent"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Cancelling
                        </>
                      ) : (
                        <>Cancel</>
                      )}
                    </button>
                  ) : (
                    <></>
                  )}
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-brand-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => closeModal()}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default SubscriptionCancellationModal;
