import React from 'react';

const TextArea = ({
  id = 'textarea',
  label,
  rows = 3,
  placeholder = '',
  description = '',
  value,
  onChange = () => {},
  width = 'w-full',
  padding = '',
  border = 'border-none',
  restricted = false,
}) => {
  const handleCopyPaste = (event) => {
    if (restricted) {
      event.preventDefault();
    }
  };

  const textareaStyle = restricted
    ? {
        userSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
      }
    : {};

  return (
    <>
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-normal text-gray-700 mb-4"
        >
          {label}
        </label>
      )}
      <div className={`mt-1 sm:col-span-2 sm:mt-0 ${width}`}>
        <textarea
          id={id}
          rows={rows}
          className={`block w-full bg-gray-new-100 whitespace-nowrap rounded-md ${border} shadow-sm focus:border-indigo-500 focus:ring-indigo-500 mb-6 ${padding} ${
            restricted ? 'cursor-not-allowed select-none' : ''
          } resize-none`}
          placeholder={placeholder}
          onChange={({ target: { value } }) => onChange(value)}
          value={value}
          style={textareaStyle}
          onCopy={handleCopyPaste}
          onPaste={handleCopyPaste}
          onMouseDown={restricted ? (e) => e.preventDefault() : null}
        />
        {description && (
          <p className="mt-2 text-sm text-gray-500 mb-6">{description}</p>
        )}
      </div>
    </>
  );
};

export default TextArea;
