import React from 'react';

import { Fragment, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Dialog, Menu, Transition } from '@headlessui/react';

import productsJson from '../../../assets/json/products.json';

import { MenuAlt2Icon, XIcon } from '@heroicons/react/outline';

import * as HeroIcons from '@heroicons/react/outline';

function Aside() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [desktopSidebarLockedOpen, setdesktopSidebarLockedOpen] =
    useState(false);
  const [desktopSidebarOpen, setDesktopSidebarOpen] = useState(false);
  const [desktopSidebarWidth, setDesktopSidebarWidth] = useState('4rem');
  const [productsCategories, setProductsCategories] = useState({});
  const [products, setProducts] = useState({});
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if (currentPath !== '/call-scheduled') {
      getProducts();
    }
  }, [currentPath]);

  const getProducts = async () => {
    setProducts(productsJson.products);
    setProductsCategories(productsJson.categories);
  };

  const renderIcon = (product) => {
    const components = {
      BellIcon: HeroIcons.BellIcon,
      MailIcon: HeroIcons.MailIcon,
      PhoneIcon: HeroIcons.PhoneIcon,
      TagIcon: HeroIcons.TagIcon,
      ReceiptTaxIcon: HeroIcons.ReceiptTaxIcon,
      CalendarIcon: HeroIcons.CalendarIcon,
      CashIcon: HeroIcons.CashIcon,
      OfficeBuildingIcon: HeroIcons.OfficeBuildingIcon,
      ClockIcon: HeroIcons.ClockIcon,
      UserCircleIcon: HeroIcons.UserCircleIcon,
      CameraIcon: HeroIcons.CameraIcon,
      GlobeAltIcon: HeroIcons.GlobeAltIcon,
      PhotographIcon: HeroIcons.PhotographIcon,
      LocationMarkerIcon: HeroIcons.LocationMarkerIcon,
      LibraryIcon: HeroIcons.LibraryIcon,
    };

    var iconName = products[product]['icon'];

    if (iconName in components) {
      let IconComponentName = components[iconName];
      return (
        <IconComponentName
          className={
            'text-blue-brand-300 group-hover:text-blue-brand-500 flex-shrink-0 h-5 w-5 ' +
            (desktopSidebarOpen ? 'mr-3' : '')
          }
          aria-hidden="true"
        />
      );
    } else {
      return <> </>;
    }
  };

  const toggleDesktopSideBar = () => {
    // Only trigger animation if Sidebar is open

    if (desktopSidebarOpen) {
      requestAnimationFrame(() => {
        // Change width
        if (desktopSidebarLockedOpen) {
          // Sidebar is now locked open so we shrink it
          setDesktopSidebarWidth('4rem');
          setDesktopSidebarOpen(false);
        } else {
          // Sidebase is now close so we expand it
          setDesktopSidebarWidth('16rem');
          setDesktopSidebarOpen(true);
        }

        // Change lock state
        setdesktopSidebarLockedOpen(!desktopSidebarLockedOpen);
      });
    }
  };

  const expandSideBar = () => {
    if (!desktopSidebarLockedOpen) {
      setDesktopSidebarWidth('16rem');
      setDesktopSidebarOpen(true);
    }
  };

  const hideSideBar = () => {
    if (!desktopSidebarLockedOpen) {
      setDesktopSidebarWidth('4rem');
      setDesktopSidebarOpen(false);
    }
  };

  return (
    <>
      <button
        className={
          'absolute h-16 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black-abstract-branding-500 md:hidden ' +
          (sidebarOpen ? 'z-0' : 'z-40')
        }
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="h-8 w-auto"
                  src="/assets/media/logos/abstract-dark-light.svg"
                  alt="Workflow"
                />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {Object.keys(productsCategories).length > 0 ? (
                    <nav className="flex-1 bg-white space-y-1 pt-4">
                      <NavLink
                        key="dashboard"
                        to={'/dashboard'}
                        activeClassName="bg-blue-brand-100 text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        className="text-gray-600 hover:bg-blue-brand-100 hover:text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      >
                        <HeroIcons.HomeIcon
                          className={
                            'text-blue-brand-300 group-hover:text-blue-brand-500 flex-shrink-0 h-5 w-5'
                          }
                          aria-hidden="true"
                        />
                        <span className="ml-3 whitespace-nowrap">
                          Dashboard
                        </span>
                      </NavLink>
                      <div className="text-xs font-medium pt-6 px-2 whitespace-nowrap">
                        API'S TO VERIFY
                      </div>
                      {productsCategories['verify'].map((product) => (
                        <NavLink
                          key={products[product]['path']}
                          to={'/api/' + products[product]['path']}
                          activeClassName="bg-blue-brand-100 text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          className="text-gray-600 hover:bg-blue-brand-100 hover:text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        >
                          {renderIcon(product)}

                          <span className="ml-3 whitespace-nowrap">
                            {products[product]['name']}
                          </span>
                        </NavLink>
                      ))}
                      <div className="text-xs font-medium pt-6 px-2 whitespace-nowrap">
                        API'S TO LOOKUP
                      </div>
                      {productsCategories['lookup'].map((product) => (
                        <NavLink
                          key={products[product]['path']}
                          to={'/api/' + products[product]['path']}
                          activeClassName="bg-blue-brand-100 text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          className="text-gray-600 hover:bg-blue-brand-100 hover:text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        >
                          {renderIcon(product)}

                          <span className="ml-3 whitespace-nowrap">
                            {products[product]['name']}
                          </span>
                        </NavLink>
                      ))}
                      <div className="text-xs font-medium pt-6 px-2 whitespace-nowrap">
                        API'S TO CREATE
                      </div>
                      {productsCategories['create'].map((product) => (
                        <NavLink
                          key={products[product]['path']}
                          to={'/api/' + products[product]['path']}
                          activeClassName="bg-blue-brand-100 text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          className="text-gray-600 hover:bg-blue-brand-100 hover:text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        >
                          {renderIcon(product)}

                          <span className="ml-3 whitespace-nowrap">
                            {products[product]['name']}
                          </span>
                        </NavLink>
                      ))}
                    </nav>
                  ) : (
                    <></>
                  )}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div
          className="flex flex-col transition-width transition-700 ease-in-out"
          style={{ width: desktopSidebarWidth }}
          onMouseEnter={expandSideBar}
          onMouseLeave={hideSideBar}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow pt-0 pb-4 bg-white overflow-y-auto shadow-lg">
            <div
              className={
                'absolute flex items-center justify-between h-16 bg-gray-brand-900 flex-shrink-0 ' +
                (desktopSidebarOpen ? 'pl-6 w-64' : 'w-full m-auto')
              }
            >
              <img
                className="h-4 w-auto"
                src="/assets/media/logos/abstract-dark.svg"
                alt="Abstract API"
                style={{ display: desktopSidebarOpen ? 'block' : 'none' }}
              />
              <button
                className="px-4 text-black-abstract-branding-500 focus:outline-none hover:text-blue-brand-500"
                onClick={() => toggleDesktopSideBar()}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="flex-grow flex flex-col pt-16 p-4 pt-6">
              {Object.keys(productsCategories).length > 0 ? (
                <nav className="flex-1 bg-white space-y-1 pt-4">
                  <NavLink
                    key="dashboard"
                    to={'/dashboard'}
                    activeClassName="bg-blue-brand-100 text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    className="text-gray-600 hover:bg-blue-brand-100 hover:text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                  >
                    <HeroIcons.HomeIcon
                      className={
                        'text-blue-brand-300 group-hover:text-blue-brand-500 flex-shrink-0 h-5 w-5 ' +
                        (desktopSidebarOpen ? 'mr-3' : '')
                      }
                      aria-hidden="true"
                    />
                    {desktopSidebarOpen ? 'Dashboard' : <></>}
                  </NavLink>
                  <div className="text-xs font-medium pt-6 px-2 whitespace-nowrap">
                    {desktopSidebarOpen ? "API'S TO VERIFY" : '...'}
                  </div>
                  {productsCategories['verify'].map((product) => (
                    <NavLink
                      key={products[product]['path']}
                      to={'/api/' + products[product]['path']}
                      activeClassName="bg-blue-brand-100 text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      className="text-gray-600 hover:bg-blue-brand-100 hover:text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    >
                      {renderIcon(product)}

                      <span className="whitespace-nowrap">
                        {desktopSidebarOpen ? products[product]['name'] : <></>}
                      </span>
                    </NavLink>
                  ))}
                  <div className="text-xs font-medium pt-6 px-2 whitespace-nowrap">
                    {desktopSidebarOpen ? "API'S TO LOOKUP" : '...'}
                  </div>
                  {productsCategories['lookup'].map((product) => (
                    <NavLink
                      key={products[product]['path']}
                      to={'/api/' + products[product]['path']}
                      activeClassName="bg-blue-brand-100 text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      className="text-gray-600 hover:bg-blue-brand-100 hover:text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    >
                      {renderIcon(product)}

                      <span className="whitespace-nowrap">
                        {desktopSidebarOpen ? products[product]['name'] : <></>}
                      </span>
                    </NavLink>
                  ))}
                  <div className="text-xs font-medium pt-6 px-2 whitespace-nowrap">
                    {desktopSidebarOpen ? "API'S TO CREATE" : '...'}
                  </div>
                  {productsCategories['create'].map((product) => (
                    <NavLink
                      key={products[product]['path']}
                      to={'/api/' + products[product]['path']}
                      activeClassName="bg-blue-brand-100 text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      className="text-gray-600 hover:bg-blue-brand-100 hover:text-blue-brand-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    >
                      {renderIcon(product)}

                      <span className="whitespace-nowrap">
                        {desktopSidebarOpen ? products[product]['name'] : <></>}
                      </span>
                    </NavLink>
                  ))}
                </nav>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Aside;
