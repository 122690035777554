import React, { useCallback, useRef } from 'react';
import { usePopper } from 'react-popper';

import ArrowIndicator from './ArrowIndicator';
import usePopperOptions from './Popover.options';
import { useOnClickOutside } from '../../Utils/Helpers';

const Popover = ({
  title,
  description,
  open,
  referenceElement,
  onClose,
  children,
  placement = 'right',
  showArrow = true,
  fallbackPlacements,
  closeOnClickOutside = true,
}) => {
  const popperElementRef = useRef(null);
  const arrowElementRef = useRef(null);

  const handleClickOutside = useCallback(() => onClose && onClose(), [onClose]);

  const popperOptions = usePopperOptions({
    arrowElementRef,
    placement,
    showArrow,
    fallbackPlacements,
  });
  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperElementRef.current,
    popperOptions,
  );

  const arrowPlacement =
    attributes.popper && attributes.popper['data-popper-placement'];

  useOnClickOutside(popperElementRef, handleClickOutside, closeOnClickOutside);

  return (
    <div
      ref={popperElementRef}
      style={{ ...styles.popper, zIndex: 999 }}
      {...attributes.popper}
    >
      {open && (
        <div
          className="relative w-73 bg-white py-4 px-4 z-10 box-border rounded-xl border-2 border-gray-brand-500"
          style={styles.offset}
        >
          {showArrow && (
            <ArrowIndicator
              ref={arrowElementRef}
              style={styles.arrow}
              placement={arrowPlacement}
            />
          )}

          <h1 className="leading-6 font-bold">{title}</h1>
          <p className="mb-6 leading-6">{description}</p>
          {children}
        </div>
      )}
    </div>
  );
};

export default Popover;
