import { useEffect, useState, useContext, createContext } from 'react';
import { withRouter } from 'react-router';
import { RadioGroup } from '@headlessui/react';
import ContentLoader from 'react-content-loader';
import { useStripe } from '@stripe/react-stripe-js';
import APIContext from '../../../contexts/APIContext';
import { CheckIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import { useHistory, useLocation } from 'react-router-dom';
import { trackEvent } from '../../Utils/SegmentAnalytics';
import SubscriptionCancellationModal from '../../Modals/SubscriptionCancellationModal';
import SubscriptionPaymentModal from '../../Modals/SubscriptionPaymentModal';
import SubscriptionDowngradeModal from '../../Modals/SubscriptionDowngradeModal';
import partners from '../../../assets/json/partners.json';
import apiInfo from '../../../assets/json/api-details.json';
import { marked } from 'marked';

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

const frequencies = [
  { value: 3, label: 'Monthly', priceSuffix: '/month' },
  { value: 2, label: 'Annually - Get 10% off!', priceSuffix: '/year' },
];

export const ModalContext = createContext();

const SubscriptionOverlay = (props) => {
  let history = useHistory();

  const stripe = useStripe();

  const {
    APIgetProducts,
    APIGetCheckoutSession,
    APIGetChurnSolutionsHash,
    APICancelPlan,
    APICouponRedeem,
  } = useContext(APIContext);

  // Api details
  const { pathname } = useLocation();
  const apiDetails = apiInfo.find((it) => it.slug === pathname.split('/')[2]);

  // Modals
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [isCancellationModalVisible, setIsCancellationModalVisible] =
    useState(false);
  const [isDowngradeModalVisible, setIsDowngradeModalVisible] = useState(false);

  // Plans
  const [plans, setPlans] = useState([]);
  const [allPlans, setAllPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState({});
  const [selectedPlan, setSelectedPlan] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [frequency, setFrequency] = useState(frequencies[1]);
  const isFromOnboarding = window.location.search.includes('from_onboarding');
  const { api } = props.match.params;

  useEffect(() => {
    // Get available plans
    getPlans(props.productId);
    let pricing_cta = '';
    if (window.location.search.match(/pricing_cta=([^&]*)/)) {
      pricing_cta = window.location.search.match(/pricing_cta=([^&]*)/)[1];
    }
    trackEvent('Subscription - plan selection overlay viewed', {
      product_id: props.productId,
      pricing_cta,
    });

    const session_id = window.location.search.match(/session_id=([^&]*)/);
    if (session_id) {
      setIsPaymentModalVisible(true);
    }

    const wasOffered = Boolean(
      window.location.search.match(/was_offered=([^&]*)/),
    );

    if (!session_id && wasOffered) {
      history.push('/');
    }
  }, [props.productId]);

  useEffect(() => {
    if (currentPlan && currentPlan.billing_period === 3) {
      setFrequency(frequencies[0]); // show monthly plans
    }
  }, [currentPlan]);

  useEffect(() => {
    filterPlans(frequency);
  }, [allPlans, frequency]);

  const filterPlans = (frequency) => {
    // Find plans based on frecuency
    let frecuencyPlans = allPlans.filter(
      (plan) => plan.billing_period === frequency.value,
    );

    // Add pricing items
    for (let i = 0; i < frecuencyPlans.length; i++) {
      frecuencyPlans[i]['features'] = ['', 'Domains whitelisting'];
      if (frecuencyPlans[i].base_price > 45) {
        frecuencyPlans[i]['features'].push('Multiple API keys');
      }
      frecuencyPlans[i]['features'][0] =
        frecuencyPlans[i].rate_limit + ' requests per second';
    }

    // Set frecuency plans to finally display
    setPlans(frecuencyPlans);
  };

  const getPlans = async (productId) => {
    setIsLoading(true);
    // Retrieve all products and associated plans
    await APIgetProducts().then((response) => {
      // All products
      let products = response.results;

      // Find actual product displayed within products list
      let product = products.filter((product) => product.id === productId)[0];

      let minPlanPrice = 0;
      let productPlans = product.plans.filter(
        (plan) =>
          plan.base_price > minPlanPrice ||
          (plan.base_price !== 0 && plan.id === props.currentPlanId),
      );

      // Store all plans
      setAllPlans(productPlans);

      // Find & set current plan
      setCurrentPlan(
        productPlans.filter((plan) => plan.id === props.currentPlanId)[0],
      );
    });
    setIsLoading(false);
  };

  const getLabelAction = (currentPlan, plan) => {
    if (currentPlan?.billing_period === 3 && plan.billing_period === 2) {
      return 'Upgrade';
    }
    if (currentPlan?.billing_period === 2 && plan.billing_period === 3) {
      return 'Switch to monthly';
    }
    if (currentPlan?.base_price > plan.base_price) {
      return 'Downgrade';
    }
    return 'Upgrade';
  };

  const getPeriodDisplay = (plan) => {
    if (plan.billing_period === 2) {
      return 'year';
    }
    return 'month';
  };

  const getPriceDisplay = (plan) => {
    if (plan.billing_period === 2) {
      return Math.round(plan.base_price / 12);
    }
    return plan.base_price;
  };

  const trackContactUs = () => {
    trackEvent('Custom plan request', {
      product_name: props.productName,
    });
  };

  const displayModal = (selectedPlan) => {
    let plan = plans.filter((plan) => plan.id === selectedPlan.id)[0];
    setSelectedPlan(plan);

    if (
      selectedPlan.billing_period === 3 &&
      currentPlan?.billing_period === 2
    ) {
      setIsDowngradeModalVisible(true);
    } else {
      trackEvent('Subscription - plan change attempted', {
        product_id: props.productId,
        plan_id: plan.id,
        plan_name: plan.name,
        plan_price: plan.base_price,
        plan_included_api_calls: plan.included_api_calls,
      });

      APIGetCheckoutSession(selectedPlan.id).then((response) => {
        return stripe.redirectToCheckout({
          sessionId: response.data['session_id'],
        });
      });
    }
  };

  const convertToMB = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  const cancelPlan = async () => {
    let trackEventData = {
      product_id: props.productId,
      product_name: props.usersProductData.api_product_name,
      plan_name: currentPlan.name,
      plan_price: currentPlan.base_price,
      plan_cadence: currentPlan.billing_period_display,
    };

    trackEvent('Modal viewed - cancel subscription', trackEventData);

    APIGetChurnSolutionsHash(props.productId)
      .then((response) => {
        let hash = response.data.hash;
        let subscriptionId = response.data.stripe_subscription_id;
        window.churnSolution?.run(
          hash,
          subscriptionId,
          process.env.REACT_APP_CHURNSOLUTION_ID,
          {
            handleCancel: (customer, flowInfo) => {
              APICancelPlan(props.productId, trackEventData)
                .then((response) => {
                  trackEvent('Subscription - cancellation succeeded', {
                    ...trackEventData,
                    cancellation_reason: 'No longer needed',
                  });

                  trackEvent('Churn Flow completed', {
                    ...trackEventData,
                    feedback: flowInfo.form_answer,
                    flow_id: flowInfo.cancellation_flow.id,
                    result: 'declined_offer',
                    offer_type: 'coupon',
                    action_type: null,
                    reason: flowInfo.selected_survey_option.text,
                  });
                  history.push(`/api/${props.match.params.api}/pricing`);
                })
                .catch((error) => {
                  throw new Error('Cancellation failed: ' + error.message);
                });
            },
            handleAbandon: (customer, flowInfo) => {
              trackEvent('Churn Flow completed', {
                ...trackEventData,
                flow_id: flowInfo.cancellation_flow.id,
                result: 'closed_offer',
                action_type: null,
                offer_type: 'coupon',
                reason: null,
                feedback: null,
              });
            },
            handleCoupon: ({ code }, customer, flowInfo) => {
              APICouponRedeem(subscriptionId, trackEventData).then(() => {
                trackEvent('Churn Flow completed', {
                  ...trackEventData,
                  product_id: props.productId,
                  coupon_code: code,
                  result: 'accepted_offer',
                  flow_id: flowInfo.cancellation_flow.id,
                  offer_type: 'coupon',
                  action_type: null,
                  reason: null,
                  feedback: null,
                });
                history.push(`/api/${props.match.params.api}/tester`);
              });
            },
            handlePause: ({ pausePeriod }, customer) => {},
            handleTrialExtension: ({ trialExtensionDays }, customer) => {
              // trial extension callback
            },
          },
        );
        setIsLoading(false);
      })
      .catch(() => {
        setIsCancellationModalVisible(true);
        const message =
          "Your cancellation couldn't be processed. Please try again or contact support.";
        trackEvent('Subscription - plan change failed', {
          ...trackEventData,
          error_message: message,
        });
      });
  };

  return (
    <>
      <SubscriptionPaymentModal
        isVisible={isPaymentModalVisible}
        closeModal={() => setIsPaymentModalVisible(false)}
        currentPlan={currentPlan}
        selectedPlan={selectedPlan}
        productId={props.productId}
        productName={props.productName}
      />
      <SubscriptionCancellationModal
        isVisible={isCancellationModalVisible}
        closeModal={() => setIsCancellationModalVisible(false)}
        currentPlan={currentPlan}
        selectedPlan={selectedPlan}
        productId={props.productId}
        productName={props.productName}
      />
      <SubscriptionDowngradeModal
        isVisible={isDowngradeModalVisible}
        closeModal={() => setIsDowngradeModalVisible(false)}
        currentPlan={currentPlan}
        selectedPlan={selectedPlan}
        productId={props.productId}
        usersProductData={props.usersProductData}
      />

      <div
        className={
          'px-8 xl:px-0 top-0 left-0 right-0 bottom-0 w-full z-50 h-screen bg-gray-100 overflow-y-scroll ' +
          (isCancellationModalVisible ||
          isPaymentModalVisible ||
          isDowngradeModalVisible
            ? 'hidden'
            : 'fixed')
        }
      >
        <div className="pt-2 mx-auto">
          <div className="mx-auto">
            <div className="py-4">
              <button
                className={
                  'bg-gray-100 inline-flex items-center px-3 py-2 border border-gray-200 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 ' +
                  (isPaymentModalVisible ? 'opacity-0' : '')
                }
                onClick={() => {
                  if (isFromOnboarding) {
                    history.push(`/quickstart?${api}`);
                  } else {
                    history.push(`/api/${api}`);
                  }
                }}
                style={{ marginLeft: '10px' }}
              >
                <ChevronLeftIcon className="w-4 mr-2" />
                <span>Back</span>
              </button>

              <h3 className="mt-4 mb-6 auto space-y-3 text-2xl sm:text-3xl font-extrabold text-gray-900 text-center pt-6">
                {props.title}
                <br />
                <span className="space-y-1 text-base text-gray-500 font-semibold">
                  {`Plans for the ${props.productName}  API`}
                </span>
              </h3>
              <div className="mt-10 flex justify-center">
                {api !== 'holidays' ? (
                  <RadioGroup
                    value={frequency}
                    onChange={setFrequency}
                    className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
                  >
                    <RadioGroup.Label className="sr-only">
                      Payment frequency
                    </RadioGroup.Label>
                    {frequencies.map((option) => (
                      <RadioGroup.Option
                        key={option.value}
                        value={option}
                        className={({ checked }) =>
                          classNames(
                            checked
                              ? 'bg-blue-brand-500 text-white'
                              : 'text-gray-500',
                            'cursor-pointer rounded-full px-2.5 py-1',
                          )
                        }
                      >
                        <span>{option.label}</span>
                      </RadioGroup.Option>
                    ))}
                  </RadioGroup>
                ) : (
                  <> </>
                )}
              </div>
            </div>
            {isLoading ? (
              <div className="mt-2 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:mx-auto xl:mx-0 xl:grid-cols-3">
                {[1, 2, 3].map((x, i) => {
                  return (
                    <div className="border border-gray-200 bg-white rounded-lg shadow-sm divide-y divide-gray-200">
                      <div className="p-6">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                          <ContentLoader
                            speed={1.5}
                            width={'30%'}
                            height={'20px'}
                            viewBox="0 0 100% 100%"
                            backgroundColor="#646c9a"
                            backgroundOpacity={0.05}
                            foregroundColor="#646c9a"
                            foregroundOpacity={0.1}
                            {...props}
                          >
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="100%"
                              height="100%"
                            />
                          </ContentLoader>
                        </h2>
                        <div className="mt-4 text-sm text-gray-500">
                          <ContentLoader
                            speed={1.5}
                            width={'40%'}
                            height={'20px'}
                            viewBox="0 0 100% 100%"
                            backgroundColor="#646c9a"
                            backgroundOpacity={0.05}
                            foregroundColor="#646c9a"
                            foregroundOpacity={0.1}
                            {...props}
                          >
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="100%"
                              height="100%"
                            />
                          </ContentLoader>
                        </div>
                        <div className="mt-8 mb-6">
                          <span className="text-4xl font-extrabold text-gray-900">
                            <ContentLoader
                              speed={1.5}
                              width={'45%'}
                              height={'30px'}
                              viewBox="0 0 100% 100%"
                              backgroundColor="#646c9a"
                              backgroundOpacity={0.05}
                              foregroundColor="#646c9a"
                              foregroundOpacity={0.1}
                              {...props}
                            >
                              <rect
                                x="0"
                                y="0"
                                rx="3"
                                ry="3"
                                width="100%"
                                height="100%"
                              />
                            </ContentLoader>
                          </span>
                        </div>
                        <ContentLoader
                          speed={1.5}
                          width={'100%'}
                          height={'40px'}
                          viewBox="0 0 100% 100%"
                          backgroundColor="#646c9a"
                          backgroundOpacity={0.05}
                          foregroundColor="#646c9a"
                          foregroundOpacity={0.1}
                          {...props}
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="100%"
                            height="100%"
                          />
                        </ContentLoader>
                      </div>
                      <div className="pt-6 pb-8 px-6">
                        <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                          <ContentLoader
                            speed={1.5}
                            width={'40%'}
                            height={'20px'}
                            viewBox="0 0 100% 100%"
                            backgroundColor="#646c9a"
                            backgroundOpacity={0.05}
                            foregroundColor="#646c9a"
                            foregroundOpacity={0.1}
                            {...props}
                          >
                            <rect
                              x="0"
                              y="0"
                              rx="3"
                              ry="3"
                              width="100%"
                              height="100%"
                            />
                          </ContentLoader>
                        </h3>
                        <ul className="mt-6 space-y-4">
                          {[1, 2, 3, 4, 5].map((x, i) => {
                            return (
                              <li key={i} className="flex space-x-3">
                                <span className="text-sm text-gray-500">
                                  <ContentLoader
                                    speed={1.5}
                                    width={'100%'}
                                    height={'16px'}
                                    viewBox="0 0 100% 100%"
                                    backgroundColor="#646c9a"
                                    backgroundOpacity={0.05}
                                    foregroundColor="#646c9a"
                                    foregroundOpacity={0.1}
                                    {...props}
                                  >
                                    <rect
                                      x="0"
                                      y="0"
                                      rx="3"
                                      ry="3"
                                      width="100%"
                                      height="100%"
                                    />
                                  </ContentLoader>
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                className={`mt-2 mb-8 lg:mb-12 space-y-4 sm:space-y-0 flex place-content-center sm:gap-6 sm:mx-auto`}
                style={{ padding: '20px' }}
              >
                {plans.map((plan) => (
                  <div
                    key={plan.name}
                    className="relative border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 flex-1"
                  >
                    {plan.most_popular ? (
                      <p className="absolute top-0 left-6 z-20 -translate-y-1/2 transform rounded-full bg-black-abstract-branding-500 py-1.5 px-4 text-sm font-semibold text-white">
                        Most popular
                      </p>
                    ) : null}
                    <div className="p-6 relative">
                      <h2 className="text-lg leading-6 font-medium text-gray-900">
                        {plan.name}
                      </h2>
                      <div className="mt-4 text-sm text-gray-500 absolute">
                        <b>
                          {props.productId === 10
                            ? convertToMB(plan.included_api_calls)
                            : plan.included_api_calls === 999999999999
                            ? '1 billion'
                            : plan.included_api_calls.toLocaleString() +
                              ' API calls '}
                        </b>{' '}
                        / {getPeriodDisplay(plan)}
                      </div>
                      <div className="mt-16">
                        <span className="text-4xl font-extrabold text-gray-900">
                          ${getPriceDisplay(plan)}
                        </span>{' '}
                        <span className="text-base font-medium text-gray-500">
                          / month
                        </span>
                      </div>
                      {plan.billing_period === 2 ? (
                        <div className="mt-2 text-sm text-gray-500 absolute">
                          <b>
                            Billed $
                            {plan.base_price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                            yearly
                          </b>
                        </div>
                      ) : undefined}
                      {plan.id === currentPlan?.id ? (
                        <div>
                          <button
                            disabled={true}
                            className="mt-10 block w-full bg-black-abstract-branding-500 border rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-black-abstract-branding-700 disabled:opacity-50"
                          >
                            Current plan
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={() => displayModal(plan)}
                            className="mt-10 block w-full bg-black-abstract-branding-500 border rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-black-abstract-branding-700"
                          >
                            {getLabelAction(currentPlan, plan)}
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="pt-6 pb-8 px-6">
                      <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                        What's included
                      </h3>
                      <ul className="mt-6 space-y-4">
                        <li className="flex space-x-3">
                          <CheckIcon
                            className="flex-shrink-0 h-5 w-5 text-brand-blue-500"
                            aria-hidden="true"
                          />
                          <span className="text-xs text-gray-500">
                            {props.productId === 10
                              ? convertToMB(plan.included_api_calls)
                              : plan.included_api_calls === 999999999999
                              ? '1 billion'
                              : plan.included_api_calls.toLocaleString() +
                                ' calls '}{' '}
                            / {getPeriodDisplay(plan)}
                          </span>
                        </li>
                        {plan['features'].map((x, i) => {
                          return (
                            <li key={i} className="flex space-x-3">
                              <CheckIcon
                                className="flex-shrink-0 h-5 w-5 text-brand-blue-500"
                                aria-hidden="true"
                              />
                              <span className="text-xs text-gray-500">
                                {plan['features'] ? plan['features'][i] : ''}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                      {plan.id === currentPlan?.id ? (
                        <button
                          onClick={() => cancelPlan()}
                          className="mt-8 block w-full border rounded-md py-2 text-sm font-semibold text-gray-700 text-center hover:bg-gray-50 border-gray-300"
                        >
                          Cancel plan
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="mb-16 flex justify-center">
              <p className="relative rounded-full px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/10 hover:ring-gray-900/20">
                <span className="hidden md:inline">
                  Need a custom solution?
                </span>
                <a
                  href="mailto:support@abstractapi.com"
                  target="_blank"
                  className="font-semibold text-blue-brand-500"
                  onClick={trackContactUs}
                >
                  <span className="absolute inset-0" aria-hidden="true" />{' '}
                  Contact us <span aria-hidden="true">&rarr;</span>
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className={'bg-white py-16 -mx-8 xl:mx-0 lg:pt-20'}>
          <div className={'text-lg text-center mb-8'}>
            The world's top engineers and organizations run on Abstract
          </div>
          <div
            className="max-w-desktop mx-auto px-8 xl:px-0 grid grid-cols-2 gap-0.5 lg:grid-cols-8"
            style={{ paddingLeft: '60px', paddingRight: '60px' }}
          >
            {partners.map((it) => (
              <div
                key={it.title}
                className="flex justify-center py-4 pr-16 bg-white"
              >
                <img className="max-h-12" src={it.icon} alt={it.title} />
              </div>
            ))}
          </div>
        </div>
        <div className="mx-auto" style={{ padding: '60px' }}>
          <div className={'mx-auto max-w-title'}>
            <h3 className="mt-10 lg:mt-28 mb-8 lg:mb-16 auto text-2xl sm:text-3xl font-extrabold text-gray-900 text-center">
              {apiDetails.title}
            </h3>
          </div>
          <div className="mx-auto mb-28 grid grid-cols-1 gap-x-12 gap-y-7 lg:gap-y-10 lg:grid-cols-3">
            {apiDetails.features.map((it) => (
              <div key={it.title} className={'flex'}>
                <img
                  className={'w-8 h-8 mr-2 lg:mr-4'}
                  src={'/assets/media/icons/checkMark-no-bg.svg'}
                />
                <div className={'relative'}>
                  <h4
                    className={
                      'absolute top-none text-lg font-bold text-gray-900'
                    }
                  >
                    {it.title}
                  </h4>
                  <div
                    className={'text-base mt-16'}
                    dangerouslySetInnerHTML={{
                      __html: marked(it.description, { sanitize: false }),
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(SubscriptionOverlay);
