import React, { useCallback } from 'react';

const Checkbox = ({ id, label, description, checked, onChange }) => {
  const _onChange = useCallback(
    (e) => {
      if (onChange) {
        onChange(e.target.checked, e);
      }
    },
    [onChange],
  );

  return (
    <div className="relative flex items-center">
      <div className="flex h-5 items-center">
        <input
          id={id}
          type="checkbox"
          checked={checked}
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          onChange={_onChange}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className="text-base font-medium text-gray-700">
          {label}
        </label>
        {description && <span className="text-gray-500">{description}</span>}
      </div>
    </div>
  );
};

export default Checkbox;
