import React, { Fragment, useRef, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';

const AllCreditsUsedModal = ({ isVisible, setIsVisible, api }) => {
  let history = useHistory();
  const updatePaymentButtonRef = useRef(null);

  const goToPricing = () => {
    history.push(`/api/${api}/pricing/select?pricing_cta=free_credits_over`);
  };

  return (
    <>
      <Transition.Root show={isVisible} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-50 inset-0 overflow-y-auto"
          initialFocus={updatePaymentButtonRef}
          open={isVisible}
          onClose={() => {
            setIsVisible(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium"
                    >
                      {' '}
                      You’ve used all your free credits
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Please select a paid plan to continue using the API.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => goToPricing()}
                    ref={updatePaymentButtonRef}
                  >
                    Go to pricing page
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() =>
                      window.open(
                        'mailto:support@abstractapi.com?subject=Support%20request%20-%20My%20API%20keys%20have%20been%20disabled',
                        '_blank',
                      )
                    }
                  >
                    Contact Support
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default withRouter(AllCreditsUsedModal);
