import React, {
  Fragment,
  useRef,
  useContext,
  useState,
  useEffect,
} from 'react';

import { Dialog, Transition } from '@headlessui/react';
import APIContext from '../../contexts/APIContext';
import CacheContext from '../../contexts/CacheContext';

import { trackEvent } from '../Utils/SegmentAnalytics';

const PaymentFailedModal = ({ isVisible }) => {
  const { userId, organizationSuspended } = useContext(CacheContext);
  const { APImanageBilling, APItriggerInvoicePayment } = useContext(APIContext);
  const updatePaymentButtonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (organizationSuspended) {
      tryInvoicePayment();
    }
  }, [organizationSuspended]);

  async function goToBilling() {
    let url = await APImanageBilling();
    trackEvent('Button clicked - Update payment method', {
      id: userId,
    });
    window.open(url, '_self');
  }

  const tryInvoicePayment = async () => {
    setIsLoading(true);
    const organization = await APItriggerInvoicePayment();
    if (!organization.suspended) {
      window.location.reload();
    }
    setIsLoading(false);
  };

  return (
    <>
      <Transition.Root show={isVisible} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-50 inset-0 overflow-y-auto"
          initialFocus={updatePaymentButtonRef}
          open={true}
          onClose={() => {}}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium"
                    >
                      {' '}
                      Your payment has failed
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Your API keys have been disabled due to failed payments.
                        Please update your payment method to re-enable them.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    disabled={isLoading}
                    onClick={() => goToBilling()}
                    ref={updatePaymentButtonRef}
                  >
                    {isLoading ? (
                      <>
                        <svg
                          className="animate-spin h-5 w-5 mr-3"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            fill="transparent"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Checking unpaid invoices
                      </>
                    ) : (
                      'Update payment method'
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-brand-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() =>
                      window.open(
                        'mailto:support@abstractapi.com?subject=Support%20request%20-%20My%20API%20keys%20have%20been%20disabled',
                        '_blank',
                      )
                    }
                  >
                    Contact Support
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default PaymentFailedModal;
