import React, { useContext, useState, useEffect } from 'react';
import CacheContext from '../../../contexts/CacheContext';
import { trackEventPageLoad } from '../../Utils/SegmentAnalytics';
import AccountPasswordModal from './AccountPasswordModal';
import AccountEmailModal from './AccountEmailModal';

function Account() {
  const { getUserInformation } = useContext(CacheContext);

  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const has_usable_password = localStorage.hasOwnProperty('has_usable_password')
    ? localStorage.getItem('has_usable_password') == 'true'
    : true;

  useEffect(() => {
    trackEventPageLoad('settings account');
    if (window.location.search.match(/open-modal-email=([^&]*)/)) {
      setIsEmailModalVisible(true);
    }
  }, [getUserInformation().email]);

  return (
    <>
      <AccountPasswordModal
        isVisible={isPasswordModalVisible}
        closeModal={() => setIsPasswordModalVisible(false)}
      />

      <AccountEmailModal
        isVisible={isEmailModalVisible}
        closeModal={() => setIsEmailModalVisible(false)}
      />

      <h2 className="text-xl font-medium">Account</h2>

      {!has_usable_password && (
        <div className="mt-4 text-sm">
          You have signed up using Google. If you want to adjust your email,{' '}
          <a href="mailto:support@abstractapi.com?subject=Change email">
            contact us.
          </a>
          <h2 className="text-xl font-medium mt-7 mb-2">Password </h2>
          You can set up a password by clicking on the button below.
          <br />
          <button
            onClick={() => setIsPasswordModalVisible(true)}
            className="inline-flex items-center mt-4 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-black-abstract-branding-500 hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500"
          >
            Set password
          </button>
        </div>
      )}

      {has_usable_password && (
        <>
          <div className="mt-4 text-sm">
            Edit your account's information below
          </div>
          <div className="mt-8 text-sm">
            <label htmlFor="email" className="block text-sm font-medium">
              Email
            </label>
            <div className="mt-1 flex flex-row">
              <input
                type="text"
                name="email"
                id="email"
                disabled={true}
                className="shadow-sm focus:ring-indigo-500 bg-gray-50 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mr-3"
                placeholder={getUserInformation().email}
              />
              <button
                onClick={() => setIsEmailModalVisible(true)}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-black-abstract-branding-500 hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500"
              >
                Change
              </button>
            </div>
          </div>
          <div className="mt-8 text-sm">
            <label htmlFor="email" className="block text-sm font-medium">
              Password
            </label>
            <div className="mt-1 flex flex-row">
              <input
                type="password"
                name="password"
                id="password"
                disabled={true}
                className="shadow-sm focus:ring-indigo-500 bg-gray-50 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mr-3"
                placeholder="*****************"
              />
              <button
                onClick={() => setIsPasswordModalVisible(true)}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-black-abstract-branding-500 hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500"
              >
                Change
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Account;
