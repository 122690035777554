import React, { createContext, useState } from 'react';

// Third party libraries
import * as Sentry from '@sentry/react';

const CacheContext = createContext();

const CacheProvider = (props) => {
  const [userRoles, setUserRoles] = useState([]);
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [organizationSuspended, setOrganizationSuspended] = useState(null);

  // User info
  const storeUserInformation = (data) => {
    // Put info in local storage
    localStorage.setItem('user_info', JSON.stringify(data));
    localStorage.setItem('user_id', data.id);
    // Change states
    // id
    if (data && data.id) {
      setUserId(data.id);
    }

    // email
    if (data && data.email) {
      setUserEmail(data.email);
    }

    // organization_suspended
    if (data && data.organization_suspended) {
      setOrganizationSuspended(data.organization_suspended);
    }

    if (data && data.id && data.email) {
      Sentry.setUser({ email: data.email, id: data.id });
    }

    // -- Roles
    let roles = [];
    if (data && data.is_superuser) {
      roles.push('super_user');
    }
    if (data && data.is_staff) {
      roles.push('is_staff');
    }
    setUserRoles(roles);
  };

  const getUserInformation = () => {
    let data = localStorage.getItem('user_info');
    return JSON.parse(data);
  };

  // APIs info
  const storeUserApisData = (data) => {
    localStorage.setItem('user_apis', JSON.stringify(data));
  };

  const getUserApisData = () => {
    let data = localStorage.getItem('user_apis');
    return JSON.parse(data);
  };

  const setUserInterest = (data) => {
    localStorage.setItem('user_interest', JSON.stringify(data));
  };

  const getUserInterest = () => {
    let data = localStorage.getItem('user_interest');
    try {
      return JSON.parse(data);
    } catch (ex) {
      return data;
    }
  };

  const setCalendlyRedirectStatus = () => {
    localStorage.setItem('calendly_redirected', true);
  };

  const getCalendlyRedirectStatus = () => {
    let data = localStorage.getItem('calendly_redirected');
    return JSON.parse(data);
  };

  const deleteCalendlyRedirectStatus = () => {
    localStorage.removeItem('calendly_redirected');
  };

  // Resetting data
  const resetUserData = () => {
    localStorage.removeItem('user_info');
    localStorage.removeItem('user_apis');
    Sentry.setUser(null);
    // Sentry.configureScope((scope) => scope.setUser(null));
  };

  return (
    <CacheContext.Provider
      value={{
        storeUserInformation: storeUserInformation,
        storeUserApisData: storeUserApisData,
        getUserInformation: getUserInformation,
        getUserApisData: getUserApisData,
        resetUserData: resetUserData,
        setUserInterest: setUserInterest,
        getUserInterest: getUserInterest,
        setCalendlyRedirectStatus: setCalendlyRedirectStatus,
        getCalendlyRedirectStatus: getCalendlyRedirectStatus,
        deleteCalendlyRedirectStatus: deleteCalendlyRedirectStatus,
        userRoles: userRoles,
        userId: userId,
        userEmail: userEmail,
        organizationSuspended: organizationSuspended,
      }}
    >
      {props.children}
    </CacheContext.Provider>
  );
};

export { CacheContext as default, CacheProvider };
