import { withRouter, NavLink, Route, Switch, Redirect } from 'react-router-dom';
import SubHeader from '../Layout/SubHeader/SubHeader';
import Account from './Modules/Account';
import Organization from './Modules/Organization';

const Settings = () => {
  return (
    <>
      <SubHeader title={'Settings'} />

      <div className="flex flex-wrap overflow-hidden xl:-mx-3 lg:-mx-3">
        <div className="w-full overflow-hidden lg:my-3 lg:px-3 xl:my-3 xl:px-3 xl:w-1/4 my-3">
          <div className="bg-white overflow-hidden shadow-small rounded-md">
            <div className="px-4 py-5 sm:p-6">
              <div role="tablist" className="flex flex-col">
                <NavLink
                  to={`/settings/account`}
                  className="px-6 py-3 w-full hover:bg-gray-brand-100 hover:text-gray-brand-500 text-sm font-semibold"
                  activeClassName="bg-gray-brand-100 text-gray-brand-500"
                >
                  Account
                </NavLink>
                <NavLink
                  to={`/settings/organization`}
                  className="px-6 py-3 w-full hover:bg-gray-brand-100 hover:text-gray-brand-500 text-sm font-semibold"
                  activeClassName="bg-gray-brand-100 text-gray-brand-500"
                >
                  Organization
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full overflow-hidden lg:my-3 lg:px-3 xl:my-3 xl:px-3 xl:w-3/4 my-3">
          <div className="bg-white overflow-hidden shadow-small rounded-md">
            <div className="p-12">
              <Switch>
                <Route
                  exact
                  path={`/settings/account`}
                  render={(props) => <Account />}
                />
                <Route
                  exact
                  path={`/settings/organization`}
                  render={(props) => <Organization />}
                />
                <Redirect to="/settings/account" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Settings);
