import React from 'react';

const Header = () => {
  return (
    <div className="z-10 fixed inset-x-0 top-0 w-screen flex flex-col md:flex-row md:h-20 h-32 bg-white px-8 py-6">
      <img
        className="w-32 h-7"
        alt="Abstract logo"
        src="/assets/media/logos/abstract-dark-light.svg"
      />
    </div>
  );
};

export { Header };
