import { createContext, useState } from 'react';

const HeaderContext = createContext();

const HeaderProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [eventProperties, setEventProperties] = useState({});

  const contextValue = {
    show,
    setShow,
    eventProperties,
    setEventProperties,
  };

  return (
    <HeaderContext.Provider value={contextValue}>
      {children}
    </HeaderContext.Provider>
  );
};

export { HeaderContext as default, HeaderProvider };
