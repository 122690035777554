import React, { Component } from 'react';

import InviteUser from './topbar/InviteUser';
import Changelog from './topbar/Changelog';
import Settings from './topbar/Settings';

class HeaderBase extends Component {
  render() {
    return (
      <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow bg-gray-brand-900">
        <div className="flex-1 px-4 flex justify-between">
          <div className="flex-1 flex">{/* Search space */}</div>
          <div className="ml-4 flex items-center md:ml-6">
            <InviteUser />
            <Changelog />
            <Settings />
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderBase;
