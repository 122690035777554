import React from 'react';
import { withRouter } from 'react-router-dom';
import SubHeader from '../Layout/SubHeader/SubHeader';
import Menu from './Modules/Menu';

function Layout(props) {
  return (
    <>
      <SubHeader title={props.title} />

      <div className="flex flex-wrap overflow-hidden xl:-mx-3 lg:-mx-3">
        <div className="w-full overflow-hidden lg:my-3 lg:px-3 xl:my-3 xl:px-3 xl:w-1/4 my-3">
          <div className="bg-white overflow-hidden shadow-small rounded-md">
            <div className="px-4 py-5 sm:p-6">
              <Menu />
            </div>
          </div>
        </div>

        <div className="w-full overflow-hidden lg:my-3 lg:px-3 xl:my-3 xl:px-3 xl:w-3/4 my-3">
          {props.children}
        </div>
      </div>
    </>
  );
}

export default withRouter(Layout);
