import {
  LocationMarkerIcon,
  MailIcon,
  ReceiptTaxIcon,
  PhoneIcon,
  UserCircleIcon,
  PhotographIcon,
  CameraIcon,
  LibraryIcon,
  CalendarIcon,
  CashIcon,
  OfficeBuildingIcon,
  ClockIcon,
  GlobeAltIcon,
} from '@heroicons/react/outline';

export const productOptions = {
  text: 'What product are you most interested in?',
  question: 'What product are you most interested in?',
  trait: 'main_interest',
  items: [
    {
      title: 'IP Geolocation',
      icon: <LocationMarkerIcon className="w-4 h-4 md:w-5 md:h-5" />,
      tag: 'Popular',
      tooltip: 'Geolocate and get details on any IP worldwide',
      answer: 'ip-geolocation',
    },
    {
      title: 'Email Validation',
      icon: <MailIcon className="w-4 h-4 md:w-5 md:h-5" />,
      tag: 'Popular',
      tooltip: 'Validate any email quickly and reliably',
      answer: 'email-validation',
    },
    {
      title: 'Phone Validation',
      icon: <PhoneIcon className="w-4 h-4 md:w-5 md:h-5" />,
      tag: 'Popular',
      tooltip: 'Validate any phone number worldwide',
      answer: 'phone-validation',
    },
    {
      title: 'Holidays Data',
      icon: <CalendarIcon className="w-4 h-4 md:w-5 md:h-5" />,
      tooltip: 'Get holidays for any country at any time',
      answer: 'holidays',
    },
    {
      title: 'Company Enrichment',
      icon: <OfficeBuildingIcon className="w-4 h-4 md:w-5 md:h-5" />,
      tooltip: 'Get data on any domain or company',
      answer: 'company-enrichment',
    },
    {
      title: 'VAT Validation & Rates',
      icon: <ReceiptTaxIcon className="w-4 h-4 md:w-5 md:h-5" />,
      tooltip: 'Comply with VAT laws and get the latest verified rates',
      answer: 'vat',
    },
    {
      title: 'User Avatar Creation',
      icon: <UserCircleIcon className="w-4 h-4 md:w-5 md:h-5" />,
      tooltip: 'Create flexible user avatars',
      answer: 'avatars',
    },
    {
      title: 'Website Screenshot',
      icon: <CameraIcon className="w-4 h-4 md:w-5 md:h-5" />,
      tooltip: 'Get a screenshot of any HTML file or URL',
      answer: 'screenshot',
    },
    {
      title: 'IBAN Validation',
      icon: <LibraryIcon className="w-4 h-4 md:w-5 md:h-5" />,
      tooltip: 'Validate IBAN codes',
      answer: 'iban-validation',
    },
    {
      title: 'Exchange Rates Data',
      icon: <CashIcon className="w-4 h-4 md:w-5 md:h-5" />,
      tooltip: 'Get and convert exchange rates',
      answer: 'exchange-rates',
    },
    {
      title: 'Time Zone Data',
      icon: <ClockIcon className="w-4 h-4 md:w-5 md:h-5" />,
      tooltip: 'Get and convert time globally',
      answer: 'timezone',
    },
    {
      title: 'Website Scraping',
      icon: <GlobeAltIcon className="w-4 h-4 md:w-5 md:h-5" />,
      tooltip: 'Extract data from any website',
      answer: 'scrape',
    },
    {
      title: 'Image Processing',
      icon: <PhotographIcon className="w-4 h-4 md:w-5 md:h-5" />,
      tooltip: 'Compress and optimize any image',
      answer: 'images',
    },
  ],
};
