import { useState, useContext, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import APIContext from '../../../contexts/APIContext';
import { trackEventPageLoad } from '../../Utils/SegmentAnalytics';

const Pricing = (props) => {
  const { APIgetProducts } = useContext(APIContext);

  const [currentPlan, setCurrentPlan] = useState({});
  const [usersProductData, setUsersProductData] = useState({});
  const [productId, setProductId] = useState(props.productId);
  const [isLoading, setIsLoading] = useState(true);
  const [sourcePricingCTA, setSourcePricingCTA] = useState('pricing_section');

  useEffect(() => {
    const isFromOnboarding = props.location.pathname.includes('/onboarding');
    let productIdFromUrl = null;

    if (isFromOnboarding) {
      const urlParams = new URLSearchParams(window.location.search);
      productIdFromUrl = urlParams.get('product_id');
      setSourcePricingCTA('onboarding');
      if (productIdFromUrl) {
        setProductId(productIdFromUrl);
      }
    } else {
      setSourcePricingCTA('pricing_section');
    }
    setUsersProductData(props.usersProductData);
    getCurrentPlan(props.usersProductData.api_id);
    trackEventPageLoad('product pricing');
  }, [
    props.usersProductData.api_id,
    props.usersProductData.api_key,
    props.usersProductData.api_plan_id,
    props.usersProductData.current_usage,
  ]);

  const getCurrentPlan = async (productId) => {
    setIsLoading(true);

    // Retrieve all products and associated plans
    await APIgetProducts().then((response) => {
      let products = response.results;

      // Find actual product displayed within products list
      let product = products.filter((product) => product.id === productId)[0];

      // Find current plan
      setCurrentPlan(
        product.plans.filter(
          (plan) => plan.id === props.usersProductData.api_plan_id,
        )[0],
      );
    });

    setIsLoading(false);
  };

  // Only to be used for product id = 10
  const convertToMB = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  const getTitle = (currentPlan) => {
    if (currentPlan?.base_price === 0) {
      return `${currentPlan?.name} plan`;
    }
    return `${currentPlan?.name} plan - ${currentPlan?.billing_period_display}`;
  };

  return (
    <>
      <h2 className="text-xl font-medium">Pricing</h2>
      <div className="mt-4 text-sm">
        Upgrade your plan and manage your subscription below.
      </div>

      <div className="mt-6">
        <div
          className="transition ease p-12 rounded-md border border-gray-200 hover:border-blue-brand-500 cursor-pointer"
          onClick={() =>
            props.showSubscriptionOverlay('Upgrade your plan', sourcePricingCTA)
          }
        >
          {isLoading ? (
            <>
              <div className="mb-2">
                <ContentLoader
                  speed={1.5}
                  width={'140px'}
                  height={'35px'}
                  viewBox="0 0 100% 100%"
                  backgroundColor="#646c9a"
                  backgroundOpacity={0.05}
                  foregroundColor="#646c9a"
                  foregroundOpacity={0.1}
                  {...props}
                >
                  <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
                </ContentLoader>
              </div>
              <div className="mt-4">
                <ContentLoader
                  speed={1.5}
                  width={'100%'}
                  height={'80px'}
                  viewBox="0 0 100% 100%"
                  backgroundColor="#646c9a"
                  backgroundOpacity={0.05}
                  foregroundColor="#646c9a"
                  foregroundOpacity={0.1}
                  {...props}
                >
                  <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
                </ContentLoader>
              </div>
            </>
          ) : (
            <>
              <h4 className="text-xl font-medium">{getTitle(currentPlan)}</h4>
              <span className="text-sm">
                You are on the <strong>{currentPlan.name} plan</strong>
                {currentPlan.base_price > 0 ? ', billed ' : '.'}
                <strong>
                  {currentPlan.base_price > 0
                    ? currentPlan.billing_period_display.toLowerCase() + '.'
                    : undefined}
                </strong>{' '}
                You have used{' '}
                <strong>
                  {productId === 10
                    ? convertToMB(usersProductData.current_usage) +
                      ' of the ' +
                      convertToMB(currentPlan.included_api_calls)
                    : usersProductData.current_usage.toLocaleString() +
                      ' of the ' +
                      currentPlan.included_api_calls.toLocaleString() +
                      ' requests'}
                </strong>
                {currentPlan.base_price > 0 && currentPlan.billing_period === 3
                  ? ' available per month on your plan.'
                  : undefined}
                {currentPlan.base_price > 0 && currentPlan.billing_period === 2
                  ? ' available your plan.'
                  : undefined}
                {currentPlan.base_price === 0 ? '.' : undefined}
                <br />
                <br />
                {currentPlan.base_price > 0 &&
                !usersProductData.scheduled_new_plan_id ? (
                  <p>
                    Your plan will renew on{' '}
                    <strong>
                      {usersProductData.current_subscription_period_display}
                    </strong>{' '}
                    for <strong>${currentPlan.base_price}.</strong>
                  </p>
                ) : undefined}
                {currentPlan.base_price > 0 &&
                usersProductData.scheduled_new_plan_id ? (
                  <p>
                    Your plan will renew on{' '}
                    <strong>
                      {usersProductData.current_subscription_period_display}
                    </strong>{' '}
                    for your upcoming{' '}
                    <strong>
                      monthly subscription of ${usersProductData.upcoming_price}
                      .
                    </strong>
                  </p>
                ) : undefined}
              </span>
            </>
          )}
        </div>
      </div>
      <div className="mt-4">
        <div className="flex justify-end">
          <button
            className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() =>
              props.showSubscriptionOverlay(
                'Upgrade your plan',
                sourcePricingCTA,
              )
            }
          >
            Change plan
          </button>
        </div>
      </div>
    </>
  );
};

export default Pricing;
