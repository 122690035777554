import React, { useEffect, useContext } from 'react';
import APIContext from '../../contexts/APIContext';
import { trackEvent } from '../Utils/SegmentAnalytics';
import { getSignupSourceData } from '../Utils/Helpers';
import { useHistory } from 'react-router-dom';

const useCreateGoogleCallback = (
  context,
  setErrorMessage,
  setDisplayErrorMessage,
) => {
  const { APISignupByGoogle } = useContext(APIContext);
  const names = { signin: 'login', signup: 'signup' };
  const history = useHistory();

  return (result, source = 'google_button') => {
    let credential = result.credential || result;
    trackEvent(`Google ${names[context]} submitted`, { google_source: source });
    if (credential) {
      let token = new URLSearchParams(window.location.search).get('token');
      const params = {
        credential: credential,
        token: token,
        signup_source_data: getSignupSourceData(source),
      };
      return APISignupByGoogle(
        params,
        source,
        context,
        setDisplayErrorMessage,
        setErrorMessage,
        history,
      );
    }
  };
};

const GoogleButton = ({ callback, context, width = '320px' }) => {
  const contextDict = { signin: 'sign_in', signup: 'sign_up' };

  useEffect(() => {
    /* global google */
    const renderButton = () => {
      const signInDiv = document.getElementById('signInDiv');
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: callback,
        context: `${context}_with`,
        auto_select: false,
        cancel_on_tap_outside: false,
      });

      if (signInDiv) {
        window.google.accounts.id.disableAutoSelect();
        window.google.accounts.id.renderButton(signInDiv, {
          theme: 'outline',
          size: 'large',
          text: `${contextDict[context]}_with`,
          logo_alignment: 'center',
          shape: 'rectangular',
          width: `${width}`,
          locale: 'EN',
        });
      }
    };

    renderButton();
    return () => {
      window.google.accounts.id.disableAutoSelect();
      window.google.accounts.id.cancel();
    };
  }, [window.google]);

  return (
    <div id="signInDivContent">
      <div
        id="signInDiv"
        style={{
          marginTop: '1rem',
          display: 'inline-block',
          scrollMarginLeft: '20px',
        }}
      />
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center text-sm py-2">
          <span className="px-4 bg-gray-50 text-gray-500">OR</span>
        </div>
      </div>
    </div>
  );
};

export { useCreateGoogleCallback, GoogleButton };
