import React, { useEffect, useRef, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { redirectFromQuickstart } from '../../../../Quickstart/Quickstart.helpers';
import Modal from '../../../../Layout/Modal';
import Checkbox from '../../../../Layout/Checkbox';
import TextArea from '../../../../Layout/TextArea';
import { trackEvent } from '../../../../Utils/SegmentAnalytics';
import { trackPayload } from './FeedbackModal.helpers';
import useGetQueryParamValue from '../../../../Utils/useGetQueryParamValue';
import CacheContext from '../../../../../contexts/CacheContext';

const FeedbackModal = ({
  isShowingFeedbackModal,
  onClose,
  productName,
  testUrl,
}) => {
  const { location, push } = useHistory();
  const isQuickstart = location.pathname.includes('quickstart');
  const [textAreaValue, setTextAreaValue] = useState('');
  const [hasInaccurateData, setHasInaccurateData] = useState(false);
  const [hasMoreOrDifferentData, setHasMoreOrDifferentData] = useState(false);
  const [hasSlowResponseTime, setHasSlowResponseTime] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const { getUserInterest } = useContext(CacheContext);

  const api = getUserInterest();

  const modalFooterRef = useRef(null);
  const modalRef = useRef(null);

  const handleSubmitFeedback = () => {
    const answers = [];

    hasInaccurateData && answers.push('Data is inaccurate');
    hasMoreOrDifferentData && answers.push('More or different data expected');
    hasSlowResponseTime && answers.push('Response time is too slow');

    trackEvent('Test API call feedback', {
      product_name: productName,
      feedback: 'negative',
      source: isQuickstart ? 'quickstart' : 'dashboard',
      detail: answers,
      description: textAreaValue.substring(0, 250),
      payload: trackPayload(productName, testUrl),
    });

    isQuickstart && push(redirectFromQuickstart(api));
    onClose();
  };

  useEffect(() => {
    const isDisabled =
      hasInaccurateData ||
      hasMoreOrDifferentData ||
      hasSlowResponseTime ||
      textAreaValue.length > 0;

    setIsSubmitDisabled(!isDisabled);
  }, [
    hasInaccurateData,
    hasMoreOrDifferentData,
    hasSlowResponseTime,
    textAreaValue,
  ]);

  return isShowingFeedbackModal ? (
    <Modal title="There's an issue" initialFocusRef={modalFooterRef}>
      Select all the reasons that may apply.
      <div ref={modalRef} className="flex flex-col gap-6 py-6">
        <Checkbox
          id="inaccurate-data"
          label="Data is inaccurate"
          checked={hasInaccurateData}
          onChange={setHasInaccurateData}
        />
        <Checkbox
          id="slow"
          label="Response time is too slow"
          checked={hasSlowResponseTime}
          onChange={setHasSlowResponseTime}
        />
        <Checkbox
          id="more-different-data"
          label="More or different data expected"
          checked={hasMoreOrDifferentData}
          onChange={setHasMoreOrDifferentData}
        />
      </div>
      <TextArea
        id="other"
        label="Other"
        value={textAreaValue}
        onChange={setTextAreaValue}
      />
      <Modal.Footer
        ref={modalFooterRef}
        actions={{ primary: { label: 'Submit', isDisabled: isSubmitDisabled } }}
        onSubmit={handleSubmitFeedback}
      />
    </Modal>
  ) : null;
};

export default FeedbackModal;
