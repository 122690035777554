import { useState, useEffect, useContext, useRef } from 'react';
import { shouldShowOfferModal } from './utils';
import APIContext from '../../contexts/APIContext';

const useShowOfferModal = (component) => {
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [promoCodeModal, setPromoCodeModal] = useState({});
  const { userInfo, APIGetOfferModalPromoCode } = useContext(APIContext);
  const hasCheckedRef = useRef(false);

  useEffect(() => {
    const checkOfferModal = async () => {
      if (hasCheckedRef.current) return;

      const currentPath = window.location.pathname;
      const shouldFetch = currentPath.includes(`/${component.toLowerCase()}`);

      if (!userInfo || !shouldFetch || !shouldShowOfferModal(userInfo)) return;

      try {
        hasCheckedRef.current = true;
        const data = await APIGetOfferModalPromoCode();
        setShowOfferModal(!!data);
        setPromoCodeModal(data);
      } catch (error) {
        console.error('Error fetching offer details:', error);
        setShowOfferModal(false);
      }
    };

    checkOfferModal();
  }, [userInfo, component]);

  return { showOfferModal, promoCodeModal };
};

export default useShowOfferModal;
