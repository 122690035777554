import React, { useState, useContext, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import APIContext from '../../contexts/APIContext';
import { trackEventPageLoad, trackEvent } from '../Utils/SegmentAnalytics';

import SubHeader from '../Layout/SubHeader/SubHeader';

function SignupInvite(props) {
  const { APIconfirmChangeEmail } = useContext(APIContext);
  const history = useHistory();

  const [alertMessage, setAlertMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    trackEventPageLoad('email change confirmation');
    confirmEmailChange();
  }, [props.location.search]);

  async function confirmEmailChange() {
    let email = new URLSearchParams(props.location.search).get('email');
    let token = new URLSearchParams(props.location.search).get('token');

    if (!email || !token) {
      history.push('/users/login');
    } else {
      await APIconfirmChangeEmail(email, token)
        .then((response) => {
          if (response.status === 204) {
            setSuccessMessage(`Your email was successfuly changed to ${email}`);
            trackEvent('Email change confirmation accepted', {
              status: response.status,
            });
          } else {
            var message =
              "Your email change couldn't be processed. Please try again or contact support.";
            setAlertMessage(message);
            trackEvent('Email change confirmation rejected', {
              status: response.status,
              message: message,
            });
          }
        })
        .catch((error) => {
          var message =
            "Your email change couldn't be processed. Please try again or contact support.";
          setAlertMessage(message);
          trackEvent('Email change confirmation rejected', {
            status: error.response ? error.response.status : '',
            message: message,
          });
        });
    }
  }

  return (
    <div>
      <div style={{ width: 300 + 'px', margin: '100px auto 40px auto' }}>
        <img
          src="/assets/media/logos/abstract-dark-light.svg"
          alt=""
          style={{ width: 60 + '%' }}
        ></img>
      </div>
      <div
        className="kt-portlet"
        style={{ width: 300 + 'px', margin: '20px auto 20px auto' }}
      >
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Email change confirmation
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="login-form">
            {successMessage ? (
              <div
                className="alert alert-solid-success alert-bold"
                role="alert"
                style={{ marginBottom: '10px' }}
              >
                <div className="alert-text">{successMessage}</div>
              </div>
            ) : (
              <></>
            )}
            {alertMessage ? (
              <div
                className="alert alert-solid-danger alert-bold"
                role="alert"
                style={{ marginBottom: '10px' }}
              >
                <div className="alert-text">{alertMessage}</div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <Link
            to="/users/login"
            className={'btn btn-primary'}
            style={{ width: '100%', marginTop: '10px' }}
          >
            Proceed to login
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SignupInvite;
