import { NAMES } from '../../namingConventions/products.js';

const trackProductInterest = (setEventProperties, trackEvent) => {
  const hasProductInterest = Boolean(
    window.location.search.match(/target=([^&]*)/),
  );

  if (!hasProductInterest) {
    setEventProperties((prev) => ({
      ...prev,
    }));
  } else {
    const apiFromTarget = window.location.search
      .match(/target=([^&]*)/)[1]
      .split('/')[2];

    let apiFromTargetWithoutSlash =
      window.location.search.match(/target=([^&]*)/)[1];

    setEventProperties((prev) => ({
      ...prev,
      main_interest: apiFromTarget || apiFromTargetWithoutSlash,
    }));
  }
  return !hasProductInterest;
};

const getMainInterestFromParam = (param) => {
  if (!param) {
    return null;
  }

  const mainInterestList = Object.values(NAMES.KEBAB_CASE);
  mainInterestList.sort((a, b) => b.length - a.length);
  const interest = mainInterestList.find((interest) => {
    const regex = new RegExp(`\\b${interest}\\b`, 'i');
    return param.match(regex) !== null;
  });

  localStorage.setItem('mainInterest', interest);
  localStorage.setItem('user_interest', interest);

  return interest;
};

const prepareTrackingProperties = (currentStep, item) => {
  let data = JSON.parse(localStorage.getItem('user_info'));
  let mainInterest = localStorage.getItem('mainInterest');

  if (mainInterest === null) {
    data = {
      id: data.id,
      question: currentStep.trait,
      answer: item.answer,
    };
  } else {
    data = {
      id: data.id,
      question: currentStep.trait,
      main_interest: mainInterest,
      answer: item.answer,
    };
  }

  let pageData = {
    current_url: window.location.href,
    path: window.location.pathname,
  };

  return Object.assign({}, pageData, data);
};

export {
  trackProductInterest,
  getMainInterestFromParam,
  prepareTrackingProperties,
};
