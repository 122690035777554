import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const urlZapEditorIframeIntegrations = {
  Salesforce:
    'https://api.zapier.com/v1/embed/abstract/create?steps[0][app]=SalesforceCLIAPI@latest&steps[0][action]=new_record&steps[1][app]=AbstractCLIAPI@latest&steps[1][action]',
  Marketo:
    'https://api.zapier.com/v1/embed/abstract/create?steps[0][app]=MarketoCLIAPI@latest&steps[0][action]=new_lead&steps[1][app]=AbstractCLIAPI@latest&steps[1][action]',
  Zapier:
    'https://api.zapier.com/v1/embed/abstract/create?steps[0][app]=WebHookAPI&steps[0][action]=hook_raw&steps[1][app]=AbstractCLIAPI@latest&steps[1][action]',
  Snowflake:
    'https://api.zapier.com/v1/embed/abstract/create?steps[0][app]=SnowflakeCLIAPI@latest&steps[0][action]=new_row&steps[1][app]=AbstractCLIAPI@latest&steps[1][action]',
  Pardot:
    'https://api.zapier.com/v1/embed/abstract/create?steps[0][app]=PardotCLIAPI@latest&steps[0][action]=prospect&steps[1][app]=AbstractCLIAPI@latest&steps[1][action]',
  Hubspot:
    'https://api.zapier.com/v1/embed/abstract/create?steps[0][app]=HubSpotCLIAPI@latest&steps[0][action]=contactList&steps[1][app]=AbstractCLIAPI@latest&steps[1][action]',
  Shopify:
    'https://api.zapier.com/v1/embed/abstract/create?steps[0][app]=ShopifyAPI&steps[0][action]=customer&steps[1][app]=AbstractCLIAPI@latest&steps[1][action]',
  Magento:
    'https://api.zapier.com/v1/embed/abstract/create?steps[0][app]=MagentoAPI&steps[0][action]=customer&steps[1][app]=AbstractCLIAPI@latest&steps[1][action]',
  Mailchimp:
    'https://api.zapier.com/v1/embed/abstract/create?steps[0][app]=MailchimpCLIAPI@latest&steps[0][action]=new_member&steps[1][app]=AbstractCLIAPI@latest&steps[1][action]',
  Slack:
    'https://api.zapier.com/v1/embed/abstract/create?steps[0][app]=SlackAPI&steps[0][action]=team_join&steps[1][app]=AbstractCLIAPI@latest&steps[1][action]',
  'Zoho CRM':
    'https://api.zapier.com/v1/embed/abstract/create?steps[0][app]=ZohoCRMCLIAPI@latest&steps[0][action]=contactList&steps[1][app]=AbstractCLIAPI@latest&steps[1][action]',
  'Google Sheets':
    'https://api.zapier.com/v1/embed/abstract/create?steps[0][app]=GoogleSheetsV2API&steps[0][action]=new_row_notify_hook&steps[1][app]=AbstractCLIAPI@latest&steps[1][action]',
  'Google Forms':
    'https://api.zapier.com/v1/embed/abstract/create?steps[0][app]=GoogleFormsCLIAPI@latest&steps[0][action]=new_form_response&steps[1][app]=AbstractCLIAPI@latest&steps[1][action]',
  'Active Campaign':
    'https://api.zapier.com/v1/embed/abstract/create?steps[0][app]=ActiveCampaignCLIAPI@latest&steps[0][action]=custom_object_create_update&steps[1][app]=AbstractCLIAPI@latest&steps[1][action]',
  'Customer IO': 'CustomerIO',
  Azure:
    'https://api.zapier.com/v1/embed/abstract/create?steps[0][app]=VisualStudioOnlineCLIAPI@latest&steps[0][action]=work_item_commented_on&steps[1][app]=AbstractCLIAPI@latest&steps[1][action]',
};

export const apiInZapierNames = {
  'ip-geolocation': 'ip_geolocation',
  'email-validation': 'email_verification',
  'phone-validation': 'phone',
  'company-enrichment': 'domain',
};
export const getUrlEditorEmbedZapiers = (integrationName, api) => {
  let urlZapIntegration = urlZapEditorIframeIntegrations[integrationName];
  let apiName = apiInZapierNames[api];
  return `${urlZapIntegration}=${apiName}`;
};

const IntegrationsZapierModal = ({
  isVisible,
  onClose,
  service,
  api,
  setIframeUrl,
  iframeURL,
}) => {
  useEffect(() => {
    setIframeUrl(getUrlEditorEmbedZapiers(service, api));
  }, [service, api]);

  const Iframe = () => {
    useEffect(() => {
      const iframe = document.getElementById('zapier-iframe');
      iframe.src = iframeURL;
    }, [iframeURL]);

    return (
      <iframe
        id="zapier-iframe"
        src={iframeURL}
        width="100%"
        height="800px"
      ></iframe>
    );
  };
  return (
    <>
      <Transition.Root show={isVisible} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-50 inset-0 overflow-y-auto"
          open={isVisible}
          onClose={onClose}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-right overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full  h-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="mt-1 text-center sm:mt-0 sm:text-left">
                    <div className="mt-2">
                      <Iframe src={iframeURL} />
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default IntegrationsZapierModal;
