import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';

function Menu(props) {
  return (
    <ul role="tablist" className="flex flex-col">
      <NavLink
        to={`/api/${props.match.params.api}/pricing`}
        className="px-6 py-3 w-full hover:bg-gray-brand-100 hover:text-gray-brand-500 text-sm font-semibold"
        activeClassName="bg-gray-brand-100 text-gray-brand-500"
      >
        Pricing
      </NavLink>
      <NavLink
        to={`/api/${props.match.params.api}/tester`}
        className="px-6 py-3 w-full hover:bg-gray-brand-100 hover:text-gray-brand-500 text-sm font-semibold"
        activeClassName="bg-gray-brand-100 text-gray-brand-500"
      >
        Try it out
      </NavLink>

      <a
        href={`https://docs.abstractapi.com/${props.match.params.api}`}
        className="px-6 py-3 w-full hover:bg-gray-brand-100 hover:text-gray-brand-500 text-sm font-semibold"
        activeClassName="bg-gray-brand-100 text-gray-brand-500"
        target="_blank"
      >
        Documentation
      </a>
      <NavLink
        to={`/api/${props.match.params.api}/integrations`}
        className="px-6 py-3 w-full hover:bg-gray-brand-100 hover:text-gray-brand-500 text-sm font-semibold justify-self-auto"
        activeClassName="bg-gray-brand-100 text-gray-brand-500"
      >
        Integrations{' '}
        <span className="mr-0 ml-2 bg-green-100 border-green-400 border border-1px text-green-600 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
          New
        </span>
      </NavLink>
      {props.match.params.api === 'email-validation' ||
      props.match.params.api === 'phone-validation' ? (
        <NavLink
          to={`/api/${props.match.params.api}/bulk-upload`}
          className="px-6 py-3 w-full hover:bg-gray-brand-100 hover:text-gray-brand-500 text-sm font-semibold"
          activeClassName="bg-gray-brand-100 text-gray-brand-500"
        >
          Bulk upload (CSV)
        </NavLink>
      ) : undefined}
      <NavLink
        to={`/api/${props.match.params.api}/usage`}
        className="px-6 py-3 w-full hover:bg-gray-brand-100 hover:text-gray-brand-500 text-sm font-semibold"
        activeClassName="bg-gray-brand-100 text-gray-brand-500"
      >
        Usage
      </NavLink>
      <NavLink
        to={`/api/${props.match.params.api}/settings`}
        className="px-6 py-3 w-full hover:bg-gray-brand-100 hover:text-gray-brand-500 text-sm font-semibold"
        activeClassName="bg-gray-brand-100 text-gray-brand-500"
      >
        Settings
      </NavLink>
      <NavLink
        to={`/api/${props.match.params.api}/support`}
        className="px-6 py-3 w-full hover:bg-gray-brand-100 hover:text-gray-brand-500 text-sm font-semibold"
        activeClassName="bg-gray-brand-100 text-gray-brand-500"
      >
        Support
      </NavLink>
    </ul>
  );
}

export default withRouter(Menu);
