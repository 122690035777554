import { useCallback, useState } from 'react';
import { get } from 'axios';

import { trackEvent } from '../../Utils/SegmentAnalytics';

import {
  displayTestResponse,
  removeParameterFromUrl,
  productPropertyByProductId,
} from '../Quickstart.helpers';

const usePerformTestRequest = ({
  productId,
  testUrlEditable,
  setTestInformation,
  testInformation,
  setShowPopover,
  showPopover,
  setShowTester,
  productName,
}) => {
  const [loading, setLoading] = useState(false);

  const performTestRequest = useCallback(
    async (event) => {
      const isNotAvatarOrScreenshot = ![4, 8].includes(productId);
      const isEmailOrIpOrPhone = [2, 6, 7].includes(productId);
      setTestInformation({
        statusColor: 'indigo',
        statusText: 'Request sent',
        result: 'Awaiting response...',
      });

      event.preventDefault();
      setLoading(true);
      try {
        if (isNotAvatarOrScreenshot) {
          const response = await get(testUrlEditable);
          displayTestResponse({ response, setTestInformation });
          const timeoutId = setTimeout(() => setShowPopover(true), 1000);
          if (showPopover) clearTimeout(timeoutId);

          let requestUrl = removeParameterFromUrl(testUrlEditable, 'api_key');

          if (isEmailOrIpOrPhone) {
            trackEvent('Test API call completed', {
              request_url: requestUrl,
              response_status: response.status,
              product_name: productName,
              payload: response.data[productPropertyByProductId[productId]],
            });
          } else {
            trackEvent('Test API call completed', {
              request_url: requestUrl,
              response_status: response.status,
              product_name: productName,
            });
          }
        } else {
          const timeoutId = setTimeout(() => setShowPopover(true), 1000);
          if (showPopover) clearTimeout(timeoutId);

          const newWindow = window.open(
            testUrlEditable,
            '_blank',
            'noopener,noreferrer',
          );
          if (newWindow) {
            newWindow.opener = null;
          }
          let requestUrl = removeParameterFromUrl(testUrlEditable, 'api_key');

          trackEvent('Test API call completed', {
            product_name: productName,
            request_url: requestUrl,
          });
        }
      } catch (error) {
        displayTestResponse({
          response: error.response,
          setTestInformation,
        });

        let requestUrl = removeParameterFromUrl(testUrlEditable, 'api_key');
        trackEvent('Test API call completed', {
          request_url: requestUrl,
          response_status: error.response.status,
        });
      } finally {
        setLoading(false);
        setShowTester(true);
      }
    },
    [
      productId,
      testUrlEditable,
      setTestInformation,
      setShowPopover,
      showPopover,
      setShowTester,
      productName,
    ],
  );

  return { performTestRequest, loading };
};

export default usePerformTestRequest;
