import React from 'react';
import advantages from '../../assets/json/socialProof.json';
import partners from '../../assets/json/partners.json';

const SocialProof = () => {
  return (
    <div className={'bg-white lg:h-96 mt-14 md:mt-0 px-8 lg:pl-20'}>
      <div className={'mb-6 font-semibold'}>
        You're seconds away from your free API key, along with:
      </div>
      <div>
        {advantages.map((it) => (
          <div className={'flex gap-3'} key={it}>
            <img
              src={'/assets/media/icons/checkMark.svg'}
              className={'mb-4'}
              alt={'check mark'}
            />
            <div className={'text-sm'}>{it}</div>
          </div>
        ))}
      </div>

      <div className={'mt-8 mb-1 font-semibold'}>
        100,000+ of the world's top engineers and organizations run on Abstract:
      </div>
      <div className="bg-white">
        {
          <div className={'bg-white'}>
            <div className="grid grid-cols-2 gap-0.5 md:grid-cols-4 mb-10 lg:mb-0">
              {partners.map((it) => (
                <div
                  key={it.title}
                  className="flex justify-center py-4 pr-16 bg-white"
                >
                  <img className="max-h-12" src={it.icon} alt={it.title} />
                </div>
              ))}
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export { SocialProof };
