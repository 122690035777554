import { useState, useEffect } from 'react';

import IntegrationsModal from '../../Modals/IntegrationsModal';
import IntegrationSuccessModal from '../../Modals/IntegrationsSuccessModal';
import IntegrationZapierModal from '../../Modals/IntegrationsZapierModal';
import { getUrlEditorEmbedZapiers } from '../../Modals/IntegrationsZapierModal';
import integrationData from '../../../assets/json/integrations.json';
import { trackEvent } from '../../Utils/SegmentAnalytics';

const implementedInZapier = [
  'ip-geolocation',
  'email-validation',
  'phone-validation',
  'company-enrichment',
];

const IntegrationElement = ({
  name,
  logoUrl,
  toggleModal,
  supportedByZapier,
}) => {
  return (
    <div
      className="border border-2px rounded-lg p-4 bg-white flex items-center cursor-pointer"
      onClick={() => toggleModal(name, supportedByZapier)}
    >
      <img src={logoUrl} alt="Logo" className="w-1/5" />
      <div className="ml-4">
        <h2 className="text-sm font-semibold text-gray-600">{name}</h2>
      </div>
    </div>
  );
};

const Integrations = ({ api, apiPlanName, apiPlanPrice, ...props }) => {
  const [{ isSuccessModalVisible, service, isZapierSupported }, switchModals] =
    useState({
      isSuccessModalVisible: false,
      service: false,
      isZapierSupported: false,
    });

  const [isZapierModalVisible, setIsZapierModalVisible] = useState(false);

  const [iframeURL, setIframeUrl] = useState(
    getUrlEditorEmbedZapiers(service, api),
  );

  useEffect(() => {
    trackEvent('App page loaded - integrations', {
      product_name: api,
      plan_name: apiPlanName,
      plan_price: apiPlanPrice,
    });
  }, []);

  const selectService = (service, supportedByZapier) => {
    trackEvent('API integrations - integration selected', {
      product_name: api,
      integration_name: service,
      plan_name: apiPlanName,
      plan_price: apiPlanPrice,
    });

    if (supportedByZapier && implementedInZapier.includes(api)) {
      setIsZapierModalVisible(true);
      setIframeUrl(getUrlEditorEmbedZapiers(service, api));
    } else {
      switchModals({
        isSuccessModalVisible,
        service: service,
        isZapierSupported:
          supportedByZapier && implementedInZapier.includes(api),
      });
    }
  };

  const showIntegrationSuccessModal = () => {
    if (isZapierSupported) {
      trackEvent('API integrations - zapier integration selected', {
        product_name: api,
        integration_name: service,
        plan_name: apiPlanName,
        plan_price: apiPlanPrice,
      });
      switchModals({ isSuccessModalVisible: false, service: false });
    } else {
      trackEvent('API integrations - price accepted', {
        product_name: api,
        integration_name: service,
        plan_name: apiPlanName,
        plan_price: apiPlanPrice,
      });
      switchModals({ isSuccessModalVisible: true, service: false });
    }
  };

  const closeIntegrationSuccessModal = () => {
    switchModals({ isSuccessModalVisible: false, service: false });
  };

  const closeIntegrationZapierModal = () => {
    setIsZapierModalVisible(false);
  };

  const requestIntegration = () => {
    trackEvent('API integrations - missing integration clicked', {
      product_name: api,
      plan_name: apiPlanName,
      plan_price: apiPlanPrice,
    });

    window.open(
      'mailto:support@abstractapi.com?subject=I%E2%80%99d%20like%20you%20to%20include%20this%20integration',
      '_blank',
    );
  };

  return (
    <>
      <IntegrationsModal
        isVisible={!!service}
        onCancel={() => switchModals({ isSuccessModalVisible, service: false })}
        onAccept={showIntegrationSuccessModal}
        api={api}
        apiKey={props.apiKey}
        integrationService={service}
        isZapierSupported={isZapierSupported}
      />
      <IntegrationSuccessModal
        isVisible={isSuccessModalVisible}
        onClose={closeIntegrationSuccessModal}
        apiKey={props.apiKey}
        integrationService={service}
        onAccept={null}
      />
      <IntegrationZapierModal
        isVisible={isZapierModalVisible}
        onClose={closeIntegrationZapierModal}
        service={service}
        api={api}
        setIframeUrl={setIframeUrl}
        iframeURL={iframeURL}
      />
      <h2 className="text-xl font-medium">Integrations</h2>
      <div className="mt-4 text-sm">
        Abstract supports +6000 integrations via Zapier. Don't see the
        integration you need?
        <a
          href="#"
          className="ml-2 text-gray-brand-500 no-underline hover:underline"
          onClick={requestIntegration}
        >
          Let us know.
        </a>
      </div>
      <div className="mt-4">
        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-3">
          {integrationData.map(({ name, icon, supportedByZapier }) => (
            <IntegrationElement
              key={`integration_${name}`}
              name={name}
              supportedByZapier={supportedByZapier}
              logoUrl={icon}
              toggleModal={selectService}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Integrations;
