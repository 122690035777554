import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

import APIContext from '../contexts/APIContext';
import SessionContext from '../contexts/SessionContext';
import CacheContext from '../contexts/CacheContext';
import { NewHeader, NewFooter } from '../components/Layout/index';
import { HeaderProvider } from '../contexts/HeaderContext';

const RouteContainer = ({ component: Component, ...rest }) => {
  const { isUserAuthenticated, isUserImpersonated } =
    useContext(SessionContext);
  const { userId, organizationSuspended } = useContext(CacheContext);
  const { APIrefreshUserData } = useContext(APIContext);
  const { setPaymentFailedModalVisibility } = rest;
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    if (isUserAuthenticated()) {
      APIrefreshUserData();
    }
    if (organizationSuspended) {
      setPaymentFailedModalVisibility(true);
    }
    if (window.location.pathname === '/offer') {
      setShowHeader(false);
    }
  }, [userId, isUserImpersonated, organizationSuspended]);

  const renderComponent = (props) =>
    isUserAuthenticated() ? (
      !showHeader ? (
        <Component {...props} />
      ) : (
        <HeaderProvider>
          <NewHeader />
          <main>
            <div
              className="h-full w-full flex flex-col items-center"
              style={{
                backgroundImage:
                  'linear-gradient(180deg, #F1F3FF 0%, rgba(241, 243, 255, 0) 100%)',
              }}
            >
              <div className="mt-32 md:mt-20 md:pb-20 ">
                <Component {...props} />
              </div>
            </div>
          </main>
          <NewFooter />
        </HeaderProvider>
      )
    ) : (
      <Redirect to="/login" />
    );

  const renderRoute = (props) => renderComponent(props);

  return <Route {...rest} render={renderRoute} />;
};

export { RouteContainer };
