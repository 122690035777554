import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useRef,
} from 'react';
import { withRouter } from 'react-router';
import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon, CheckIcon } from '@heroicons/react/outline';
import APIContext from '../../contexts/APIContext';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../Utils/SegmentAnalytics';
import { checkSubscriptionStatus } from '../Utils/Helpers';

const SubscriptionPaymentModal = (props) => {
  const {
    APIRetrieveCheckoutSession,
    APIgetSuggestedPlan,
    APIrefreshUserData,
  } = useContext(APIContext);
  let history = useHistory();

  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(props.isVisible);
  const isFromOnboarding = window.location.search.includes('from_onboarding');
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    const productId = urlParams.get('product_id');
    const coupon = urlParams.get('coupon_discount');
    if (sessionId) {
      if (productId || props.productId) {
        handleSessionId(sessionId, productId || props.productId, coupon);
      }
    }
  }, [window.location.href]);

  const closeModal = () => {
    props.closeModal();
  };

  const hideSuccessModal = () => {
    // APIrefreshUserData();

    // redirect to API
    const regex = /\/api\/([^\/?]*)/;
    const match = window.location.href.match(regex);
    const redirectUrl = match ? `/api/${match[1]}/tester` : '/';

    const wasOffered = Boolean(
      window.location.search.match(/was_offered=([^&]*)/),
    );

    if (wasOffered || isFromOnboarding) {
      history.push(redirectUrl);
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const plan_id = urlParams.get('plan_id');

      const { isEmailSubscriptor, isIPGeolocationSubscriptor } =
        checkSubscriptionStatus();

      if (!plan_id || (isEmailSubscriptor && isIPGeolocationSubscriptor)) {
        setIsVisible(false);
        props.closeModal();
        history.push(redirectUrl);
      } else {
        let offeredApiName = !isEmailSubscriptor
          ? 'Email Validation'
          : 'IP Geolocation';

        APIgetSuggestedPlan(plan_id, offeredApiName)
          .then((data) => {
            const planUpsell = data.offer_details;
            if (
              planUpsell.product_name === 'Email Validation' &&
              !isEmailSubscriptor
            ) {
              history.push(
                `/offer?plan_id=${plan_id}&offered_api_name=Email Validation`,
              );
            } else if (
              planUpsell.product_name === 'IP Geolocation' &&
              !isIPGeolocationSubscriptor
            ) {
              history.push(
                `/offer?plan_id=${plan_id}&offered_api_name=IP Geolocation`,
              );
            } else {
              history.push(redirectUrl);
            }
          })
          .catch((error) => {
            const message =
              error.response?.data?.message ||
              'Failed to retrieve the suggested plan. Please try again later.';
            setErrorMessage(message);
            setIsLoading(false);
            trackEvent('Subscription - suggested plan retrieval failed', {
              plan_id: plan_id,
              error_message: message,
            });
          });
      }
    }
  };

  const handleSessionId = (sessionId, productId, coupon) => {
    setErrorMessage('');
    setShowSuccessMessage(false);
    setIsLoading(true);

    APIRetrieveCheckoutSession(sessionId)
      .then((response) => {
        if (response.data.session.payment_status === 'paid') {
          // show success modal
          setShowSuccessMessage(true);
          setIsLoading(false);
          APIrefreshUserData();
          trackEvent('Subscription - plan change succeeded', {
            product_id: productId,
            plan_id: response.data.session.metadata.plan_id,
            plan_name: response.data.session.metadata.plan_name,
            plan_price: response.data.session.metadata.plan_price,
            plan_included_api_calls:
              response.data.session.metadata.plan_included_api_calls,
            ...(coupon && {
              coupon_discount: coupon,
              amount_paid: response.data.session.amount_total / 100,
            }),
          });

          setIsVisible(true);
        }
      })
      .catch((error) => {
        const message =
          error.response?.data?.message ||
          "Your payment couldn't be processed. Please try again or contact support.";
        setErrorMessage(message);
        setIsLoading(false);
        trackEvent('Subscription - plan change failed', {
          product_id: props.productId,
          plan_id: '',
          plan_name: '',
          plan_price: '',
          plan_included_api_calls: '',
          error_message: message,
        });
      });
  };

  return (
    <>
      {!isLoading ? (
        <Transition.Root show={isVisible} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={cancelButtonRef}
            onClose={closeModal}
            open={true}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      {showSuccessMessage ? (
                        <div>
                          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                            <CheckIcon
                              className="h-6 w-6 text-green-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title
                              as="h3"
                              className="text-lg leading-6 font-medium text-gray-900"
                            >
                              Payment successful
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                You are now successfuly subscribed to your new
                                plan
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                            <XCircleIcon
                              className="h-5 w-5 text-red-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title
                              as="h3"
                              className="text-lg leading-6 font-medium text-gray-900"
                            >
                              Payment failed
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                {errorMessage}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={hideSuccessModal}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      ) : (
        <></>
      )}
    </>
  );
};

export default withRouter(SubscriptionPaymentModal);
