import './App.css';

import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { useState } from 'react';

// Contexts
import { CacheProvider } from './contexts/CacheContext';
import { SessionProvider } from './contexts/SessionContext';
import { APIProvider } from './contexts/APIContext';

// Routing
import PublicRoute from './routing/PublicRoute';
import PrivateRoute from './routing/PrivateRoute';
import { RouteContainer } from './routing/RouteContainer';

// Public views
import Signup from './components/Auth/Signup';
import SignupInvite from './components/Auth/SignupInvite';
import Login from './components/Auth/Login';
import PasswordReset from './components/Auth/PasswordReset';
import PasswordResetConfirm from './components/Auth/PasswordResetConfirm';
import EmailChangeConfirm from './components/Auth/EmailChangeConfirm';
import EmailConfirm from './components/Auth/EmailConfirm';

// Private views
import { Onboarding } from './components/Onboarding/Onboarding';
import { Quickstart } from './components/Quickstart/Quickstart';
import Dashboard from './components/Dashboard/Dashboard';
import API from './components/API/API';
import Beta from './components/Beta/Beta';
import Settings from './components/Settings/Settings';
import Admin from './components/Admin/Admin';
import UpdatePaymentDetails from './components/Admin/UpdatePaymentDetails';
import PaymentFailedModal from './components/Modals/PaymentFailedModal';
import TrackerCallScheduled from './components/TrackerCallScheduled/TrackerCallScheduled';
import Offer from './components/Offer/Offer';
// Security and logging
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// Third party libraries
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function App() {
  console.log('Loading Abstract - ' + process.env.REACT_APP_ENVIRONMENT);
  const [paymentFailedModalState, setPaymentFailedModalState] = useState(false);
  Sentry.init({
    dsn: 'https://d461975281d74006bde84fa8e7f13c4b@o417279.ingest.sentry.io/5688651',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENVIRONMENT
      ? process.env.REACT_APP_ENVIRONMENT
      : 'development',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  const setPaymentFailedModalVisibility = (isVisible) => {
    setPaymentFailedModalState(isVisible);
  };

  return (
    <HttpsRedirect>
      <CacheProvider>
        <SessionProvider>
          <APIProvider>
            <Elements stripe={stripePromise}>
              <PaymentFailedModal isVisible={paymentFailedModalState} />
              <BrowserRouter>
                <Switch>
                  <PublicRoute exact path="/users/login" component={Login} />
                  <PublicRoute exact path="/users/signup" component={Signup} />
                  <PublicRoute
                    exact
                    path="/users/signup/invite"
                    component={SignupInvite}
                  />
                  <PublicRoute
                    exact
                    path="/users/password/reset"
                    component={PasswordReset}
                  />
                  <PublicRoute
                    exact
                    path="/users/password/reset/confirm"
                    component={PasswordResetConfirm}
                  />
                  <Route
                    exact
                    path="/users/email/change/confirm"
                    component={EmailChangeConfirm}
                  />
                  <Route
                    exact
                    path="/users/email/confirm"
                    component={EmailConfirm}
                  />
                  <Route
                    exact
                    path="/billing-portal"
                    component={UpdatePaymentDetails}
                  />
                  <Route
                    exact
                    path="/call-scheduled"
                    component={TrackerCallScheduled}
                  />
                  <RouteContainer exact path="/offer" component={Offer} />
                  <RouteContainer
                    exact
                    path="/onboarding"
                    component={Onboarding}
                    setPaymentFailedModalVisibility={
                      setPaymentFailedModalVisibility
                    }
                  />
                  <RouteContainer
                    exact
                    path="/quickstart"
                    component={Quickstart}
                    setPaymentFailedModalVisibility={
                      setPaymentFailedModalVisibility
                    }
                  />
                  <PrivateRoute
                    exact
                    path="/call-scheduled"
                    component={TrackerCallScheduled}
                    setPaymentFailedModalVisibility={
                      setPaymentFailedModalVisibility
                    }
                  />
                  <PrivateRoute
                    exact
                    path="/dashboard"
                    component={Dashboard}
                    setPaymentFailedModalVisibility={
                      setPaymentFailedModalVisibility
                    }
                  />
                  <PrivateRoute
                    path="/api/:api"
                    component={API}
                    setPaymentFailedModalVisibility={
                      setPaymentFailedModalVisibility
                    }
                  />
                  <PrivateRoute
                    path="/beta"
                    component={Beta}
                    setPaymentFailedModalVisibility={
                      setPaymentFailedModalVisibility
                    }
                  />
                  <PrivateRoute
                    path="/settings"
                    component={Settings}
                    setPaymentFailedModalVisibility={
                      setPaymentFailedModalVisibility
                    }
                  />
                  <PrivateRoute
                    exact
                    path="/admin"
                    rolesAllowed={['is_staff']}
                    component={Admin}
                    setPaymentFailedModalVisibility={
                      setPaymentFailedModalVisibility
                    }
                  />
                  <Redirect to="/users/login" />
                </Switch>
              </BrowserRouter>
            </Elements>
          </APIProvider>
        </SessionProvider>
      </CacheProvider>
    </HttpsRedirect>
  );
}

export default App;
