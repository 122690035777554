import React, { useContext, useState, useEffect } from 'react';

import APIContext from '../../../contexts/APIContext';
import InviteModal from '../../Modals/InviteModal';
import RemoveUserModal from '../../Modals/RemoveUserModal';

import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { CheckCircleIcon } from '@heroicons/react/outline';

import { trackEventPageLoad } from '../../Utils/SegmentAnalytics';
import { useHistory } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Organization(props) {
  const { APIgetOrganizationMembers, APIinviteOrganizationMember } =
    useContext(APIContext);
  const { location } = useHistory();
  const [source, setSource] = useState('organization page');
  const [users, setUsers] = useState([]);
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [isRemoveUserModalVisible, setIsRemoveUserModalVisible] =
    useState(false);
  const [resendInviteState, setResendInviteState] = useState('default'); // default > processing > success
  const [userProp, setUserProp] = useState({});

  useEffect(() => {
    location.search.includes('utm_source=email') && setSource('email');
    getUsers();
    trackEventPageLoad('settings organization');
  }, []);

  const getUsers = () => {
    APIgetOrganizationMembers().then((members) => setUsers(members));
  };

  const resendInvite = (email, key) => {
    setResendInviteState('processing');
    APIinviteOrganizationMember(email)
      .then((response) => {
        setResendInviteState('success');
      })
      .catch((error) => {
        setResendInviteState('success');
      });
  };

  const closeModal = () => {
    getUsers();
    setIsInviteModalVisible(false);
    setIsRemoveUserModalVisible(false);
  };

  const displayRemoveUserModal = (user) => {
    setUserProp(user);
    setIsRemoveUserModalVisible(true);
  };

  return (
    <>
      <InviteModal
        isVisible={isInviteModalVisible}
        closeModal={closeModal}
        source={source}
      />
      <RemoveUserModal
        isVisible={isRemoveUserModalVisible}
        closeModal={closeModal}
        user={userProp}
      />
      <h2 className="text-xl font-medium">Organization</h2>
      <div className="mt-4 text-sm">
        Invite team members to your organization. They will get access to your
        account's APIs, documentation and will be able to manage billing.
      </div>
      <div className="mt-8 text-sm">
        <div className="flex flex-col">
          <div className="-my-2  sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="border border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {users.map((user, index) => {
                      return (
                        <tr key={user.email}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            {user.email}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            <span
                              className={
                                user.status === 'Active'
                                  ? 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800'
                                  : 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800'
                              }
                            >
                              {user.status}
                            </span>
                          </td>
                          {user.email !==
                          JSON.parse(localStorage.getItem(`user_info`))
                            .email ? (
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <Menu
                                as="div"
                                className="relative inline-block text-left"
                              >
                                {({ open }) => (
                                  <>
                                    <div>
                                      <Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-black-abstract-branding-500">
                                        <span className="sr-only">
                                          Open options
                                        </span>
                                        <DotsVerticalIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </Menu.Button>
                                    </div>

                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items
                                        static
                                        className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                      >
                                        {user.status !== 'Active' ? (
                                          <div className="py-1">
                                            <Menu.Item>
                                              {({ active }) => (
                                                <button
                                                  href="#"
                                                  className={classNames(
                                                    active
                                                      ? 'bg-gray-100 text-gray-900'
                                                      : 'text-gray-700',
                                                    'w-full text-left block px-4 py-2 text-sm',
                                                  )}
                                                  onClick={() =>
                                                    resendInvite(user.email)
                                                  }
                                                >
                                                  Resend invite
                                                </button>
                                              )}
                                            </Menu.Item>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        <div className="py-1">
                                          <Menu.Item>
                                            {({ active }) => (
                                              <button
                                                href="#"
                                                className={classNames(
                                                  active
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'text-gray-700',
                                                  'w-full text-left block px-4 py-2 text-sm',
                                                )}
                                                onClick={() =>
                                                  displayRemoveUserModal(user)
                                                }
                                              >
                                                {' '}
                                                {user.status === 'Pending'
                                                  ? 'Cancel Invite'
                                                  : 'Remove user'}
                                              </button>
                                            )}
                                          </Menu.Item>
                                        </div>
                                      </Menu.Items>
                                    </Transition>
                                  </>
                                )}
                              </Menu>
                            </td>
                          ) : (
                            <></>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="mt-3 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setIsInviteModalVisible(true)}
                >
                  Invite new user
                </button>
              </div>

              {resendInviteState === 'success' ? (
                <div className="mt-4 rounded-md bg-green-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-green-800">
                        Email sent
                      </h3>
                      <div className="mt-2 text-sm text-green-700">
                        <p>Your invite reminder was successfuly sent.</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : resendInviteState === 'processing' ? (
                <div className="mt-4 rounded-md bg-yellow-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-yellow-800">
                        Email being sent...
                      </h3>
                      <div className="mt-2 text-sm text-yellow-700">
                        <p>Your invite reminder is being sent.</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <> </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Organization;
