import React, { forwardRef } from 'react';

const ArrowIndicator = forwardRef((props, ref) => {
  const arrowPosition = {
    right: {
      borderRight: '2px solid transparent',
      borderTop: '2px solid transparent',
      right: 52,
      top: 37,
    },
    left: {
      borderLeft: '2px solid transparent',
      borderBottom: '2px solid transparent',
      left: 248,
      top: 37,
    },
  };

  return (
    <div className="w-9 h-9 absolute" ref={ref}>
      <div className="relative">
        <div
          className="w-6 h-6 bg-white box-border border-2 border-gray-brand-500 absolute"
          style={{
            ...arrowPosition[props.placement],
            transform: 'translate(50%, -50%) rotate(45deg)',
          }}
        />
      </div>
    </div>
  );
});

export default ArrowIndicator;
