import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import APIContext from '../../../../contexts/APIContext';
import CacheContext from '../../../../contexts/CacheContext';
import SessionContext from '../../../../contexts/SessionContext';
import {
  resetAnalyticsSession,
  trackEventPageLoad,
} from '../../../Utils/SegmentAnalytics';

import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

import { UserCircleIcon } from '@heroicons/react/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function TopbarSettings(props) {
  const { APImanageBilling, APIlogout, APIstopImpersonation } =
    useContext(APIContext);
  const { userRoles, getUserInformation } = useContext(CacheContext);
  const { isUserImpersonated } = useContext(SessionContext);

  useEffect(() => {}, [isUserImpersonated]);

  async function goToBilling() {
    await APImanageBilling().then((url) => {
      window.open(url, '_self');
    });

    trackEventPageLoad('settings billing');
  }

  async function logout() {
    resetAnalyticsSession();
    APIlogout();
  }

  async function stopImpersonation() {
    APIstopImpersonation();
  }

  return (
    <Menu as="div" className="ml-3 relative">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="p-1 rounded-full text-gray-brand-500 hover:text-white hover:bg-gray-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <span className="sr-only">Open user menu</span>
              <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
            >
              <div className="px-4 py-3">
                <p className="text-sm">Signed in as</p>
                <p className="text-sm font-medium text-gray-900 truncate">
                  {getUserInformation() ? getUserInformation().email : ''}
                </p>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/settings/account"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      )}
                    >
                      Account settings
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/settings/organization"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      )}
                    >
                      Organization
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={goToBilling}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm w-full text-left',
                      )}
                    >
                      Billing
                    </button>
                  )}
                </Menu.Item>
              </div>
              {userRoles.includes('is_staff') ? (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/admin"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        )}
                      >
                        Impersonate
                      </Link>
                    )}
                  </Menu.Item>
                </div>
              ) : (
                <></>
              )}

              {isUserImpersonated ? (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={stopImpersonation}
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm w-full text-left',
                        )}
                      >
                        Stop impersonation
                      </button>
                    )}
                  </Menu.Item>
                </div>
              ) : (
                <></>
              )}

              {userRoles.includes('is_staff') ? (
                <div className="py-1">
                  <Menu.Item>
                    <a
                      href="https://api.abstractapi.com/admin/users/user/"
                      className="text-gray-900 block w-full text-left px-4 py-2 text-sm"
                      target="_blank"
                    >
                      Admin
                    </a>
                  </Menu.Item>
                </div>
              ) : (
                <></>
              )}

              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={logout}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full text-left px-4 py-2 text-sm',
                      )}
                    >
                      Sign out
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

export default TopbarSettings;
