import { useState, useEffect, useContext, Fragment, useRef } from 'react';
import APIContext from '../../../contexts/APIContext';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline';

import {
  InformationCircleIcon,
  PlusIcon,
  TrashIcon,
  GlobeAltIcon,
} from '@heroicons/react/outline';

function SettingsApiConfig(props) {
  const [whitelistedDomains, setWhitelistedDomains] = useState([]);
  const [whitelistedDomainsEdited, setWhitelistedDomainsEdited] = useState([]);
  const [isEditState, setIsEditState] = useState(false);

  const [selectedApiKeyID, setSelectedApiKeyID] = useState(null);
  const [selectedApiKey, setSelectedApiKey] = useState(null);

  const [showRotateModal, setShowRotateModal] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showDeleteKeyModal, setShowDeleteKeyModal] = useState(false);

  const [newApiKey, setNewApiKey] = useState(null);
  const [newApiKeyName, setNewApiKeyName] = useState(null);

  const [error, setError] = useState(null);

  const [showModalNewApiKeyName, setShowModalNewApiKeyName] = useState(false);
  const [showModalNewSlaveKey, setShowModalNewSlaveKey] = useState(false);
  const [slaveKeys, setSlaveKeys] = useState([]);

  const cancelButtonRef = useRef(null);

  const {
    APIgetDomainsWhitelist,
    APIupdateDomainsWhitelist,
    APIRotateKey,
    APIKeyChangeName,
    APIKeyCreateSlaveKey,
    APIKeyGetSlaveKeys,
    APIKeyDeleteSlaveKey,
  } = useContext(APIContext);

  useEffect(() => {
    getWhitelist(props.apiKey);
    APIKeyGetSlaveKeys(props.apiKeyID).then((response) => {
      setSlaveKeys(response.data);
    });
  }, [props.apiKey]);

  const getWhitelist = async (apiKey) => {
    await APIgetDomainsWhitelist(apiKey).then((response) => {
      if (response.status === 200) {
        setWhitelistedDomains(response.data);
      } else if (response.status === 403) {
        setWhitelistedDomains([]);
      } else {
        setWhitelistedDomains([]);
      }
    });
  };

  const saveWhitelist = async () => {
    var list = whitelistedDomainsEdited.slice().filter((e) => e);
    setWhitelistedDomains(list);
    setIsEditState(false);
    setWhitelistedDomainsEdited(whitelistedDomains.slice());
    await APIupdateDomainsWhitelist(props.apiKey, whitelistedDomainsEdited);
  };

  const startEdit = () => {
    setWhitelistedDomainsEdited(whitelistedDomains.slice());
    setIsEditState(true);
  };

  const addDomain = () => {
    var list = whitelistedDomainsEdited.slice();
    list.push('');
    setWhitelistedDomainsEdited(list);
  };

  const editDomain = (event, index) => {
    var list = whitelistedDomainsEdited.slice();
    list[index] = event.target.value;
    setWhitelistedDomainsEdited(list);
  };

  const deleteDomain = (index) => {
    var list = whitelistedDomainsEdited.slice();
    list.splice(index, 1);
    setWhitelistedDomainsEdited(list);
  };

  const createWhitelist = () => {
    setWhitelistedDomainsEdited(whitelistedDomains.slice());
    setIsEditState(true);
    var list = whitelistedDomainsEdited.slice();
    list.push('');
    setWhitelistedDomainsEdited(list);
  };

  const cancelEditting = () => {
    setWhitelistedDomainsEdited(whitelistedDomains.slice());
    setIsEditState(false);
  };

  const rotateKey = async () => {
    setShowSuccessMsg(true);
    await APIRotateKey(selectedApiKeyID).then((response) => {
      setNewApiKey(response.data.api_key);
    });
  };

  const openRotateKeyModal = (apiKeyID, apiKey) => {
    setShowRotateModal(true);
    setSelectedApiKeyID(apiKeyID);
    setSelectedApiKey(apiKey);
  };

  const closeRotateKeyModal = () => {
    if (showSuccessMsg) {
      window.location.reload();
    }
    setShowRotateModal(false);
  };

  const openNameModal = (apiKeyID, apiKey) => {
    if (apiKeyID) {
      setShowModalNewApiKeyName(true);
      setSelectedApiKeyID(apiKeyID);
      setSelectedApiKey(apiKey);
    } else {
      setShowModalNewSlaveKey(true);
    }
  };

  const closeNameModal = () => {
    setShowModalNewSlaveKey(false);
    setShowModalNewApiKeyName(false);
    setError('');
    setNewApiKeyName('');
  };

  const changeNameKey = async () => {
    if (!newApiKeyName) {
      setError('Please enter a name.');
    } else {
      setError('');
      await APIKeyChangeName(selectedApiKeyID, newApiKeyName)
        .then((response) => {
          console.log(response);
          window.location.reload();
        })
        .catch((error) => {
          setError(error.response.data[0]);
        });
    }
  };

  const createSlaveKey = async () => {
    await APIKeyCreateSlaveKey(props.apiKeyID, newApiKeyName).then(
      (response) => {
        window.location.reload();
      },
    );
  };

  const openDeleteKeyModal = (apiKeyID, apiKey) => {
    setShowDeleteKeyModal(true);
    setSelectedApiKeyID(apiKeyID);
    setSelectedApiKey(apiKey);
  };

  const deleteSlaveKey = async () => {
    await APIKeyDeleteSlaveKey(selectedApiKeyID).then((response) => {
      window.location.reload();
    });
  };

  const allowedToMultipleKeys = () => {
    if (props.apiPlanPrice > 45) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="flex" style={{ 'justify-content': 'space-between' }}>
        <h2 className="text-xl font-medium">Manage your API Keys</h2>
        <button
          className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={() =>
            !allowedToMultipleKeys()
              ? props.showSubscriptionOverlay(
                  'You must upgrade to create additional keys',
                  'multiple_api_keys',
                )
              : openNameModal()
          }
        >
          Create a new key
        </button>
      </div>
      <br />
      <div className="flex flex-col">
        <div className="-my-2  sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="border border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Key
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {slaveKeys.map((key, index) => {
                    return (
                      <>
                        {key.fields.primary ? (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                              {key.fields.name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                              {key.fields.api_key}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                              <button
                                className="text-black-abstract-branding-500"
                                onClick={() =>
                                  openRotateKeyModal(key.pk, key.fields.api_key)
                                }
                              >
                                Rotate
                              </button>
                              <button
                                className="ml-4 text-black-abstract-branding-500"
                                onClick={() =>
                                  openNameModal(key.pk, key.fields.api_key)
                                }
                              >
                                Rename
                              </button>
                            </td>
                          </tr>
                        ) : undefined}
                      </>
                    );
                  })}
                  {slaveKeys.map((key, index) => {
                    return (
                      <>
                        {!key.fields.primary ? (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                              {key.fields.name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                              {key.fields.api_key}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                              <button
                                className="text-black-abstract-branding-500"
                                onClick={() =>
                                  openRotateKeyModal(key.pk, key.fields.api_key)
                                }
                              >
                                Rotate
                              </button>
                              <button
                                className="ml-4 text-black-abstract-branding-500"
                                onClick={() =>
                                  openNameModal(key.pk, key.fields.api_key)
                                }
                              >
                                Rename
                              </button>
                              <button
                                className="ml-4 text-black-abstract-branding-500"
                                onClick={() =>
                                  !allowedToMultipleKeys()
                                    ? props.showSubscriptionOverlay(
                                        'You must upgrade to create or remove additional keys',
                                        'multiple_api_keys',
                                      )
                                    : openDeleteKeyModal(
                                        key.pk,
                                        key.fields.api_key,
                                      )
                                }
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ) : undefined}
                      </>
                    );
                  })}
                  <Transition.Root show={showRotateModal} as={Fragment}>
                    <Dialog
                      as="div"
                      static
                      className="fixed z-50 inset-0 overflow-y-auto"
                      open={true}
                      initialFocus={cancelButtonRef}
                      onClose={() => console.log('close')}
                    >
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                          className="hidden sm:inline-block sm:align-middle sm:h-screen"
                          aria-hidden="true"
                        >
                          &#8203;
                        </span>
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg leading-6 font-medium sm:text-center mt-3"
                                >
                                  Rotate API Key
                                </Dialog.Title>
                                <div className="mt-3">
                                  {!showSuccessMsg ? (
                                    <p className="text-gray-500 sm:text-center">
                                      Rotating this key{' '}
                                      <strong>{selectedApiKey}</strong> will
                                      completely disable the current one and
                                      generate an entirely new one.{' '}
                                      <strong>
                                        This action cannot be undone
                                      </strong>
                                      .
                                    </p>
                                  ) : (
                                    <>
                                      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4 mt-4">
                                        <CheckIcon
                                          className="h-6 w-6 text-green-600"
                                          aria-hidden="true"
                                        />
                                      </div>
                                      <p className="text-gray-500 sm:text-center">
                                        Your API key was changed successfully.
                                        <br />
                                        <br />
                                        New API Key:{' '}
                                        <strong>{newApiKey}</strong>
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="bg-gray-50 pt-2 mt-1 pb-2 pr-4 sm:flex sm:flex-row-reverse">
                              {!showSuccessMsg ? (
                                <>
                                  <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={rotateKey}
                                  >
                                    Rotate Key
                                  </button>
                                  <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={closeRotateKeyModal}
                                    ref={cancelButtonRef}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={closeRotateKeyModal}
                                  >
                                    Close
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </Transition.Child>
                      </div>
                    </Dialog>
                  </Transition.Root>

                  <Transition.Root
                    show={showModalNewApiKeyName || showModalNewSlaveKey}
                    as={Fragment}
                  >
                    <Dialog
                      as="div"
                      static
                      className="fixed z-50 inset-0 overflow-y-auto"
                      open={true}
                      onClose={() => console.log('close')}
                    >
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                          className="hidden sm:inline-block sm:align-middle sm:h-screen"
                          aria-hidden="true"
                        >
                          &#8203;
                        </span>
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                {showModalNewSlaveKey ? (
                                  <Dialog.Title
                                    as="h3"
                                    className="text-lg leading-6 font-medium sm:text-center mt-3"
                                  >
                                    Create a new API Key
                                  </Dialog.Title>
                                ) : (
                                  <Dialog.Title
                                    as="h3"
                                    className="text-lg leading-6 font-medium sm:text-center mt-3"
                                  >
                                    Rename API Key
                                  </Dialog.Title>
                                )}
                                <div className="mt-4 text-sm">
                                  {error ? (
                                    <>
                                      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                        <ExclamationIcon
                                          className="h-6 w-6 text-red-600"
                                          aria-hidden="true"
                                        />
                                      </div>
                                      <p className="text-gray-500 font-medium sm:text-center mt-2">
                                        {error}
                                      </p>
                                    </>
                                  ) : undefined}
                                  {showModalNewSlaveKey ? (
                                    <label
                                      htmlFor="name"
                                      className="block text-sm font-medium"
                                    >
                                      Key name
                                    </label>
                                  ) : (
                                    <label
                                      htmlFor="name"
                                      className="block text-sm font-medium"
                                    >
                                      New key name
                                    </label>
                                  )}
                                  <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="shadow-sm mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mr-3"
                                    value={newApiKeyName}
                                    onChange={(e) =>
                                      setNewApiKeyName(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="bg-gray-50 pt-2 mt-3 pb-2 pr-4 sm:flex sm:flex-row-reverse">
                              {showModalNewSlaveKey ? (
                                <button
                                  type="button"
                                  className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                  onClick={createSlaveKey}
                                >
                                  Create
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                  onClick={changeNameKey}
                                >
                                  Update
                                </button>
                              )}
                              <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => closeNameModal()}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Transition.Child>
                      </div>
                    </Dialog>
                  </Transition.Root>

                  <Transition.Root show={showDeleteKeyModal} as={Fragment}>
                    <Dialog
                      as="div"
                      static
                      className="fixed z-50 inset-0 overflow-y-auto"
                      open={true}
                      initialFocus={cancelButtonRef}
                      onClose={() => console.log('close')}
                    >
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                          className="hidden sm:inline-block sm:align-middle sm:h-screen"
                          aria-hidden="true"
                        >
                          &#8203;
                        </span>
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg leading-6 font-medium sm:text-center mt-3"
                                >
                                  Delete API Key
                                </Dialog.Title>
                                <div className="mt-2">
                                  <p className="text-gray-500 sm:text-center">
                                    Are you sure to delete this key{' '}
                                    <strong>{selectedApiKey}</strong>?
                                    <br />
                                    <strong>
                                      This action cannot be undone
                                    </strong>
                                    .
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="bg-gray-50 pt-2 pb-2 pr-4 sm:flex sm:flex-row-reverse">
                              <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={deleteSlaveKey}
                              >
                                Delete Key
                              </button>
                              <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => setShowDeleteKeyModal(false)}
                                ref={cancelButtonRef}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Transition.Child>
                      </div>
                    </Dialog>
                  </Transition.Root>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />

      <h2 className="text-xl font-medium">Domain whitelisting</h2>
      <div className="mt-4 text-sm">
        If you are using your API on the front-end. You can choose to restrict
        calls so Abstract only accepts requests originating from certain
        domains.
        <br />
      </div>

      <div className="mt-8 text-sm">
        <div className="rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                You can whitelist subdomains (ie <b>app.domain.com</b>), root
                domains (<b>domain.com</b>) or all subdomains of a specific
                domain using wildcards (<b>*.domain.com</b>)
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 text-sm">
        <label>Whitelisted domains:</label>
        <div className="mt-4 ">
          {!isEditState ? (
            whitelistedDomains.length > 0 ? (
              <>
                {whitelistedDomains.map((domain, index) => {
                  return (
                    <div
                      key={index}
                      className="mb-2 flex flex-row items-center appearance-none block w-full px-3 border border-gray-300 bg-gray-50 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-black-abstract-branding-500 focus:border-black-abstract-branding-500 sm:text-sm"
                    >
                      <GlobeAltIcon className="h-5 mr-2" />
                      <input
                        key={index}
                        className="border-0 w-full h-10 text-sm"
                        disabled="disabled"
                        value={domain}
                        type="text"
                        placeholder="*.domain.com"
                      />
                    </div>
                  );
                })}

                <div className="mt-4">
                  <div className="flex justify-end">
                    <button
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() =>
                        props.apiPlanName === 'Free'
                          ? props.showSubscriptionOverlay(
                              'Upgrade to get access to domain whitelisting',
                              'domain_whitelisting',
                            )
                          : startEdit()
                      }
                    >
                      Edit whitelist
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div
                className="transition ease p-12 text-center rounded-md border border-gray-200 hover:border-black-abstract-branding-500 cursor-pointer"
                onClick={() =>
                  props.apiPlanName === 'Free'
                    ? props.showSubscriptionOverlay(
                        'Upgrade to get access to domain whitelisting',
                        'domain_whitelisting',
                      )
                    : createWhitelist()
                }
              >
                <PlusIcon className="w-10 m-auto" />
                <span className="font-bold m-auto">
                  Create domain whitelist
                </span>
              </div>
            )
          ) : (
            <>
              {whitelistedDomainsEdited.map((domain, index) => {
                return (
                  <div className="flex mb-2">
                    <div className="flex flex-row items-center appearance-none block w-full pl-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-brand-500 focus:border-blue-brand-500 sm:text-sm">
                      <GlobeAltIcon className="h-5 mr-2" />
                      <input
                        key={index}
                        value={domain}
                        className="w-full h-10 bg-transparent border-none text-sm rounded-md"
                        onChange={(event) => editDomain(event, index)}
                        type="text"
                        placeholder="*.domain.com"
                      />
                    </div>
                    <button
                      className="px-3 rounded-md align-center border border-gray-300 shadow-sm bg-white text-base font-medium hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-brand-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={(event) => deleteDomain(index)}
                    >
                      <TrashIcon className="h-5" />
                    </button>
                  </div>
                );
              })}
              <div className="mb-6">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() =>
                    props.apiPlanName === 'Free'
                      ? props.showSubscriptionOverlay(
                          'Upgrade to get access to domain whitelisting',
                          'domain_whitelisting',
                        )
                      : addDomain()
                  }
                >
                  Add domain
                </button>
              </div>

              <div className="sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black-abstract-branding-500 text-base font-medium text-white hover:bg-black-abstract-branding-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() =>
                    props.apiPlanName === 'Free'
                      ? props.showSubscriptionOverlay(
                          'Upgrade to get access to domain whitelisting',
                          'domain_whitelisting',
                        )
                      : saveWhitelist()
                  }
                >
                  Save whitelist
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => cancelEditting()}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SettingsApiConfig;
