import { useEffect } from 'react';
import SettingsApiConfig from './SettingsApiConfig';
import { trackEventPageLoad } from '../../Utils/SegmentAnalytics';

function Settings(props) {
  useEffect(() => {
    trackEventPageLoad('product settings');
  });

  return (
    <>
      <SettingsApiConfig
        apiKey={props.apiKey}
        apiKeyName={props.apiKeyName}
        apiPlanPrice={props.apiPlanPrice}
        apiKeyID={props.apiKeyID}
        apiPlanName={props.apiPlanName}
        showSubscriptionOverlay={props.showSubscriptionOverlay}
      />
    </>
  );
}

export default Settings;
