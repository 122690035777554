import React from 'react';

import { LoadingSpinner } from '../index';

const Button = ({
  children,
  onClick,
  action = 'primary',
  isWide = false,
  disabled,
  icon,
  className = null,
  loading = false,
}) => {
  const buttonStyles = {
    primary:
      'bg-black-abstract-branding-500 text-white hover:bg-black-abstract-branding-700',
    secondary: 'bg-white text-gray-700 hover:bg-gray-50 border-gray-300',
  };
  const wideStyles =
    action === 'secondary'
      ? 'h-14 w-64 px-2.5 py-1.5 border-gray-300'
      : 'h-14 w-64 px-2.5 py-1.5';

  return (
    <button
      type="button"
      className={`flex place-items-center place-content-center rounded-md border text-sm font-medium py-2.5 px-3.5 shadow-sm ${
        buttonStyles[action]
      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-abstract-branding-500 ${
        isWide ? wideStyles : ''
      } ${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${
        className ? className : ''
      }`}
      onClick={onClick}
      style={{ minWidth: '8rem', minHeight: '2.8rem' }}
      disabled={disabled || loading}
    >
      {loading && <LoadingSpinner />}
      {icon && <span className="h-5 w-5 p-1">{icon}</span>}
      {children}
    </button>
  );
};

export default Button;
