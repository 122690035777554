import { useEffect } from 'react';
import axios from 'axios';
import { LRUCache } from 'lru-cache';
import productsJson from '../../assets/json/products.json';
import planPeriods from '../../assets/json/plan_periods.json';
import { getMainInterestFromParam } from '../Onboarding/helpers';

const cache = new LRUCache({
  max: 500,
  ttl: 1000 * 60 * 60 * 24, // 24 horas
}); // Cache for an hour.

export const useOnClickOutside = (ref, handler, closeOnClickOutside) => {
  useEffect(() => {
    if (handler && closeOnClickOutside) {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }
  }, [ref, handler, closeOnClickOutside]);
};

export const verifyEmail = (email) => {
  const re = /^[^\s@]+@[^@\s]+$/;
  if (!re.test(String(email).toLowerCase())) {
    return `Invalid email address - ${email}`;
  }
  return null;
};

export const verifyPhone = (phone) => {
  const re = /^[0-9().\-+]+$/;
  if (!re.test(phone.replace(' ', ''))) {
    return `Invalid phone number - ${phone}`;
  }
  return null;
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const fetchIpAddress = async () => {
  try {
    const response = await axios.get('https://api64.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    return '';
  }
};

export const handleIpAddressFetching = async (setQueryIpAddress) => {
  const storedIp = cache.get('queryIpAddress');
  if (storedIp) {
    setQueryIpAddress(storedIp);
  } else {
    try {
      const ipAddress = await fetchIpAddress();
      const queryIpAddress = `&ip_address=${ipAddress}`;
      cache.set('queryIpAddress', queryIpAddress);
      setQueryIpAddress(queryIpAddress);
    } catch (error) {
      console.error('Error Getting IP Adress from Api:', error);
    }
  }
};

export const readAbstractTrackingCookie = () => {
  let name = 'abs_trckng=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '{}';
};

export const getSignupSourceData = (signup_source = 'manual') => {
  // Build signupSourceData object
  let target = undefined;
  let selectedPlanData = undefined;
  let selectedPlan = {};

  if (window.location.search.match(/target=([^&]*)/)) {
    let target_param = window.location.search.match(/target=([^&]*)/)[1];
    try {
      target = target_param.match(/api\/([^&]*)\/pricing\/select/)[1];
    } catch (error) {
      target = getMainInterestFromParam(target_param);
    }
  }

  if (window.location.search.match(/selected_plan=([^&]*)/)) {
    selectedPlanData = window.location.search.match(/selected_plan=([^&]*)/)[1];
    const [api, basePrice, billingPeriod] = selectedPlanData.split('_');
    selectedPlan = {
      api: productsJson.products[api].id,
      base_price: basePrice,
      billing_period: planPeriods[billingPeriod],
    };
  }

  const signupSourceData = {
    signup_initial_referring_domain: (
      JSON.parse(readAbstractTrackingCookie())['initial_referring_domain'] || ''
    ).substring(0, 200),
    signup_initial_referrer: (
      JSON.parse(readAbstractTrackingCookie())['initial_referrer'] || ''
    ).substring(0, 200),
    signup_landing_page_url: (
      JSON.parse(readAbstractTrackingCookie())['landing_page_url'] || ''
    ).substring(0, 200),
    signup_referrer: (document.referrer || '').substring(0, 200),
    signup_current_url: (window.location.href || '').substring(0, 200),
    target: target,
    selected_plan: selectedPlan,
    signup_source: signup_source,
  };
  return signupSourceData;
};

export const trimTrailingEmptyValues = (arr) => {
  let endIndex = arr.length - 1;
  while (endIndex >= 0 && !arr[endIndex]) {
    endIndex--;
  }
  return arr.slice(0, endIndex + 1);
};

const APIValidator = {
  'email-validation': verifyEmail,
  'phone-validation': verifyPhone,
};

const checkIsEmptyRow = (row, lineNumber, dataLength) => {
  if (!row || (row && row.trim() === '')) {
    if (dataLength !== lineNumber) {
      return `Row ${lineNumber}: Empty row detected`;
    }

    return null;
  }
  return null;
};

const checkAndFormatError = (row, validator, lineNumber) => {
  const error = validator(row);
  if (error) {
    return `Row ${lineNumber}: ${error}`;
  }
  return null;
};

export const validatorCSV = (data, slugApiName) => {
  const typeData = slugApiName.includes('email') ? 'email' : 'phone';

  if (!data.length) {
    return ['Invalid CSV file'];
  }

  let hasErrorFirstColumn = false;

  if (data.length > 50000) {
    return [
      `The maximum number of ${typeData}s is 50,000. Your current number is ${data.length.toLocaleString()}`,
    ];
  }

  const validator = APIValidator[slugApiName];
  const errors = [];

  if (data.length > 1 && !!data[0] && validator(data[0]) !== null) {
    errors.push(`There were no ${typeData} found in the first column.`);
    hasErrorFirstColumn = true;
  }

  const dataLength = data.length;
  const mappedErrors = data
    .map((row, index) => {
      const rowNumber = index + 1;
      const emptyRowError = checkIsEmptyRow(row, rowNumber, dataLength);

      if (!emptyRowError) {
        const singleValueError = checkAndFormatError(row, validator, rowNumber);
        if (singleValueError) {
          if (row.trim().split(' ').length > 1) {
            if (slugApiName.includes('email') && row.split('@').length > 1) {
              return [
                `Row ${rowNumber}: The cell contains more than one value to validate - ${row}`,
              ];
            }
            if (slugApiName.includes('phone') && row.split('+').length > 1) {
              return [
                `Row ${rowNumber}: The cell contains more than one value to validate - ${row}`,
              ];
            }
            return [singleValueError];
          }
          if (!hasErrorFirstColumn) {
            return [];
          } else {
            return [singleValueError];
          }
        }
        return [];
      } else {
        return [emptyRowError];
      }
    })
    .flat();

  const allErrors = errors.concat(mappedErrors);
  return allErrors;
};

export const summarizeCSVErrors = (errors, slugApiName) => {
  const typeData = slugApiName.includes('email') ? 'email' : 'phone';

  let errorCodes = [];
  let errorMessagesSummary = [];

  if (errors.some((error) => error.includes('Invalid CSV file'))) {
    errorCodes.push('file_invalid_format');
    errorMessagesSummary.push('Check that the file is a valid CSV.');
  }

  if (errors.some((error) => error.includes('The maximum number of'))) {
    errorCodes.push('exceeds_row_limit');
    errorMessagesSummary.push(
      'The maximum file size permitted is 50,000 rows.',
    );
  }

  if (errors.some((error) => error.includes('Empty row detected'))) {
    errorCodes.push('empty_row');
    errorMessagesSummary.push('Remove any empty rows from the file.');
  }

  if (errors.some((error) => error.includes('The cell'))) {
    errorCodes.push('multiple_values_per_cell');
    errorMessagesSummary.push(
      `Include only one ${typeData} ${
        typeData === 'email' ? 'address' : 'number'
      } per row.`,
    );
  }

  if (errors.some((error) => error.includes('There were no'))) {
    errorCodes.push('wrong_data_in_first_column');
    let message = `Ensure the selected column contains the ${typeData} ${
      typeData === 'email' ? 'address' : 'number'
    } to be analyzed`;
    errorMessagesSummary.push(message);
    const index = errors.findIndex((error) => error.includes('There were no'));
    errors[index] = `There were no ${typeData} ${
      typeData === 'email' ? 'addresses' : 'numbers'
    } found in the selected column`;
  }
  errors = errors.filter((error) => !error.includes(`Invalid ${typeData}`));

  return {
    errorCodes,
    errorMessagesSummary,
    errors,
  };
};

export const getLocalDateAndTime = () => {
  const now = new Date();
  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const formattedDate = now
    .toLocaleDateString('en-US', dateOptions)
    .split('/')
    .join('-');
  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };
  const formattedTime = now.toLocaleTimeString('en-US', timeOptions);
  return `${formattedDate}-${formattedTime}`;
};

export const calculateAvailabilityDaysFromFile = (file) => {
  const date = new Date(file.created.replace('-', '/'));
  date.setDate(date.getDate() + 7);
  return date.toLocaleDateString('es-ES');
};

export const generateBulkUploadCSVURL = (productPath) => {
  return `https://docs.abstractapi.com/${productPath}#bulk-upload-csv`;
};

export const checkSubscriptionStatus = () => {
  const userApis = JSON.parse(localStorage.getItem('user_apis'));

  let isEmailSubscriptor = userApis.filter(
    (api) =>
      api.api_product_name === 'Email Validation' &&
      api.api_plan_name !== 'Free',
  )[0];

  let isIPGeolocationSubscriptor = userApis.filter(
    (api) =>
      api.api_product_name === 'IP Geolocation' && api.api_plan_name !== 'Free',
  )[0];

  return {
    isEmailSubscriptor:
      isEmailSubscriptor && Object.keys(isEmailSubscriptor).length > 0,
    isIPGeolocationSubscriptor:
      isIPGeolocationSubscriptor &&
      Object.keys(isIPGeolocationSubscriptor).length > 0,
  };
};

export const generateRowDescription = (productPath) => {
  return productPath === 'email-validation'
    ? 'email addresses'
    : 'phone numbers';
};

export const getHowMuchErrorsFound = (errors) => {
  const errorCount = errors.length;
  if (errorCount === 1) {
    return 'There is 1 issue found';
  } else {
    return `There are ${errorCount} issues found`;
  }
};
