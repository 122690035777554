import React, { forwardRef } from 'react';

import Button from '../../Button';

const ModalFooter = forwardRef(
  ({ actions: { primary, secondary }, onSubmit }, ref) => {
    return (
      <div className="flex justify-end gap-4" ref={ref}>
        {secondary && <Button action="secondary">{secondary.label}</Button>}
        <Button
          action="primary"
          onClick={onSubmit}
          disabled={Boolean(primary.isDisabled)}
        >
          {primary.label}
        </Button>
      </div>
    );
  },
);

export default ModalFooter;
