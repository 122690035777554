import { useEffect, useContext, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

// Context
import APIContext from '../contexts/APIContext';
import SessionContext from '../contexts/SessionContext';
import CacheContext from '../contexts/CacheContext';

// Components
import Aside from '../components/Layout/Aside/Aside';
import Header from '../components/Layout/Header/Header';
import Footer from '../components/Layout/Footer/Footer';

// Modal
import AllCreditsUsedModal from '../components/Modals/AllCreditsUsedModal';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isUserAuthenticated, isUserImpersonated } =
    useContext(SessionContext);
  const { userRoles, userId, userEmail, organizationSuspended } =
    useContext(CacheContext);
  const cacheContext = useContext(CacheContext);
  const { APIrefreshUserData } = useContext(APIContext);
  const { setPaymentFailedModalVisibility } = rest;
  const [allCreditsUsedModalState, setAllCreditsUsedModalState] =
    useState(false);
  const [currentApi, setCurrentApi] = useState(null);
  const [userApis, setUserApis] = useState(null);

  useEffect(() => {
    if (isUserAuthenticated()) {
      APIrefreshUserData().then(() => {
        let usersApis = cacheContext.getUserApisData();
        setUserApis(usersApis);
      });
    }
    if (organizationSuspended) {
      setPaymentFailedModalVisibility(true);
    }
  }, [userId, isUserImpersonated, organizationSuspended]);

  useEffect(() => {
    const { api } = rest.computedMatch.params;
    if (api && !rest.location.pathname.includes('pricing')) {
      setCurrentApi(api);
      const apiData =
        userApis &&
        userApis.filter(
          (userApi) =>
            userApi.api_product_name.toLowerCase().replace(' ', '-') === api,
        )[0];
      if (
        apiData &&
        apiData.credits_available === 0 &&
        apiData.api_plan_name === 'Free'
      ) {
        setAllCreditsUsedModalState(true);
      }
    } else {
      setAllCreditsUsedModalState(false);
    }
  }, [rest.computedMatch.params, userApis]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isUserAuthenticated() ? (
          <>
            <div className="h-screen flex overflow-hidden bg-gray-brand-100">
              <Aside />
              <div className="flex flex-col w-0 flex-1 overflow-hidden ">
                <Header />
                {isUserImpersonated ? (
                  <div className="relative bg-red-600">
                    <div className="mx-auto py-3 px-3 sm:px-6 lg:px-8">
                      <div className="pr-16 sm:text-center sm:px-16">
                        <p className="font-medium text-white">
                          <span>Your are currently impersonating: </span>
                          <span>{userEmail}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <main className="flex-1 relative overflow-y-auto focus:outline-none">
                  <div className="py-12 px-8 xl:px-0 max-w-screen-lg mx-auto">
                    <div className="max-w-screen-lg mx-auto">
                      <div className="py-4">
                        <div className="">
                          {
                            // Check if this route is role-restricted and if that's the case, if the user has given role
                            rest.rolesAllowed === undefined ||
                            rest.rolesAllowed.some((role) =>
                              userRoles.includes(role),
                            ) ? (
                              <>
                                <AllCreditsUsedModal
                                  isVisible={allCreditsUsedModalState}
                                  setIsVisible={setAllCreditsUsedModalState}
                                  api={currentApi}
                                />
                                <Component {...props} />
                              </>
                            ) : (
                              <Redirect to="/dashboard" />
                            )
                          }
                        </div>
                      </div>
                      <div className="pt-10 pb-4">
                        <Footer />
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
