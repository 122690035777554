import React, { useEffect } from 'react';

import OnboardingCards from './OnboardingCards/OnboardingCards';
import useHeaderContext from '../../contexts/useHeaderContext';

import { trackEventPageLoad } from '../Utils/SegmentAnalytics';
import { getMainInterestFromParam } from './helpers';
import useGetQueryParamValue from '../Utils/useGetQueryParamValue';

const Onboarding = () => {
  const param = useGetQueryParamValue('target');
  const mainInterest = getMainInterestFromParam(param);
  const { show, setShow, eventProperties, setEventProperties } =
    useHeaderContext();

  useEffect(() => {
    trackEventPageLoad('Onboarding');
  }, []);

  useEffect(() => {
    setShow(true);
  }, [setShow]);

  return (
    show && (
      <div className="pb-20 md:pb-0">
        <div className="flex flex-col items-center">
          <p className="flex justify-center pt-8 px-8 text-sm">
            👋 Welcome! Please answer the following questions to improve your
            experience.
          </p>

          <h3 className="mx-auto font-bold text-lg pt-10 pb-8 px-8">
            What product are you most interested in?
          </h3>

          <OnboardingCards
            key={0}
            eventProperties={eventProperties}
            setEventProperties={setEventProperties}
            mainInterest={mainInterest}
          />
        </div>
      </div>
    )
  );
};
export { Onboarding };
