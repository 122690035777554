const TESTER_STRINGS = {
  LANGUAGES: [
    'cURL',
    'Javascript',
    'jQuery',
    'NodeJS',
    'Python',
    'Ruby',
    'Java',
    'PHP',
    'Go',
  ],
};

export default TESTER_STRINGS;
