import { useMemo } from 'react';

const usePopperOptions = ({
  arrowElementRef,
  placement,
  showArrow,
  fallbackPlacements = ['top', 'right'],
}) => {
  return useMemo(
    () => ({
      placement,
      modifiers: [
        {
          name: 'arrow',
          options: { element: arrowElementRef.current },
          enabled: showArrow,
        },
        {
          name: 'offset',
          options: {
            offset: [0, 15],
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements,
          },
        },
      ],
    }),
    [arrowElementRef, placement, showArrow, fallbackPlacements],
  );
};

export default usePopperOptions;
